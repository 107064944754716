import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Models } from '@model/movies.models';
import { PaymentProfile } from '@model/payment-profile';
import { TokenBalance } from '@model/token-balance';
import { UserService } from '@service/user.service';
import { ColDef } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { TransactionMerchantRendererComponent } from './cell-renderers/transaction-merchant-renderer.component';

@Component({
  selector: 'app-fanbucks-balance-tab',
  templateUrl: './balance-tab.component.html',
  styleUrls: ['./balance-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FanBucksBalanceTabComponent implements OnInit {
  user = this.userService.user;
  // Token Balance
  private _tokenBalance = 0;
  public balanceIndex = 0;
  public totalBalancePages = 0;
  public tokenBalance$ = new BehaviorSubject<number>(0);

  public userProfile$ = new BehaviorSubject<PaymentProfile>({});
  // Token History
  private _tokenBalanceHistory: TokenBalance[] = [];
  public tokenBalanceHistory$: BehaviorSubject<TokenBalance[]> = new BehaviorSubject(null as any);
  constructor(private userService: UserService) {}
  fanRewardBalance$ = new BehaviorSubject<number>(0);

  colDefs: ColDef<TokenBalance>[] = [
    {
      headerName: 'Artist',
      field: 'tetheredMerchantName',
      flex: 2,
      minWidth: 160,
      autoHeight: true,
      cellRenderer: TransactionMerchantRendererComponent,
      headerClass: 'has-text-weight-semibold',
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Amount',
      field: 'balance',
      flex: 1,
      minWidth: 160,
      cellClass: "flexbox is-align-items-center",
      headerClass: 'has-text-weight-semibold',
      sortable: true,
      filter: 'agTextColumnFilter'
    }
  ];

  ngOnInit(): void {
    this.userService.getUserTokenBalances().subscribe((res: TokenBalance[]) => {
      this._tokenBalanceHistory = res.filter((x) => x.tetheredMerchantGUID !== undefined);
      this.totalBalancePages = Math.ceil(this._tokenBalanceHistory.length / 20);
      this.tokenBalanceHistory$.next(
        this._tokenBalanceHistory
      );

      const fanbucks = res.filter((x) => x.tetheredMerchantGUID !== undefined);
      const count = fanbucks.length;
      if (fanbucks !== undefined && count) {
        this.fanRewardBalance$.next(
          fanbucks.map((tokenbalance) => tokenbalance.balance).reduce((prev, next) => prev + next) || 0
        );
      }
    });
  }

}
