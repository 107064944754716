import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MerchantMeetAndGreetRequest } from '@model/merchant-meet-and-greet-request';
import { User } from '@model/user';
import { MerchantMeetAndGreetRequestService } from '@service/merchant-meet-and-greet-request.service';
import { TwilioService } from '@service/twilio.service';
import { ZoomService } from '@service/zoom.service';
import { Stream } from '@zoom/videosdk';
import { UserService } from '@service/user.service';
import * as moment from 'moment';
import { BehaviorSubject, interval, Subscription, timer } from 'rxjs';
import * as Twilio from 'twilio-video';
import 'moment-duration-format';
import { VideoSettingsService } from '@service/video-settings.service';
import { take } from 'rxjs/operators';
import { environment } from '@environment';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCallComponent implements OnInit, OnDestroy {
  data = new BehaviorSubject<any>(undefined);
  isOpened = new BehaviorSubject(false);

  room$ = new BehaviorSubject<typeof Stream | undefined>(undefined);
  isParticipantConnected = new BehaviorSubject(false);
  isParticipantEnableVideo = new BehaviorSubject(true);
  isParticipantEnableAudio = new BehaviorSubject(true);
  isEnableAudio = true;
  isEnableVideo = true;

  user$ = new BehaviorSubject<User | undefined>(undefined);
  meetAndGreet$ = new BehaviorSubject<MerchantMeetAndGreetRequest | undefined>(undefined);

  isTimerStart = new BehaviorSubject(false);
  startTime = new BehaviorSubject<Date | undefined>(undefined);
  inGracePeriod = false;
  dateDiff = 0;
  currentDate = new Date();

  countDown: any = '0';
  timeLapse = new BehaviorSubject<string | undefined>('');

  interval: any;

  selectedMic: MediaDeviceInfo | undefined = undefined;
  selectedCam: MediaDeviceInfo | undefined = undefined;
  selectedSpeaker: MediaDeviceInfo | undefined = undefined;
  settings = 1;
  showSettings = false;

  showRating = false;
  showRatingSubscribe: Subscription;

  isMerchant = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public merchantMeetAndGreetRequestService: MerchantMeetAndGreetRequestService,
    public twilioService: TwilioService,
    public zoomService: ZoomService,
    private userService: UserService,
    private videoSettingsService: VideoSettingsService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef
  ) {
    let isGettingRequest = false;

    this.userService.user$.subscribe((user) => {
      if (user && user.guid && !isGettingRequest) {
        this.user$.next(user);
        isGettingRequest = true;
        const params: any = { userGUID: user.guid, platform: environment.platform };
        this.merchantMeetAndGreetRequestService.getVideoCallRequest(params, 1);
      }
    });

    this.merchantMeetAndGreetRequestService.isOpened.subscribe((isOpened) => {
      this.isOpened.next(isOpened);

      if (isOpened) {
        this.document.getElementById('xgenflix-body')?.classList.add('modal-open');
      } else {
        this.document.getElementById('xgenflix-body')?.classList.remove('modal-open');
      }
    });

    this.showRatingSubscribe = this.merchantMeetAndGreetRequestService.showRating.subscribe((data) => {
      this.showRating = data;
    });

    this.merchantMeetAndGreetRequestService.data.pipe().subscribe((data) => {
      if (data && data.merchantUserGUID && this.user$.value?.guid === data.merchantUserGUID) {
        this.isMerchant = true;
      }
      this.meetAndGreet$.next(data);

      this.isOpened.pipe(take(2)).subscribe((openModal) => {
        // console.log('isOpenModal', openModal);
        if (data !== undefined && openModal && !merchantMeetAndGreetRequestService.showRating.value) {
          this.data.next(data);
          this.merchantMeetAndGreetRequestService.generateVideoMeetingToken(data.videoMeetingGUID).then((res) => {
            if (res.token) {
              this.zoomService.initializeConnection();
              this.merchantMeetAndGreetRequestService.generatedToken.next(res.token);
              this.zoomService.connect(res, data.merchantName).then(() => {
                this.room$.next(this.zoomService.zoomSession);
                setTimeout(() => {
                  this.zoomService.addLocalVideo();
                  this.zoomService.zoomSession?.startAudio({
                    microphoneId: this.zoomService.selectedMic?.deviceId,
                    speakerId: this.zoomService.selectedSpeaker?.deviceId
                  });
                }, 3000);

                this.zoomService.zoomVideo.getAllUser().forEach((user) => {
                  if (user.bVideoOn) {
                    this.addParticipantVideo(user.userId);
                  }
                  this.participantTimerCheck();
                });

                this.zoomService.zoomVideo.on('peer-video-state-change', (payload) => {
                  if (payload.action === 'Start') {
                    this.addParticipantVideo(payload.userId);
                  } else if (payload.action === 'Stop') {
                    this.removeParticipantVideo(payload.userId);
                  }
                });
              });
            }
            // this.merchantMeetAndGreetRequestService.videoMeetingToken.next(res);
          });
        }
      });
    });
  }

  ngOnInit(): void {
    this.isTimerStart.subscribe((status) => {
      if (status && this.meetAndGreet$.value?.dateScheduled) {
        this.interval = setInterval(() => {
          if (this.meetAndGreet$.value) {
            if (this.inGracePeriod) {
              this.inGracePeriod = false;
            }
            this.dateDiff =
              this.dateCompare(this.startTime.value! as Date, new Date()).asSeconds() +
              this.meetAndGreet$.value.durationInMinutes! * 60;

            const callDurationInMoments = moment.duration(Math.abs(this.dateDiff), 'seconds').add(1, 'seconds');
            const convertedTimeLapse = moment.utc(callDurationInMoments.asMilliseconds()).format('mm:ss');

            const momentCountdown = moment.duration(
              Math.abs(this.dateCompare(new Date(this.meetAndGreet$.value.dateScheduled!), new Date()).asSeconds()),
              'seconds'
            );

            this.countDown = moment.utc(momentCountdown.asMilliseconds()).format('mm:ss');
            if (Math.floor(this.dateDiff) >= 0 && Math.floor(this.dateDiff) < 180) {
              this.timeLapse.next(convertedTimeLapse);
              this.countDown = undefined;
            } else {
              clearInterval(this.interval);
              this.hangUp();
              this.timeLapse.next(undefined);
            }
          }
        }, 1000);
      }
    });
  }

  ngOnDestroy() {
    this.hangUp();
    this.showRatingSubscribe.unsubscribe();
    // this.cameraSubscription.unsubscribe();
    // this.speakerSubscription.unsubscribe();
    // this.microphoneSubscription.unsubscribe();
  }

  hangUp() {
    clearInterval(this.interval);
    const meetAndGreet = this.meetAndGreet$.value;
    // if (
    //   meetAndGreet &&
    //   meetAndGreet.guid &&
    //   meetAndGreet.merchantUserGUID &&
    //   this.user$.value?.guid === meetAndGreet.merchantUserGUID
    // ) {
    //   this.merchantMeetAndGreetRequestService.completeMerchantMeetAndGreetRequest(meetAndGreet.guid);
    //   this.merchantMeetAndGreetRequestService.endCall(true, this.user$.value);
    // } else {
    //   this.merchantMeetAndGreetRequestService.endCall(true);
    // }
    if (meetAndGreet && meetAndGreet.guid) {
      //user will also trigger the complete call
      this.merchantMeetAndGreetRequestService
        .completeMerchantMeetAndGreetRequest(meetAndGreet.guid)
        .toPromise()
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      this.merchantMeetAndGreetRequestService.endCall(true, this.user$.value);
    }

    this.zoomService.zoomVideo.leave();
    // this.room$.getValue()?.disconnect();
    this.room$.next(undefined);

    this.videoSettingsService.callInProgress.next(false);
    this.isParticipantConnected.next(false);
    this.isParticipantEnableVideo.next(true);
    this.isParticipantEnableAudio.next(true);
    this.isEnableAudio = true;
    this.isEnableVideo = true;
    this.timeLapse.next(undefined);
  }

  addParticipantVideo(userId: number) {
    this.isParticipantConnected.next(true);

    this.zoomService.zoomSession?.renderVideo(
      this.document.querySelector('#participant-videos-canvas') as HTMLCanvasElement,
      userId,
      1920,
      1080,
      0,
      0,
      3
    );

    this.isParticipantEnableVideo.next(true);
  }

  removeParticipantVideo(id: number) {
    this.zoomService.zoomSession?.detachVideo(id);
    this.isParticipantEnableVideo.next(false);
  }

  participantConnected(participant: Twilio.Participant) {
    this.isParticipantConnected.next(true);
    this.participantTimerCheck();
    let participantDiv = document.createElement('div');
    participantDiv.setAttribute('id', participant.sid);
    participantDiv.setAttribute('class', 'participant');

    let tracksDiv = document.createElement('div');
    tracksDiv.setAttribute('class', 'track');
    tracksDiv.setAttribute('id', `tracks-${participant.sid}`);
    participantDiv.appendChild(tracksDiv);

    participant.on('trackSubscribed', (track: any) => {
      // const status = track.isEnabled === false || track.mediaStreamTrack.enabled === false ? false : true;
      const status = track.isEnabled || track.mediaStreamTrack.enabled;

      console.log(track.isEnabled, track.mediaStreamTrack);
      switch (track.kind) {
        case 'audio':
          if (status) {
            if (this.twilioService.selectedSpeaker !== undefined) {
              // console.log('attach selected speaker', track);
              this.twilioService.audioOutputElement = track.attach();

              this.twilioService.audioOutputElement.setSinkId(this.twilioService.selectedSpeaker?.deviceId).then(() => {
                document.body.appendChild(this.twilioService.audioOutputElement);
              });
            }
            this.isParticipantEnableAudio.next(true);
          } else {
            this.isParticipantEnableAudio.next(false);
          }
          break;
        case 'video':
          if (status) {
            this.isParticipantEnableVideo.next(true);
            this.participantVideoElement('participant visible');
          } else {
            this.isParticipantEnableVideo.next(false);
            this.participantVideoElement('participant invisible');
          }
          break;
        default:
          break;
      }

      track.on('enabled', (t: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) => {
        switch (t.kind) {
          case 'video':
            this.isParticipantEnableVideo.next(true);
            this.participantVideoElement('participant visible');
            break;
          case 'audio':
            this.isParticipantEnableAudio.next(true);
            break;
          default:
            break;
        }
      });
      track.on('disabled', (t: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) => {
        switch (t.kind) {
          case 'video':
            this.isParticipantEnableVideo.next(false);
            this.participantVideoElement('participant invisible');
            break;
          case 'audio':
            this.isParticipantEnableAudio.next(false);
            break;
          default:
            break;
        }
      });
      this.twilioService.trackSubscribed(tracksDiv, track);
    });
    participant.on('trackUnsubscribed', (track) => {
      switch (track.kind) {
        case 'audio':
          this.isParticipantEnableAudio.next(false);
          break;
        case 'video':
          this.isParticipantEnableVideo.next(false);
          this.participantVideoElement('participant invisible');
          break;
        default:
          break;
      }
      this.twilioService.trackUnsubscribed(track);
    });

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        this.twilioService.trackSubscribed(tracksDiv, publication.track);
      }
    });

    document.getElementById('meet')?.appendChild(participantDiv);
  }

  participantVideoElement(className?: string) {
    const participantVideo = document.getElementsByClassName('participant');
    for (let index = 0; index < participantVideo.length; index++) {
      if (className !== undefined || className !== '' || className !== null) {
        participantVideo.item(index)?.setAttribute('class', className || '');
      } else {
        participantVideo.item(index)?.removeAttribute('class');
      }
    }
  }

  videoControl(value: boolean) {
    this.isEnableVideo = value;
    this.zoomService.videoControl(value);
    this.cdr.markForCheck();
  }

  audioControl(value: boolean) {
    this.isEnableAudio = value;

    if (value) {
      this.zoomService.zoomSession?.unmuteAudio();
    } else {
      this.zoomService.zoomSession?.muteAudio();
    }
  }

  participantTimerCheck() {
    const data = this.meetAndGreet$.value;
    if (data) {
      const currentDate = new Date();

      this.isTimerStart.next(true);
      if (data.dateScheduled) {
        const dateScheduled = new Date(data.dateScheduled!);
        if (currentDate.valueOf() < dateScheduled.valueOf()) {
          data.dateCallStarted = currentDate;
          this.startTime.next(currentDate);
        } else if (currentDate.valueOf() < dateScheduled.valueOf() + 60000) {
          this.inGracePeriod = true;
          this.startTime.next(currentDate);

          if (data.durationInMinutes) {
            this.dateDiff = data.durationInMinutes * 60;
          }
        } else if (currentDate.valueOf() > dateScheduled.valueOf() + 60000) {
          this.startTime.next(dateScheduled);
        }
      }
    }
  }

  dateCompare(start: Date, end: Date) {
    return moment.duration(moment(start).diff(moment(end.toISOString())));
  }

  changeAudioOutput(id: string) {
    const media = this.zoomService.speakers.value.find((s) => s.deviceId === id);

    if (media) {
      this.zoomService.selectedSpeaker = media;
      this.zoomService.zoomSession?.switchSpeaker(media.deviceId);
    }
  }

  changeAudioInput(id: string) {
    const media = this.zoomService.microphones.value.find((s) => s.deviceId === id);
    // console.log(id, media);

    if (media) {
      this.zoomService.selectedMic = media;
      this.zoomService.zoomSession?.switchMicrophone(media.deviceId);
    }
  }

  changeCameraInput(id: string) {
    const media = this.zoomService.cameras.value.find((s) => s.deviceId === id);
    // if (media) this.twillioService.audioOutTest(media);

    if (media) {
      this.zoomService.selectedCam = media;
      this.zoomService.zoomSession?.switchCamera(media.deviceId);
    }
  }

  openSettings(event: Event, val: number) {
    event.stopPropagation();
    this.showSettings = true;
    this.settings = val;
    // this.videoSettingsService.showSettingsModal.next(true);
  }

  toggleSettings(event: Event) {
    event.stopPropagation();
    this.showSettings = !this.showSettings;
  }

  onClose() {
    this.merchantMeetAndGreetRequestService.data.next(undefined);
    this.merchantMeetAndGreetRequestService.isOpened.next(false);
    this.merchantMeetAndGreetRequestService.showRating.next(false);
  }

  // initializeInteract() {
  //   const position = { x: 0, y: 0 };
  //   interact('#local')
  //     .resizable({
  //       edges: { top: true, left: true, bottom: true, right: true },
  //       listeners: {
  //         move: function (event) {
  //           let { x, y } = event.target.dataset;

  //           if (event.deltaRect.right) {
  //             x = (parseFloat(x) || 0) - event.deltaRect.right;
  //           }
  //           if (event.deltaRect.left) {
  //             // x = (parseFloat(x) || 0) + (event.deltaRect.left);
  //           }
  //           if (event.deltaRect.top) {
  //             y = (parseFloat(y) || 0) + event.deltaRect.top;
  //           }
  //           Object.assign(event.target.style, {
  //             width: `${event.rect.width}px`,
  //             height: `${event.rect.height}px`,
  //             right: `${x}px`,
  //             top: `${y}px`
  //           });

  //           Object.assign(event.target.dataset, { x, y });
  //         }
  //       },
  //       modifiers: [
  //         interact.modifiers.restrictEdges({
  //           outer: 'parent'
  //         }),

  //         interact.modifiers.restrictSize({
  //           min: { width: 200, height: 160 },
  //           max: { width: 4 * 200, height: 4 * 160 }
  //         })
  //       ],

  //       inertia: true
  //     })
  //     .draggable({
  //       listeners: {
  //         move(event) {
  //           position.x += event.dx;
  //           position.y += event.dy;

  //           event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
  //         }
  //       },
  //       inertia: true,
  //       modifiers: [
  //         interact.modifiers.restrictRect({
  //           restriction: 'parent',
  //           endOnly: true
  //         })
  //       ]
  //     });
  // }
}
