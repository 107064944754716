import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from '@model/subscription';

@Component({
  selector: 'app-unsubcribe-call-action-renderer',
  templateUrl: './unsubcribe-call-action-renderer.component.html',
  styleUrls: ['./unsubcribe-call-action-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubcribeCallActionRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  subscription: Subscription | undefined = undefined;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.subscription = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.subscription = params.data;
    return true;
  }

  unsubscribe(): void {
    this.params.colDef?.cellRendererParams.unsubscribe(this.subscription);
  }
}
