<app-modal
  *ngIf="merchantInfo$ | async as merchant"
  [showModal]="showModal"
  (modalStatus)="toggleSubscriptionModal($event)"
>
  <div class="subscription">
    <div class="is-size-2 has-text-weight-semibold">Subscribe to {{ merchant.name }}</div>

    <ng-container *ngIf="(payment$ | async) === 0; else processPayment">
      <ng-container *ngFor="let plan of subscriptionPlans$ | async">
        <div class="mt-4">
          <div class="is-flex">
            <div class="thumbnail"><img [src]="plan.thumbnailUrl" /></div>
            <div class="ml-4">
              <div class="is-size-4 has-text-weight-semibold">{{ plan.name }}</div>
              <div class="is-size-6 has-text-weight-semibold">${{ plan.amount }}/month</div>
              <div class="mt-4">
                <div>What you get:</div>
                <div>
                  <ul class="benefits">
                    <li *ngFor="let benefit of plan.functions">{{ benefit.name }}</li>
                  </ul>
                </div>
              </div>

              <div class="mt-4">
                <button class="button is-primary" (click)="checkPaymentProfiles(plan)">Subscribe Now</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #processPayment>
      <div class="mt-6" *ngIf="(payment$ | async) === 1">
        <div class="is-flex is-align-items-center">
          <button class="button is-loading">Loading</button>
          <div class="ml-2">Processing payment...</div>
        </div>
      </div>

      <div class="payment-success text-center mt-6" *ngIf="(payment$ | async) === 2">
        <div class="is-size-4 has-text-weight-semibold">Thank you for subscribing!</div>
        <div class="mx-auto mt-6">
          <button class="button is-primary" (click)="subscriptionSuccessHome()">Back</button>
        </div>
      </div>

      <div class="mt-6" *ngIf="(payment$ | async) === 4">
        <div *ngIf="paymentProfiles$ | async as paymentProfiles; else loadingPaymentProfiles">
          <div *ngIf="paymentProfiles.length > 0">
            <div>You will be charged ${{ selectedPlan?.amount }}/month for this subscription.</div>
            <div class="mt-2">Select a credit card to use for this transaction:</div>
            <div class="d-flex justify-content-center">
              <div *ngFor="let p of paymentProfiles">
                <input type="radio" id="new" name="new" [value]="p.guid" [checked]="creditOption" class="mr-2" />
                <label class="has-text-primary new-label" for="new"
                  >Card ending {{ p.maskedNumber?.substring(4) }}</label
                >
              </div>
            </div>
            <div class="mt-6">
              <button class="button is-primary" [disabled]="!creditOption" (click)="confirmSubscription()">
                Proceed
              </button>
              <button class="button is-primary ml-3" (click)="resetPurchaseCheck()">Cancel</button>
            </div>
          </div>
          <div *ngIf="paymentProfiles.length == 0">
            <div>
              <div>There is no credit card on file.</div>
              <div>
                Please add your credit card in the
                <a routerLink="/settings" [queryParams]="{ ref: 'wallet' }">Profile</a> section.
              </div>
            </div>
            <div class="mt-6">
              <button class="button is-primary mr-4" routerLink="/settings" [queryParams]="{ ref: 'wallet' }">
                Add Credit Card
              </button>
              <button class="button is-ghost" (click)="resetPurchaseCheck()">Cancel</button>
            </div>
          </div>
        </div>

        <ng-template #loadingPaymentProfiles>
          <div class="is-flex is-align-items-center">
            <button class="button is-loading">Loading</button>
            <div class="ml-2">Loading...</div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</app-modal>
