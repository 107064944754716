<div class="columns" *ngIf="user$ | async as user">
  <div class="column is-half" [formGroup]="profileForm">
    <div class="field">
      <label class="label">Username (private - what you log in with)</label>
      <div class="control">
        <input id="user" class="input" type="text" formControlName="username" />
      </div>
    </div>

    <div class="field">
      <label class="label">Display name (public - what people see)</label>
      <div class="control">
        <input id="display" class="input" type="text" formControlName="displayName" />
      </div>
    </div>

    <div class="field">
      <label class="label">Email Address</label>
      <div class="control">
        <input class="input" type="text" formControlName="emailAddress" />
      </div>
    </div>

    <button class="button is-primary" (click)="editProfile()" [disabled]="isFormSubmitted | async">Update Profile</button>
  </div>
  <div class="column is-half">
    <div class="is-size-7 mb-1">Profile Picture</div>
    <div class="has-text-centered">
      <ng-container *ngIf="user.profileImageUrl && !imageUrl; else upload">
        <img class="profile-image" [src]="user.profileImageUrl" />
      </ng-container>
      <ng-template #upload>
        <img class="profile-image" [src]="imageUrl || 'assets/images/xgenflix-avatar.png'" alt="your image" />
      </ng-template>
      <button class="button is-outlined is-small mt-2" (click)="profile.click()">Select File</button>
    </div>

    <input class="is-invisible" type="file" accept="image/*" (change)="showPreview($event)" #profile />
  </div>
</div>

<div #supermodal class="supermodal" *ngIf="openModal">
  <image-cropper
    class="cropper-wrapper"
    format="png"
    [imageFile]="profileImageFile"
    [resizeToHeight]="400"
    [autoCrop]="false"
    (cropperReady)="cropperReady()"
    (imageCropped)="saveCoppedImage($event)"
    *ngIf="isAvatarChanged"
  ></image-cropper>

  <div #cropperControls class="controls">
    <button class="button is-secondary mr-2" (click)="cropImage()">Save Image</button>
    <button class="button is-secondary btn-cancel" (click)="resetImageCrop()">
      <img src="assets/images/close-regular-m.svg" />
    </button>
  </div>
</div>
