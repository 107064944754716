import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { User, UserAuth, UserConfig, UserMessageGroup } from '@model/user';
import { PaymentProfile } from '@model/payment-profile';
import { Models } from '@model/movies.models';
import { TokenBalance } from '@model/token-balance';
import { map } from 'rxjs/operators';
import { WatchedVideo } from '@model/watched-video';
import { UserFollow } from '@model/follower';
import { environment } from '@environment';
import TokenTransferDetails = Models.TokenTransferDetails;
import { Subscription } from '@model/subscription';
import { MerchantApplication } from '@model/merchant';

interface LoginRequest {
  username?: string;
  password?: string;
  platform?: string;
}

interface RegisterRequest {
  email?: string;
  username?: string;
  passworrd?: string;
  referralCode?: string;
  primaryPlatform?: string;
}

interface ChangePasswordRequest {
  oldPassword?: string | undefined;
  newPassword?: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  config: UserConfig = {};
  user: User | any = {};
  user$ = new BehaviorSubject<User | undefined>(undefined);
  isSubscribedToXStreamr = new BehaviorSubject(false);

  paymentProfiles: PaymentProfile[] = [];
  paymentProfiles$: BehaviorSubject<PaymentProfile[] | undefined> = new BehaviorSubject<PaymentProfile[] | undefined>(
    undefined
  );
  public currentBalance: number | undefined;

  constructor(private http: HttpClient) {}

  currentUserData(): Observable<User> {
    return this.http.get('/api/v1/users/current').pipe(
      map((next) => {
        this.setUserData(next);
        return next;
      })
    );
  }

  login(user: LoginRequest): Observable<UserAuth> {
    user.platform = environment.platform;
    return this.http.post('/api/v1/users/login/password', user);
  }

  register(user: RegisterRequest): Observable<object> {
    user.primaryPlatform = environment.platform;
    return this.http.post('/api/v1/users/register', user);
  }

  updateUser(user: User, guid: string): Observable<object> {
    return this.http.patch(`/api/v1/users/${guid}`, user);
  }

  changePassword(dataPassword: ChangePasswordRequest): Observable<object> {
    return this.http.post('/api/v1/users/password/change', dataPassword);
  }

  getUserPaymentInfo(userGuid: string): Observable<PaymentProfile[]> {
    return this.http.get<PaymentProfile[]>(`/api/v1/users/${userGuid}/userpaymentprofiles`);
  }

  getUserPaymentInfoObservable(userGuid: string): void {
    this.http.get(`/api/v1/users/${userGuid}/userpaymentprofiles`).subscribe((next) => {
      this.paymentProfiles$.next(next as PaymentProfile[]);
    });
  }

  getUserTokenBalances(): Observable<TokenBalance[]> {
    return this.http.get<TokenBalance[]>(`/api/v1/users/current/tokenbalances`);
  }

  public getTokenHistory(isTethered: boolean): Observable<TokenTransferDetails[]> {
    return this.http.get<TokenTransferDetails[]>(`/api/v1/users/current/tokentransfers`, {
      params: { isTetheredMerchantTransfer: isTethered }
    });
  }

  getReferralCodeUsage(): Observable<any> {
    return this.http.get(`/api/v1/users/current/referralstats`);
  }

  searchUser(params: { [p: string]: string }): Observable<User[]> {
    return this.http.get<User[]>(`/api/v1/users/search`, { params });
  }

  sendUserFeedbackUnauthenticated(email: string, message: string): Promise<any> {
    return this.http
      .post(`/api/v1/public/userfeedbacks`, {
        emailAddress: email,
        message
      })
      .toPromise();
  }

  initiatePasswordReset(username: string): Observable<any> {
    return this.http.post('/api/v1/users/password/reset/init', {
      method: 'Email',
      platform: environment.platform,
      username
    });
  }

  passwordReset(payload: { newPassword: string; emailVerificationCode: string }): Observable<any> {
    return this.http.post('/api/v1/users/password/reset', payload);
  }

  saveProfileImage(file: FormData, guid: string): Observable<any> {
    return this.http.post(`/api/v1/users/${guid}/profileimage/upload`, file);
  }

  userWatchedVideos(guid: string): Observable<WatchedVideo[]> {
    return this.http.get<WatchedVideo[]>(`/api/v1/users/${guid}/watchedvideos`, {});
  }

  userFollowing(guid: string): Observable<UserFollow[]> {
    return this.http.get<UserFollow[]>(`/api/v1/userfollowers/search`, {
      params: { userGUID: guid }
    });
  }

  setUserData(user: User): void {
    this.user = user;
    this.user$.next(user);
  }

  getVolume(): void {
    if (localStorage.getItem('unreel.config')) {
      this.config = JSON.parse(localStorage.getItem('unreel.config')!);
    } else {
      this.config = { mode: 'dark', volume: '1' };
    }
  }

  setVolume(volume: number): void {
    this.config.volume = volume.toString();
    localStorage.setItem('unreel.config', JSON.stringify(this.config));
  }

  getUser(userGuid: string): Observable<User> {
    return this.http.get(`/api/v1/users/${userGuid}`);
  }

  validateReferralCode(code: string): Observable<any> {
    return this.http.get(`/api/v1/users/existence/referralcode/${code}`, {
      params: {
        platform: environment.platform
      }
    });
  }

  getCurrentUserSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>('/api/v1/users/current/subscriptions');
  }

  applyAsMerchant(application: MerchantApplication): Observable<object> {
    return this.http.post('/api/v1/users/current/merchantapplications', application);
  }

  getUserMessageGroups(): Observable<UserMessageGroup[]> {
    return this.http.get<UserMessageGroup[]>('/api/v1/users/current/usermessagegroups');
  }
}
