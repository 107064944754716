import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserMessage, UserMessageGroup } from '@model/user';

@Injectable({
  providedIn: 'root'
})
export class UserMessageService {
  public hasMessage = new BehaviorSubject(false);
  public isOpenMessage = new BehaviorSubject(false);
  messageGroups$ = new BehaviorSubject<UserMessageGroup[] | undefined>(undefined);
  messageSubscription: Subscription = new Subscription();
  messages$ = new BehaviorSubject<UserMessage[] | undefined>(undefined);
  unreadMessage$ = new BehaviorSubject<UserMessage | undefined>(undefined);
  newMessage$ = new BehaviorSubject<UserMessage | undefined>(undefined);

  constructor(private http: HttpClient) {}

  createUserMessage(params: { [p: string]: string }): Observable<any> {
    return this.http.post(`/api/v1/usermessages`, params);
  }

  getUserMessages(params: { [key: string]: string }): Observable<UserMessage[]> {
    return this.http.get<UserMessage[]>(`/api/v1/usermessages/search`, { params });
  }

  markAllMessagesAsRead() {
    return this.http.put('/api/v1/users/current/usermessagegroups/markasread', {});
  }

  markMessageAsRead(guid: string, body: { [key: string]: string }) {
    return this.http.put(`/api/v1/users/current/usermessagegroups/${guid}/markasread`, null, {
      params: body
    });
  }
}
