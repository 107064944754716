<div>
  <ag-grid-angular
    style="width: 100%"
    class="ag-theme-alpine"
    [domLayout]="domLayout"
    [rowData]="rowData"
    [context]="context"
    [suppressCellFocus]="true"
    [columnDefs]="columns"
    [headerHeight]="headerHeight"
    [pagination]="true"
    [paginationPageSize]="10"
    [suppressPaginationPanel]="true"
    [overlayNoRowsTemplate]="noRowsTemplate"
    (paginationChanged)="onPaginationChanged($event)"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
  <div class="table-pagination">
    <div class="is-flex is-align-items-center">
      <div class="ml-auto">Showing page: {{ currentPage }}/{{ totalPages }}</div>
      <div class="ml-4">
        <button class="button is-primary is-small is-outlined" (click)="paginate('left')" [disabled]="currentPage == 1">
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="button is-primary is-small is-outlined ml-2"
          (click)="paginate('right')"
          [disabled]="currentPage == totalPages"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
