import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { environment } from '@environment';
import { ReferralCode } from '@model/referral-code';
import { ReferralCodeService } from '@service/referral-code.service';
import { UserService } from '@service/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-referral-codes',
  templateUrl: './referral-codes.component.html',
  styleUrls: ['./referral-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralCodesComponent implements OnInit {
  user = this.userService.user;
  @ViewChild('hiddenInput') hiddenInput: ElementRef | any;

  currentReferralCode: string | any = '';
  isCopied = false;
  isCodeChange = false;
  referralCodeCtrl: FormControl = new FormControl();
  referralCodes$: BehaviorSubject<ReferralCode[]>;
  isReferralCodeUsed$: BehaviorSubject<number>;

  env = environment;

  constructor(
    private referralCodeService: ReferralCodeService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService
  ) {
    this.isCopied = false;
    this.isCodeChange = false;
    this.referralCodes$ = new BehaviorSubject([{}]);
    this.isReferralCodeUsed$ = new BehaviorSubject(0);
  }

  ngOnInit() {
    this.currentReferralCode = this.user.referralCode;
    this.getReferralCodes();
    this.getCurrentSelectedReferralCode();
  }

  getReferralCodes(): void {
    this.referralCodeService.getUserReferralCodes().subscribe((next) => {
      this.referralCodes$.next(next);
    });
  }

  getCurrentSelectedReferralCode(): void {
    this.referralCodeService.getCurrentUserSelectedReferralCode().subscribe((next) => {
      this.currentReferralCode = next.code;
      this.referralCodeCtrl = new FormControl(this.currentReferralCode, this.codeValidator());

      this.cdRef.detectChanges();
    });
  }

  copyReferralCode() {
    navigator.clipboard.writeText(this.currentReferralCode);
    this.isCopied = true;
    setTimeout(() => {
      this.resetIsCopied();
    }, 5000);
  }

  createPermalink(): void {
    const link = `${environment.domains.baseUrl}/register?ref=` + this.currentReferralCode;
    navigator.clipboard.writeText(link);
    this.isCopied = true;
    setTimeout(() => {
      this.resetIsCopied();
    }, 5000);
  }

  changeCode(): void {
    this.isCodeChange = true;
    this.referralCodeCtrl.setValue('');
  }

  saveNewCode() {
    const code = this.referralCodeCtrl.value.toUpperCase();
    this.referralCodeService.addReferralCode(code, this.user.guid!).subscribe(
      () => {
        this.user.referralCode = code;
        this.referralCodeService
          .patchReferralCode({ code: this.user.referralCode, isDefault: false })
          .toPromise()
          .then((res) => {});
        this.isCodeChange = false;
        this.isReferralCodeUsed$.next(0);
        this.currentReferralCode = code;
        this.getReferralCodes();
      },
      () => {
        this.isReferralCodeUsed$.next(1);
      }
    );
  }

  cancelCodeChange(): void {
    this.isCodeChange = false;
    this.referralCodeCtrl.setValue(this.currentReferralCode);
  }

  codeValidator(): any {
    const regex = /^[A-Za-z\d]*$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const match = regex.test(control.value);
      return !match ? { passwordFormat: 'Password format is incorrect.' } : null;
    };
  }

  resetIsCopied() {
    this.isCopied = false;
    this.cdRef.detectChanges();
  }
}
