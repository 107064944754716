import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { TMDBPerson } from '@model/tmdb';

@Injectable({
  providedIn: 'root'
})
export class TmdbService {
  //   comments$ = new BehaviorSubject<Comment[]>([]);
  person: TMDBPerson | undefined = undefined;

  constructor(private http: HttpClient) {}

  searchMovie(params: { [p: string]: string | number }) {
    return this.http
      .get(`${environment.tmdbApiUrl}/search/movie?api_key=${environment.tmdbApiKey}`, { params })
      .toPromise();
  }

  getMovie(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/movie/${id}?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getMovieImages(id: string) {
    return this.http
      .get(
        `${environment.tmdbApiUrl}/movie/${id}/images?api_key=${environment.tmdbApiKey}&include_image_language=en,null`
      )
      .toPromise();
  }

  getMovieVideos(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/movie/${id}/videos?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getMovieReviews(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/movie/${id}/reviews?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getMovieCredits(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/movie/${id}/credits?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  searchPerson(params: { [p: string]: string | number }) {
    return this.http
      .get(`${environment.tmdbApiUrl}/search/person?api_key=${environment.tmdbApiKey}`, { params })
      .toPromise();
  }

  getPerson(id: string) {
    return this.http
      .get<TMDBPerson>(`${environment.tmdbApiUrl}/person/${id}?api_key=${environment.tmdbApiKey}`)
      .toPromise();
  }

  getPersonCombinedCredits(id: string) {
    return this.http
      .get(`${environment.tmdbApiUrl}/person/${id}/combined_credits?api_key=${environment.tmdbApiKey}`)
      .toPromise();
  }

  getPersonImages(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/person/${id}/images?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  multiSearch(params: { [p: string]: string | number }) {
    return this.http
      .get(`${environment.tmdbApiUrl}/search/multi?api_key=${environment.tmdbApiKey}`, { params })
      .toPromise();
  }

  searchCollection(params: { [p: string]: string | number }) {
    return this.http
      .get(`${environment.tmdbApiUrl}/search/collection?api_key=${environment.tmdbApiKey}`, { params })
      .toPromise();
  }

  searchTvShows(params: { [p: string]: string | number }) {
    return this.http
      .get(`${environment.tmdbApiUrl}/search/tv?api_key=${environment.tmdbApiKey}`, { params })
      .toPromise();
  }

  getTv(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/tv/${id}?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getTvImages(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/tv/${id}/images?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getTvReviews(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/tv/${id}/reviews?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getTvVideos(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/tv/${id}/videos?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getTvCredits(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/tv/${id}/credits?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getCredits(id: string) {
    return this.http.get(`${environment.tmdbApiUrl}/credit/${id}?api_key=${environment.tmdbApiKey}`).toPromise();
  }

  getGenreList(type: 'movie' | 'tv' = 'movie') {
    return this.http.get(`${environment.tmdbApiUrl}/genre/${type}/list?api_key=${environment.tmdbApiKey}`).toPromise();
  }
}
