import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilteredFilmsCategory, Post } from '@model/post';
import { Comment } from '@model/comment';
import { Models } from '@model/movies.models';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MerchantService } from './merchant.service';
import { PostGroup } from '@model/post-group';
import { TmdbService } from './tmdb.service';
import {
  CustomCastAndCrewData,
  TMDBGetVideoResponse,
  TMDBImage,
  TMDBImageResult,
  TMDBPerson,
  TMDBResult,
  TMDBVideo
} from '@model/tmdb';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment';
import { View } from '@model/view';
import { WatchedVideo } from '@model/watched-video';
import { Genre } from '@model/genre';

interface UnlockRequest {
  type?: string;
  paymentProfileGUID?: string;
}
@Injectable({
  providedIn: 'root'
})
export class PostService {
  post$ = new BehaviorSubject<Post | undefined>(undefined);
  castAndCrew: TMDBPerson[] = [];
  insufficientFanbucksEvent: EventEmitter<void> | any;
  seasons$ = new BehaviorSubject<Post[] | undefined>(undefined);
  constructor(
    private http: HttpClient,
    private router: Router,
    private merchantService: MerchantService,
    private tmdbService: TmdbService,
    private sanitizer: DomSanitizer
  ) {
    this.insufficientFanbucksEvent = new EventEmitter();
  }

  getPosts(type: string): Observable<Post[]> {
    return this.http.get<Post[]>('/api/v1/posts/search', {
      params: {
        platform: environment.platform,
        merchantArtistType: 'Filmmaker',
        postType: type
      }
    });
  }

  getPost(postGuid: string): Observable<Post> {
    return this.http.get(`/api/v1/posts/${postGuid}`);
  }

  searchPost(params: { [p: string]: any }): Observable<Post[]> {
    return this.http.get<Post[]>(`/api/v1/posts/search`, { params });
  }

  searchTrendingByDays(params: { [p: string]: string }): Observable<Post[]> {
    return this.http.get<Post[]>(`/api/v1/trending/posts`, { params });
  }

  addComment(comment: Comment) {
    return this.http.post<Comment>(`/api/v1/comments`, comment);
  }

  updateComment(comment: Comment) {
    return this.http.patch<Comment>(`/api/v1/comments/${comment.guid}`, comment);
  }

  deleteComment(commentGUID: string) {
    return this.http.delete(`/api/v1/comments/${commentGUID}`);
  }

  getPostReference(postGuid: string): Observable<Post> {
    return this.http.get(`/api/v1/posts/${postGuid}/postreferences`);
  }

  getPostGroup(postGroupGUID: string): Observable<Post[]> {
    return this.http.get<Post[]>('/api/v1/posts/search', {
      params: {
        postGroupGUID
      }
    });
  }

  searchPostGroups(query: { [p: string]: any }) {
    return this.http.get('/api/v1/postgroups/search', {
      params: query
    });
  }

  unlockPost(guid: string, unlockRequest: UnlockRequest) {
    return this.http.post(`/api/v1/posts/${guid}/unlock`, unlockRequest);
  }

  getPostRatings(postGuid: string) {
    return this.http.get<Models.UserPostRating[]>(`/api/v1/posts/${postGuid}/userpostratings`);
  }

  searchPostRatings(query: { [p: string]: any }) {
    return this.http.get<Models.UserPostRating[]>('/api/v1/userpostratings/search', {
      params: query
    });
  }

  savePostUserRating(query: { [p: string]: any }) {
    return this.http.post('/api/v1/userpostratings', query);
  }

  updatePostUserRating(ratingGUID: string, query: Models.IPostRatingRequest) {
    return this.http.patch(`/api/v1/userpostratings/${ratingGUID}`, query);
  }

  deletePostUserRating(ratingGUID: string) {
    return this.http.delete(`/api/v1/userpostratings/${ratingGUID}`);
  }

  private reduceDuplicate(persons: TMDBPerson[], type: string = 'cast', posts?: Post[]) {
    const uniquePersons: any = [];
    const checkPerson = persons.filter((p) => {
      const isDuplicate = uniquePersons.includes(p.id);
      if (!isDuplicate) {
        uniquePersons.push(p.id);
        return true;
      }
      return false;
    });
    return checkPerson;
  }

  private addPostToPersonsList(posts: Post[]) {
    const unlistedPost: Post[] | any[] = [];

    posts.map((post, index: number) => {
      const personsWithPost = this.castAndCrew.filter((p) => p.postData !== undefined);
      if (personsWithPost !== undefined) {
        const find = personsWithPost.find((p) => p.postData?.guid === post.guid);
        if (find === undefined) {
          const data: TMDBPerson = {
            id: 1000000000 + index,
            name: post.name,
            description: post.description
          };
          const tempPersonData: CustomCastAndCrewData = {
            id: 1000000000 + index,
            data: [data],
            description: post.description,
            name: post.name,
            profilePath:
              post.normalizedThumbnailImageUrl || post.thumbnailImageUrl || 'assets/images/default-profile.png',
            known_for_department: 'Custom:',
            isSelected: false,
            post,
            type: 'custom',
            order: post.displayOrder || this.castAndCrew.length
          };
          unlistedPost.push(tempPersonData);

          if (post.displayOrder) {
            this.castAndCrew.splice(post.displayOrder, 0, tempPersonData);
          }
        }
      }
    });
  }

  createPostView(postView: View) {
    return this.http.post(`/api/v1/posts/${postView.postGUID}/views`, postView);
  }

  getFeaturedPosts(type: string) {
    const params = {
      postType: type
    };
    return this.http.get<Post[]>('/api/v2/featured/posts', { params }).toPromise();
  }

  getPostMovie(guid: string, mergeFromTmdbCastAndCrewDataToResponse: boolean = false) {
    return this.http
      .get<Post>(`/api/v1/posts/${guid}/tmdbinformation`, {
        params: {
          mergeFromTmdbCastAndCrewDataToResponse
        }
      })
      .pipe(
        map((post) => {
          post.mergeCastAndCrew = [];
          //cast
          if (post.tmdbCast?.postDatas) {
            post.tmdbCast.postDatas.map((cast) => {
              if (cast.tmdbImageUrl) {
                cast.tmdbImageUrl =
                  cast.tmdbImageUrl !== null ? `${environment.tmdbResource}w185${cast.tmdbImageUrl}` : null;
              }
              cast.isHeyFansMerchant = cast.merchantLinkProfileGUID ? true : false;
              return cast;
            });
            post.mergeCastAndCrew = post.mergeCastAndCrew?.concat(post.tmdbCast?.postDatas);
          }
          if (post.tmdbCast?.casts) {
            post.tmdbCast.casts.map((cast) => {
              if (cast.profilePath !== undefined) {
                cast.hqProfilePath =
                  cast.profilePath !== null ? `${environment.tmdbResource}h632${cast.profilePath}` : null;
                cast.profilePath =
                  cast.profilePath !== null ? `${environment.tmdbResource}w185${cast.profilePath}` : null;
                if (cast.postData && cast.postData.tmdbImageUrl) {
                  cast.postData.tmdbImageUrl =
                    cast.postData?.tmdbImageUrl !== null
                      ? `${environment.tmdbResource}w185${cast.postData?.tmdbImageUrl}`
                      : null;
                  cast.postData.isHeyFansMerchant = cast.postData?.merchantLinkProfileGUID ? true : false;
                }
              }
              return cast;
            });
          }
          //end cast

          //crew
          if (post.tmdbCrew?.postDatas) {
            post.tmdbCrew.postDatas.map((crew) => {
              if (crew && crew.tmdbImageUrl) {
                crew.tmdbImageUrl =
                  crew.tmdbImageUrl !== null ? `${environment.tmdbResource}w185${crew.tmdbImageUrl}` : null;
              }
              crew.isHeyFansMerchant = crew.merchantLinkProfileGUID ? true : false;

              return crew;
            });
            post.mergeCastAndCrew = post.mergeCastAndCrew?.concat(post.tmdbCrew?.postDatas);
          }
          if (post.tmdbCrew?.casts) {
            post.tmdbCrew.casts.map((crew) => {
              if (crew.profilePath !== undefined) {
                crew.hqProfilePath =
                  crew.profilePath !== null ? `${environment.tmdbResource}h632${crew.profilePath}` : null;
                crew.profilePath =
                  crew.profilePath !== null ? `${environment.tmdbResource}w185${crew.profilePath}` : null;
                if (crew.postData && crew.postData.tmdbImageUrl) {
                  crew.postData.tmdbImageUrl =
                    crew.postData?.tmdbImageUrl !== null
                      ? `${environment.tmdbResource}w185${crew.postData?.tmdbImageUrl}`
                      : null;
                  crew.postData.isHeyFansMerchant = crew.postData?.merchantLinkProfileGUID ? true : false;
                }
              }
              return crew;
            });
          }
          //end crew
          if (post.tmdbCast?.casts) {
            post.tmdbCast.casts = this.reduceDuplicate(post.tmdbCast.casts, 'cast');
          }
          if (post.tmdbCrew?.crews) {
            post.tmdbCrew.crews = this.reduceDuplicate(post.tmdbCrew.crews, 'crew');
          }

          if (post.tmdbGallery) {
            post.tmdbGallery.map((image) => {
              if (image.tmdbImageUrl !== undefined) {
                image.tmdbUrl = environment.tmdbResource + 'w342' + image.tmdbImageUrl;
                image.tmdbHqUrl = `${environment.tmdbResource}original${image.tmdbImageUrl}`;
              }
              return image;
            });
          }

          if (post.tmdbVideos) {
            post.tmdbVideos.map((video) => {
              return (video.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `https://www.youtube.com/embed/${video.tmdbVideoLinkID}`
              ));
            });
          }
          if (post.tmdbImageUrl) {
            post.tmdbImageUrl = environment.tmdbResource + 'w342' + post.tmdbImageUrl;
          }
          if (post.tmdbBackdropImageUrl) {
            post.tmdbBackdropImageUrl = environment.tmdbResource + 'original' + post.tmdbBackdropImageUrl;
          }

          if (post.videoUrl) {
            post.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(post.videoUrl);
          }

          let videos: any[] = [];
          if (post.tmdbID && post.tmdbType === 'Movie') {
            this.tmdbService.getMovieVideos(post.tmdbID.toString()).then((videoRes: any) => {
              videos = videoRes.results as TMDBGetVideoResponse[];
              const trailers = videos.filter((v) => v.type === 'Trailer');
              if (trailers !== undefined && trailers.length > 0) {
                post.tmdbSanitizeTrailerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://www.youtube.com/embed/${trailers[0].key}`
                );
              }
            });
          } else if (post.tmdbID && post.tmdbType === 'TVShow') {
            this.tmdbService.getTvVideos(post.tmdbID.toString()).then((videoRes: any) => {
              videos = videoRes.results as TMDBGetVideoResponse[];
              const trailers = videos.filter((v) => v.type === 'Trailer');
              if (trailers !== undefined && trailers.length > 0) {
                post.tmdbSanitizeTrailerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://www.youtube.com/embed/${trailers[0].key}`
                );
              }
            });
          }

          return post;
        })
      )
      .toPromise();
  }

  getPostGroupByGuid(postGuid: string) {
    return this.http.get<PostGroup[]>(`/api/v1/posts/${postGuid}/postgroups`).toPromise();
  }

  getPostGroupLastWatched(postGuid: string, postgroupGuid: string) {
    return this.http.get<WatchedVideo[]>(`/api/v1/posts/${postGuid}/${postgroupGuid}/watchedvideos`).toPromise();
  }

  addToSavedPost(guid: string) {
    return this.http.post(`/api/v1/posts/${guid}/save`, {});
  }

  removeToSavedPost(guid: string) {
    return this.http.post(`/api/v1/posts/${guid}/unsave`, {});
  }

  likePost(data: Comment) {
    return this.http.post<string>(`/api/v1/posts/${data.guid}/reactions`, data);
  }

  unlikePost(guid: string) {
    return this.http.delete(`/api/v1/posts/${guid}/reactions`);
  }

  patchPostLastWatchedVideos(guid: string, lastWatchedVideoPostGUID: string) {
    return this.http.patch(`/api/v1/posts/${guid}/lastwatchedvideo`, {
      guid: guid,
      lastWatchedVideoPostGUID: lastWatchedVideoPostGUID
    });
  }

  searchPostCategories(type: string) {
    const params = {
      postCategoryGroupKey: type
    };

    return this.http.get<Genre[]>('/api/v1/postcategories/search', { params }).toPromise();
  }

  getCategoryWithPost() {
    return this.http.get<FilteredFilmsCategory[]>(`/api/v1/posts/films`);
  }
}
