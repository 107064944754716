<ng-container *ngIf="showModal$ | async as showModal">
  <app-modal [showModal]="showModal" (modalStatus)="closeModal($event)" class="bring-to-top">
    <div class="dialog column px-5">
      <div class="container">
        <div class="tabs">
          <ul>
            <li [class.is-active]="selectedOption === 1"><a (click)="setTab(1)">Video</a></li>
            <li [class.is-active]="selectedOption === 2"><a (click)="setTab(2)">Audio</a></li>
          </ul>
        </div>
      </div>
      <!-- <p>Permission: {{ hasPermission }}</p> -->

      <div [ngSwitch]="selectedOption">
        <div *ngSwitchCase="1">
          <div class="flex has-text-left mb-2 mt-2">
            <div>Camera</div>
            <div class="flexbox">
              <button
                class="button is-primary mr-2"
                type="button"
                (click)="isRunningCamera ? stopAllDevice() : testCamera(camera.value)"
              >
                {{ isRunningCamera ? 'Stop' : hasPermission ? 'Test' : 'Allow' }} Camera
              </button>
              <div class="field">
                <div class="control">
                  <select
                    id="camera"
                    #camera
                    (change)="isRunningCamera ? testCamera(camera.value) : changeCameraInput(camera.value)"
                    class="input"
                  >
                    <option *ngFor="let camera of cameras | async" [value]="camera.deviceId">
                      {{ camera.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <video *ngIf="isRunningCamera" id="video" #videoElement></video>
        </div>
        <div *ngSwitchCase="2">
          <div class="flex has-text-left mb-2 mt-2">
            <div>Speaker</div>
            <div class="flexbox speaker-list">
              <button
                class="button is-primary mr-2"
                type="button"
                (click)="!zoomService.isRunningSpeaker ? testAudioOutput(speaker.value) : stopAudioOutput()"
                [disabled]="zoomService.isRunningSpeaker"
              >
                {{ isRunningSpeaker ? 'Stop' : hasPermission ? 'Test' : 'Allow' }} Speaker
              </button>
              <div class="field">
                <div class="control">
                  <select id="speaker" #speaker (change)="changeAudioOutput(speaker.value)" class="input">
                    <option *ngFor="let speaker of speakers | async" [value]="speaker.deviceId">
                      {{ speaker.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flexbox speaker-list">
              <span class="mr-2"> Output Level: </span>
              <div class="level-container">
                <div
                  class="level"
                  *ngIf="{ value: speakerLevel$ | async } as level"
                  [style.width]="level.value + '%'"
                ></div>
              </div>
            </div>
          </div>

          <div class="flex has-text-left mb-2 mt-2">
            <div>Microphone</div>
            <div class="flexbox mic-list">
              <button
                class="button is-primary mr-2"
                type="button"
                (click)="!zoomService.isRunningMic ? testAudioInput(mic.value) : stopAudioInput()"
                [disabled]="isRunningMic"
              >
                {{ zoomService.isRunningMic ? 'Stop' : hasPermission ? 'Test' : 'Allow' }} Microphone
              </button>
              <div class="field">
                <div class="control">
                  <select id="microphone" #mic (change)="changeAudioInput(mic.value)" class="input">
                    <option *ngFor="let microphone of microphones | async" [value]="microphone.deviceId">
                      {{ microphone.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flexbox mic-list">
              <span class="mr-2"> Input Level: </span>
              <div class="level-container">
                <div
                  class="level"
                  *ngIf="{ value: micLevel$ | async } as level"
                  [style.width]="level.value + '%'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="videoCallData | async as data">
        <ng-container *ngIf="!callInProgress">
          <div class="flex has-text-center">
            <div *ngIf="hasPermission" class="flex is-size-4">
              Join {{ isMerchant ? data.userDisplayName : data.merchantName }}'s Video call
            </div>
            <!-- <pre>
              {{ data | json }}
            </pre> -->
            <p *ngIf="hasPermission" class="my-4">
              If you're using Safari and experiencing video call problems, kindly use another browser.
            </p>
            <button *ngIf="hasPermission" class="button is-primary" (click)="joinCall()">Join</button>
            <p *ngIf="!hasPermission">
              Please allow permission to access your camera and microphone to start video call.
            </p>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </app-modal>
</ng-container>
