import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Announcement } from '@model/announcement';
import { Notification } from '@model/notification';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public hasNotification = new BehaviorSubject(false);
  notifications$ = new BehaviorSubject<Notification[] | undefined>(undefined);
  announcements$ = new BehaviorSubject<Announcement[] | undefined>(undefined);
  notificationSubscription: Subscription = new Subscription();
  announcementSubscription: Subscription = new Subscription();

  constructor(private http: HttpClient) {}

  getNotifications() {
    return this.http.get<Notification[]>(`/api/v1/users/current/notifications`);
  }

  getAnnouncements() {
    return this.http.get<Announcement[]>(`/api/v1/users/current/followerannouncements`);
  }

  markAllNotificationAsRead() {
    return this.http.put('/api/v1/users/current/notifications/markasread', {});
  }

  markNotificationAsRead(guid: string) {
    return this.http.patch(`/api/v1/notifications/${guid}`, {});
  }

  webSocketGetNotifications() {
    this.getNotifications()
      .pipe(
        map((notifications) =>
          notifications.map((notification) => {
            notification.dateCreated = moment(notification.dateCreated).fromNow();
            return notification;
          })
        )
      )
      .toPromise()
      .then((next) => {
        this.notifications$.next(next);
      });
  }
}
