import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from '@model/user';
import { UserService } from '@service/user.service';
import { ToastService } from 'app/shared/components/toast/toast.service';
import { dataUrltoFile } from 'app/shared/utils/dataurl-utils';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTabComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  @ViewChild('cropperControls') cropperControls!: ElementRef;
  @ViewChild('supermodal') supermodal!: ElementRef;
  public user$: BehaviorSubject<User> = new BehaviorSubject({});

  public file!: File;
  public imageUrl: any;
  public isAvatarChanged = false;
  public openModal = false;
  public profileImageFile!: File;
  public isFormSubmitted = new BehaviorSubject(false);
  public saveCount = 0;
  public showToastr = new Subject();

  public profileForm: FormGroup;

  constructor(public userService: UserService, private toastService: ToastService, private renderer: Renderer2) {
    this.profileForm = new FormGroup({
      username: new FormControl(),
      displayName: new FormControl(),
      emailAddress: new FormControl()
    });
  }

  ngOnInit(): void {
    this.getCurrentUserData();
    this.showToastr.subscribe((next) => {
      if (next === this.saveCount) {
        this.toastService.item({ type: 'white', description: 'You have successfully updated your profile.' });
      }
    });
  }

  getCurrentUserData(): void {
    this.user$.next(this.userService.user);
    this.profileForm.patchValue(this.userService.user);
  }

  /* Load Image into DOM */
  showPreview(event: any): void {
    if (event && event.target && event.target.files[0]) {
      this.profileImageFile = event.target.files[0];
      this.openModal = true;
      this.isAvatarChanged = true;
      this.saveCount++;
    }
  }

  editProfile(): void {
    this.isFormSubmitted.next(true);
    let counter = new BehaviorSubject(1);
    this.saveCount++;
    if (this.isAvatarChanged) {
      counter.next(counter.getValue() + 1);
      this.updateAvatar();
    }

    this.userService.updateUser(this.profileForm.value, this.user$.value.guid as string).subscribe(
      () => {
        this.userService.user.username = this.profileForm.get('username')?.value;
        this.userService.user.displayName = this.profileForm.get('displayName')?.value;
        this.userService.user.emailAddress = this.profileForm.get('emailAddress')?.value;
        this.isFormSubmitted.next(false);
        this.showToastr.next(counter.getValue());
        this.saveCount = 0;
      },
      (error) => {
        this.isFormSubmitted.next(false);
        if (error.error.errorCode === 'DuplicateEmailAddress') {
          this.toastService.item({
            type: 'danger',
            description: 'The email address is already used with another account.'
          });
        } else {
          this.toastService.item({ type: 'danger', description: 'Update profile failed.' });
        }
      }
    );
  }

  updateAvatar(): void {
    if (this.profileImageFile != null) {
      const formData: FormData = new FormData();
      formData.append('file', this.profileImageFile);
      this.userService.saveProfileImage(formData, this.user$.value.guid as string).subscribe((res) => {
        this.isAvatarChanged = false;
        this.userService.user.profileImageUrl = this.imageUrl;
        const newUserData = this.user$.value;
        newUserData.profileImageUrl = this.imageUrl;
        this.user$.next(newUserData);
        this.userService.setUserData(newUserData);
        //this.toastService.item({ type: 'white', description: 'You have successfully updated your profile picture.' });
      });
    }
  }

  cropImage() {
    this.imageCropper.crop();
    this.resetImageCrop();
  }

  cropperReady() {
    const imageEl = this.imageCropper.sourceImage.nativeElement;
    const controlsEl = this.cropperControls.nativeElement;

    const fullH = this.supermodal.nativeElement.clientHeight;
    const fullW = this.supermodal.nativeElement.clientWidth;
    const imageW = imageEl.clientWidth;
    const imageH = imageEl.clientHeight;
    let topValue = (fullH - imageH) / 2;
    let rightValue = (fullW - imageW) / 2 - (controlsEl.clientWidth + 16);

    if (rightValue < 16) {
      rightValue = 16;
      topValue = topValue - 48;
    }

    this.renderer.setStyle(controlsEl, 'top', `${topValue}px`);
    this.renderer.setStyle(controlsEl, 'right', `${rightValue}px`);
  }

  resetImageCrop() {
    //this.isAvatarChanged = false;
    this.openModal = false;
  }

  saveCoppedImage(event: any) {
    this.profileImageFile = dataUrltoFile(event.base64);
    this.imageUrl = event.base64;
  }
}
