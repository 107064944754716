import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { environment } from '@environment';
import { User } from '@model/user';
import { LiveEventService } from '@service/live-event.service';
import { LiveEvent } from '@model/live-event';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets-tab.component.html',
  styleUrls: ['./tickets-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketsTabComponent implements OnInit {
  env = environment;
  liveEvents$ = new BehaviorSubject<LiveEvent[] | undefined>(undefined);
  pastLiveEvents$ = new BehaviorSubject<LiveEvent[] | undefined>(undefined);
  user$ = new BehaviorSubject<User | undefined>(undefined);

  isPaymentModalOpen = false;
  selectedTicket?: LiveEvent;
  payment$ = new BehaviorSubject(0);

  constructor(private liveEventService: LiveEventService, private router: Router) {}

  ngOnInit(): void {
    this.getTickets();
  }

  getTickets(): void {
    this.liveEventService.getLiveEventTicketsByUser().subscribe((next) => {
      this.liveEvents$.next(next.filter((x) => x.dateOfEvent && new Date(x.dateOfEvent) >= new Date()));
      this.pastLiveEvents$.next(next.filter((x) => x.dateOfEvent && new Date(x.dateOfEvent) < new Date()));
    });
  }

  selectLiveEvent(ticket: LiveEvent): void {
    if (ticket.liveStreamStatus === 'Active' && ticket.hasTicketForCallingUser) {
      this.router.navigate(['/watch/live-event/', ticket.guid]);
    } else {
      this.isPaymentModalOpen = true;
      this.selectedTicket = ticket;
    }
  }

  closePaymentOptionModal(event: any): void {
    this.isPaymentModalOpen = event;
    this.payment$.next(0);
    if (!event) {
      this.selectedTicket = undefined;
    }
  }

  cancelTicket(): void {
    const answer = confirm('Are you sure you want to cancel your ticket for this event?');
    if (answer) {
      this.liveEventService.cancelTicket(this.selectedTicket?.liveEventTicketGUID as string).subscribe(() => {
        this.selectedTicket!.hasTicketForCallingUser = false;
        this.closePaymentOptionModal(false);

        this.getTickets();
      });
    }
  }
}
