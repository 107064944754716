import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comment } from '@model/comment';
import { LiveStream, LiveStreamResponse } from '@model/live-stream';
import { Message, MessageGroup } from '@model/message';
import { Post } from '@model/post';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { propertySort } from '../utils/sort-utils';
import { PostService } from './post.service';

@Injectable({
  providedIn: 'root'
})
export class LiveStreamService {
  liveStreams$ = new BehaviorSubject<Post[]>([]);
  previousLiveStreams$ = new BehaviorSubject<Post[]>([]);
  comment$: BehaviorSubject<Comment | any> = new BehaviorSubject(undefined);
  messageGroups$: BehaviorSubject<MessageGroup[]> | any;
  liveStreamResponse$: BehaviorSubject<LiveStreamResponse> = new BehaviorSubject({});
  hasUnread$: BehaviorSubject<boolean> | any;

  private lastSenderGuid!: string;
  private lastMessageGroup!: MessageGroup;
  // private hubConnection: signalR.HubConnection;

  constructor(private http: HttpClient, private postService: PostService) {}

  getLiveStream(streamGuid: string): Observable<LiveStream> {
    return this.http.get(`/api/v1/posts/${streamGuid}/livestream`);
  }

  addComment(liveStreamGuid: string, comment: Comment): Observable<Comment> {
    return this.http.post(`/api/v1/livestreams/${liveStreamGuid}/comments`, comment);
  }

  initializeChat(liveStreamGuid: string): void {
    this.http.get(`/api/v1/livestreams/${liveStreamGuid}/comments`).subscribe((next: Comment[] | any) => {
      next.sort(propertySort('dateCreated'));
      for (const comment of next) {
        if (!comment.reactionType) {
          // return comment;
          this.comment$.next(comment);
        }
      }
    });
  }

  getLiveEventLiveStream(guid: string): Observable<LiveStream[]> {
    return this.http.get<LiveStream[]>(`/api/v1/liveevents/${guid}/livestream`);
  }

  addMessage(message: Message): Observable<Message> {
    return this.http.post('/api/v1/usermessages', message).pipe(
      map(() => {
        this.chainMessage(message);
        return message;
      })
    );
  }

  chainMessage(message: Message): void {
    const messageGroups = this.messageGroups$.getValue();
    if (this.lastSenderGuid !== message.fromUserGUID) {
      this.lastMessageGroup = {
        fromUserGUID: message.fromUserGUID,
        profileImageUrl: message.fromUserProfileImageUrl,
        messages: []
      };
      this.lastMessageGroup.messages!.push(message);
      this.lastSenderGuid = message.fromUserGUID!;
      messageGroups.push(this.lastMessageGroup);
    } else {
      this.lastMessageGroup.messages!.push(message);
      messageGroups.splice(messageGroups.length - 1, 1, this.lastMessageGroup);
    }
    this.messageGroups$.next(messageGroups);
  }

  getActiveLiveStreams(): void {
    this.postService
      .searchPost({
        postType: 'LiveStream',
        liveStreamStatus: 'Active',
        isPublished: 'true'
      })
      .toPromise()
      .then((res) => {
        const result = res.filter((r) => r.liveStreamStatus === 'Active');
        this.liveStreams$.next(result);
      });
  }

  getPreviousLiveStreams(): void {
    this.postService
      .searchPost({
        postType: 'LiveStream',
        liveStreamStatus: 'Completed',
        isPublished: 'true'
      })
      .toPromise()
      .then((res) => {
        const result = res.filter((r) => r.liveStreamStatus === 'Completed');
        this.previousLiveStreams$.next(result);
      });
  }

  getLiveStreamComments(guid: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`/api/v1/livestreams/${guid}/comments`);
  }
}
