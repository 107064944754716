import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STATES } from '@const/states';
import { PaymentProfile } from '@model/payment-profile';
import { User } from '@model/user';
import { PaymentService } from '@service/payment.service';
import { UserService } from '@service/user.service';
import { ToastService } from 'app/shared/components/toast/toast.service';
import { BehaviorSubject } from 'rxjs';
import { TokenBalance } from '@model/token-balance';

declare class Payment {
  static formatCardNumber(element: Element | null): void;
  static formatCardExpiry(element: Element | null): void;
  static formatCardCVC(element: Element | null): void;
}

@Component({
  selector: 'app-payment-details-tab',
  templateUrl: './payment-details-tab.component.html',
  styleUrls: ['./payment-details-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDetailsTabComponent implements OnInit {
  user: User | undefined;
  creditOption: any = '';
  creditForm: FormGroup;
  selectedPaymentProfile: PaymentProfile | undefined;
  public cardSelected: PaymentProfile | undefined;
  private _paymentProfiles: PaymentProfile[] = [];
  paymentProfiles$ = new BehaviorSubject<PaymentProfile[]>([]);
  isErrorMessage$ = new BehaviorSubject<number>(0);
  statesList = STATES;

  addNewPaymentDetails$ = new BehaviorSubject(false);
  isEdit$ = new BehaviorSubject(false);

  kudoBalance$ = new BehaviorSubject<number>(0);

  @ViewChild('cardNumberRef') cardNumberRef!: ElementRef<HTMLInputElement>;
  @ViewChild('cardExpiryRef') cardExpiryRef!: ElementRef<HTMLInputElement>;
  @ViewChild('cardCvvRef') cardCvvRef!: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastService: ToastService,
    private paymentService: PaymentService
  ) {
    this.user = this.userService.user;
    this._paymentProfiles = this.userService.paymentProfiles;
    this.creditForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      creditCardNumber: ['', [Validators.required]],
      expirationDate: ['', [Validators.required]],
      cardCode: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      isDefault: [''],
      existing: ['']
    });
  }

  ngOnInit() {
    this.getCurrentUserBalance();

    setTimeout(() => {
      Payment.formatCardNumber(this.cardNumberRef.nativeElement);
      Payment.formatCardExpiry(this.cardExpiryRef.nativeElement);
      Payment.formatCardCVC(this.cardCvvRef.nativeElement);
    }, 50);

    if (this._paymentProfiles.length > 0) {
      this.populateUserPaymentForm(this._paymentProfiles);
    } else {
      this.getUserPaymentInfo();
    }
  }

  getUserPaymentInfo() {
    this.userService.getUserPaymentInfo(this.user && this.user.guid ? this.user.guid : '').subscribe((res) => {
      this.populateUserPaymentForm(res);
    });
  }

  populateUserPaymentForm(paymentProfiles: PaymentProfile[]) {
    this._paymentProfiles = paymentProfiles;
    if (this._paymentProfiles.length === 0) {
      this.creditForm.enable();
    }
    // Set Default Payment
    if (paymentProfiles && paymentProfiles.length) {
      this.selectedPaymentProfile = paymentProfiles.filter((r) => r.isDefault)[0];
    }
    this.paymentProfiles$.next(this._paymentProfiles);
  }

  savePaymentInfo() {
    const { isDefault } = this.creditForm.value;
    delete this.creditForm.value.existing;
    // Check if there is already a default payment
    const oldDefault = Object.assign({}, this.selectedPaymentProfile, { isDefault: false });

    if (!this.isEdit$.value) {
      this.creditForm.value.creditCardNumber = this.creditForm.value.creditCardNumber.replace(/\s+/g, '');
      this.creditForm.value.expirationDate = this.creditForm.value.expirationDate.replace(/\s+/g, '');

      this.paymentService.addPaymentProfile(this.creditForm.value).subscribe(
        (res) => {
          if (isDefault) {
            this.selectedPaymentProfile = res;
            if (oldDefault && oldDefault.guid) {
              this.paymentService.updatePaymentProfile(oldDefault).subscribe();
            }
          }
          this._paymentProfiles.push(res);
          this.paymentProfiles$.next(this._paymentProfiles);
          this.creditForm.reset();
          this.creditOption = '';
          this.toastService.item({ type: 'white', description: 'Card successfully added.', duration: 5000 });

          this.isErrorMessage$.next(0);
          this.resetFlags();
        },
        (error) => {
          console.log(error);
          this.toastService.item({
            type: 'danger',
            description: 'Unable to add card. Please check and try again',
            duration: 5000
          });

          this.isErrorMessage$.next(1);
        }
      );
    } else {
      // edit card details
      const paymentProfile = { ...this.creditForm.value, guid: this.cardSelected?.guid };

      this.paymentService.updatePaymentProfile(paymentProfile).subscribe(() => {
        this.toastService.item({ type: 'white', description: 'Card successfully updated.', duration: 5000 });
        this.getUserPaymentInfo();

        this.resetFlags(true);
      });
    }
  }

  selectCard(paymentProfile: PaymentProfile): void {
    this.cardSelected = paymentProfile;

    if (this.addNewPaymentDetails$.value && this.isEdit$.value) {
      this.creditForm.controls['expirationDate'].setValue(paymentProfile.expirationDate);
      this.creditForm.controls['creditCardNumber'].setValue(paymentProfile.maskedNumber);
    }
  }

  setAsDefaultCard(paymentProfile: PaymentProfile): void {
    const updatedPaymentProfile = { ...paymentProfile, isDefault: true };

    this.paymentService.updatePaymentProfile(updatedPaymentProfile).subscribe(() => {
      this.toastService.item({
        type: 'white',
        description: `Card ending in ${paymentProfile.maskedNumber} is now the default card.`,
        duration: 5000
      });
      this.getUserPaymentInfo();
    });
  }

  updateCard(paymentProfile: PaymentProfile): void {
    this.resetFlags();
    this.creditForm.reset();

    this.isEdit$.next(true);
    this.addNewPaymentDetails$.next(true);

    this.creditForm.controls['expirationDate'].setValue(paymentProfile.expirationDate);
    this.creditForm.controls['creditCardNumber'].setValue(paymentProfile.maskedNumber);
  }

  removeCard(paymentProfile: PaymentProfile) {
    const answer = confirm(
      `Are you sure you want to remove your card ending in ${paymentProfile.maskedNumber?.substring(4)}?`
    );
    if (answer) {
      this.paymentService.removePaymentProfile(paymentProfile).subscribe(() => {
        this._paymentProfiles = this._paymentProfiles.filter((x) => x.guid !== paymentProfile.guid);
        this.paymentProfiles$.next(this._paymentProfiles);

        this.resetFlags(true);
      });
    }
  }

  addNewCard() {
    this.creditForm.enable();
  }

  resetFlags(isDelete?: boolean): void {
    this.addNewPaymentDetails$.next(false);
    this.isEdit$.next(false);

    if (isDelete) {
      this.cardSelected = undefined;
      this.creditForm.reset();
    }
  }

  getCurrentUserBalance(): void {
    this.userService.getUserTokenBalances().subscribe((next: TokenBalance[]) => {
      const fanbucks = next.find((x) => x.tokenProfile.key === 'KUDO' && !x.tetheredMerchantGUID);

      if (fanbucks) {
        this.kudoBalance$.next(fanbucks.balance);
      }
    });
  }
}
