import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastItemComponent } from './toast-item/toast-item.component';
import { ToastComponent } from './toast.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, RouterModule],
  declarations: [ToastComponent, ToastItemComponent],
  exports: [ToastComponent, ToastItemComponent]
})
export class ToastModule {}
