import { v4 as uuidv4 } from 'uuid';

export function dataUrltoFile(dataUrl: string) {
  const uuid = uuidv4();
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], uuid + '.png', { type: mime });
}