import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentProfile } from '@model/payment-profile';
import { PurchaseToken } from '@model/puchase-token';

export interface TokenPurchaseRequest {
  type?: string;
  tokenPurchaseBatchGUID?: string;
  paymentProfileGUID?: string;
  saveCreditCard?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getTokens() {
    return this.http.get<PurchaseToken[]>('/api/v1/tokenpurchasebatches');
  }

  purchaseTokens(purchaseRequest: TokenPurchaseRequest) {
    return this.http.post<TokenPurchaseRequest>('/api/v1/tokenpurchase', purchaseRequest);
  }

  addPaymentProfile(paymentProfile: PaymentProfile) {
    return this.http.post<PaymentProfile>('/api/v1/userpaymentprofiles', paymentProfile);
  }

  updatePaymentProfile(paymentProfile: PaymentProfile) {
    return this.http.patch<PaymentProfile>(`/api/v1/userpaymentprofiles/${paymentProfile.guid}`, paymentProfile);
  }

  removePaymentProfile(paymentProfile: PaymentProfile) {
    return this.http.delete(`/api/v1/userpaymentprofiles/${paymentProfile.guid}`);
  }
}
