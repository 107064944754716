import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Toast } from './toast';

@Injectable({ providedIn: 'root' })
export class ToastService {
  items$: Observable<Toast[]> | any;
  isOpened = new BehaviorSubject(true);
  item(item: Toast) {
    this.isOpened.next(true);
    const toastItem = [item];
    this.items$ = of(toastItem);
    setTimeout(
      () => {
        this.isOpened.next(false);
        toastItem.splice(0, 1);
      },
      item.duration ? item.duration : 5000
    );
  }

  clear() {
    this.items$ = of(undefined);
  }
}
