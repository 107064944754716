import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@model/user';
import { UserService } from '@service/user.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralsComponent implements OnInit {
  user$: BehaviorSubject<User> = new BehaviorSubject({});

  constructor(public userService: UserService) {}

  ngOnInit(): void {
    this.getCurrentUserData();
  }

  getCurrentUserData(): void {
    this.user$.next(this.userService.user);
  }
}
