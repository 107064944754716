import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environment';
import * as Twilio from 'twilio-video';
import {
  testVideoInputDevice,
  VideoInputTest,
  testAudioInputDevice,
  AudioInputTest,
  testAudioOutputDevice,
  AudioOutputTest
} from '@twilio/rtc-diagnostics';
import { MerchantMeetAndGreetRequest } from '@model/merchant-meet-and-greet-request';
import { VideoMeetings } from '../models/request';
import { UserService } from './user.service';
// import { SnackBar } from '@shared/elements/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  trackKey: string = '';
  room!: Twilio.Room | undefined;
  videoEnable: boolean = true;
  audioEnable: boolean = true;
  hubConnectionState = new BehaviorSubject(false);
  private hubConnection!: signalR.HubConnection;
  currentConnection!: signalR.HubConnection;

  microphones = new BehaviorSubject<MediaDeviceInfo[]>([]);
  cameras = new BehaviorSubject<MediaDeviceInfo[]>([]);
  speakers = new BehaviorSubject<MediaDeviceInfo[]>([]);

  selectedMic: MediaDeviceInfo | undefined = undefined;
  selectedCam: MediaDeviceInfo | undefined = undefined;
  selectedSpeaker: MediaDeviceInfo | undefined = undefined;

  speakerLevel$ = new BehaviorSubject(0);
  micLevel$ = new BehaviorSubject(0);

  isRunningSpeaker = false;
  isRunningMic = false;
  isRunningCamera = false;

  mediaStream: MediaStream | undefined = undefined;

  audioOutputElement: any = undefined;
  audioOutputTrack: any = undefined;

  audioInputElement: any = undefined;
  audioInputTrack: any = undefined;

  cameraOutputTrack: any = undefined;
  cameraOutputElement: any = undefined;

  audioOutputDeviceTest: AudioOutputTest | undefined;
  audioInputDeviceTest: AudioInputTest | undefined;

  twilio$ = new BehaviorSubject<any>({});
  constructor(private userService: UserService) {} // private snackBar: SnackBar,
  connect(meeting: VideoMeetings, videoMeetingExternalMeetingID: string) {
    this.getDevice();
    // console.log('connect', this, this.selectedCam, this.selectedSpeaker, this.selectedMic);
    const options = {
      audio: { deviceId: this.selectedMic?.deviceId },
      video: { deviceId: this.selectedCam?.deviceId },
      name: meeting.token || ''
    };
    const twilio = Twilio.connect(meeting.token || '', options);
    this.twilio$.next(twilio);
    return twilio;
  }

  audioControl(room: Twilio.Room, isEnable: boolean) {
    room.localParticipant.audioTracks.forEach((publication: Twilio.LocalAudioTrackPublication) => {
      if (isEnable) {
        publication.track.enable();
      }
      if (!isEnable) {
        publication.track.disable();
      }
    });
  }
  videoControl(room: Twilio.Room, isEnable: boolean) {
    room.localParticipant.videoTracks.forEach((publication: Twilio.LocalVideoTrackPublication) => {
      // console.log(publication, isEnable);
      if (isEnable) {
        this.addLocalVideo();
        publication.track.enable();
      }
      if (!isEnable) {
        publication.track.disable();
      }
    });
  }

  previewVideo() {
    // console.log('preview video');
    this.createNewLocalVideo();
    this.createNewLocalAudioIn();
  }

  hidePreview() {
    if (this.room) {
      this.room.localParticipant.audioTracks.forEach((audio: Twilio.LocalAudioTrackPublication) => {
        audio.track.disable();
        audio.track.stop();
        audio.unpublish();
      });
      this.room.localParticipant.videoTracks.forEach((video: Twilio.LocalVideoTrackPublication) => {
        video.track.disable();
        video.track.stop();
        video.unpublish();
      });
      const video = document.getElementById('local')?.lastChild;
      if (video) {
        document.getElementById('local')!.innerHTML = '';
      }
    }
  }

  participantDisconnected(participant: Twilio.Participant) {
    document.getElementById(`${participant.sid}`)?.remove();
  }

  trackSubscribed(div: any, track: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) {
    div.appendChild(track.attach());
  }

  trackUnsubscribed(track: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) {
    // console.log('unsubscribe', track);
    track.detach().forEach((element: any) => element.remove());
  }

  addLocalVideo() {
    if (this.room) {
      let hasLocal = false;
      const videoTracks = this.room.localParticipant.videoTracks;
      // console.log('viddeo tracks', this.room.localParticipant.videoTracks, videoTracks.values());
      videoTracks.forEach((track: Twilio.LocalVideoTrackPublication, key) => {
        // console.log('keys', this.trackKey, key);
        if (this.trackKey !== key) {
          this.trackKey = key;
          setTimeout(() => {
            const local = window.document.getElementById('local-video');
            if (!hasLocal) {
              local?.replaceWith(track.track.attach());
              hasLocal = true;
            }
            if (track.kind === 'video' && this.selectedCam !== undefined) {
              this.cameraOutputTrack = track;
              this.cameraOutputElement = local;
            }
          }, 50);
        }
      });
    }
  }

  // createNewLocalVideo() {
  //   // console.log('create local video');
  //   Twilio.createLocalVideoTrack({ deviceId: this.selectedCam?.deviceId }).then((track: Twilio.LocalVideoTrack) => {
  //     // console.log('video track', track);
  //     const localMediaContainer = document.getElementById('local');
  //     localMediaContainer?.appendChild(track.attach());
  //   });
  // }

  createNewLocalVideo() {
    Twilio.createLocalVideoTrack({ deviceId: this.selectedCam?.deviceId }).then((track: Twilio.LocalVideoTrack) => {
      const localMediaContainer = document.getElementById('local');
      const children = localMediaContainer?.children as HTMLCollection;
      if (children) {
        for (let i = 0; i < children.length; i++) {
          if (children.item(i)?.nodeName === 'VIDEO') {
            children.item(i)?.remove();
          }
        }

        if (track.kind === 'video' && this.selectedCam !== undefined) {
          this.cameraOutputTrack = track;
          this.cameraOutputElement = localMediaContainer;

          if (this.room) {
            this.room.localParticipant.videoTracks.forEach((publication) => {
              publication.unpublish();
            });
            track.enable();
            if (this.room.localParticipant.videoTracks.size === 0) {
              this.room.localParticipant.publishTrack(track);
            }
          }
        }
      }
      localMediaContainer?.appendChild(track.attach());
    });
  }

  createNewLocalAudioIn() {
    Twilio.createLocalAudioTrack({ deviceId: this.selectedMic?.deviceId })
      .then((audioTrack: Twilio.LocalAudioTrack) => {
        // console.log('audio', this.room.localParticipant.tracks);
        if (this.room !== undefined) this.room.localParticipant.publishTrack(audioTrack);
      })
      .catch((err: any) => console.log(err));
  }

  initializeConnection(): void {
    const userAuth = JSON.parse(localStorage.getItem('xgenflix.user')!);
    const token = userAuth.token;
    if (token) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalRUrl, { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Error)
        .build();

      this.hubConnection.on('MerchantMeetAndGreetRequestCallAccepted', (next) => {
        const data = JSON.parse(next);
        // alert(`${data.data.userDisplayName} accepted the call.`);
        // this.snackBar.open(`${data.data.userDisplayName} accepted the call.`, 5000);
      });

      this.hubConnection.on('MerchantMeetAndGreetRequestCallRejected', (next) => {
        const data = JSON.parse(next);
        // alert(`${data.data.userDisplayName} rejected the call.`);
        // this.snackBar.open(`${data.data.userDisplayName} rejected the call.`, 5000, 'danger');
      });

      if (this.hubConnectionState.getValue() === false) {
        this.hubConnection
          .start()
          .then(() => {
            this.currentConnection = this.hubConnection;
            this.hubConnectionState.next(true);
          })
          .catch(() => {
            console.error('Error while starting connection. Retrying...');
            setTimeout(() => {
              this.initializeConnection();
            }, 3000);
          });
      }
    }
  }

  disconnect() {
    if (this.room) {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
        // console.log(stream);
        /* use the stream */
        stream.getTracks().forEach((track) => {
          this.room?.localParticipant.unpublishTrack(track);
          track.stop();
        });
      });

      this.room.participants.clear();
      // console.log(this.room.localParticipant.tracks);

      this.room.localParticipant.tracks.forEach((publication) => {
        publication.track.removeAllListeners();
        publication.unpublish();
        publication.isEnabled = false;
      });

      // this.room.localParticipant.tracks.clear();
      this.room.disconnect();
      // console.log(this.room.state);
      this.room = undefined;
    }

    // setTimeout(() => {
    //   location.reload();
    // }, 2000);
  }

  switchAudioIn() {
    if (this.selectedMic !== undefined) {
      if (this.room)
        this.room.localParticipant.audioTracks.forEach((audio: Twilio.LocalAudioTrackPublication) => {
          audio.track.disable();
          audio.track.stop();
          audio.unpublish();
        });
      this.createNewLocalAudioIn();
    }
  }

  switchAudioOut() {
    if (this.audioOutputTrack.kind === 'audioinput' && this.selectedSpeaker !== undefined) {
      // console.log(this.audioOutputTrack);
      // console.log('remove ', this.audioOutputElement);
      this.audioOutputElement.remove();
      this.audioOutputElement = this.audioOutputTrack.attach() as any;
      // console.log('new device', this.selectedSpeaker);
      this.audioOutputElement.setSinkId(this.selectedSpeaker?.deviceId).then(() => {
        document.body.appendChild(this.audioOutputElement);
      });
    }
  }

  videoTest(element: HTMLVideoElement) {
    const videoInputDeviceTest = testVideoInputDevice({ element });
    videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
      console.error(error);
    });

    videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
      console.log(report);
    });

    setTimeout(() => {
      videoInputDeviceTest.stop();
    }, 10000);
  }

  audioInTest(device: MediaDeviceInfo) {
    // console.log(device);
    this.isRunningMic = true;

    this.audioInputDeviceTest = testAudioInputDevice({
      deviceId: device.deviceId
    });
    try {
      this.audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
        // console.log('volume', volume);
        this.micLevel$.next(volume);
      });

      this.audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
        console.error('error', error);
      });

      this.audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
        console.log('report', report);
      });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      this.audioInputDeviceTest!.stop();
      this.micLevel$.next(0);
      this.isRunningMic = false;
    }, 6000);
  }

  audioOutTest(device: MediaDeviceInfo) {
    // console.log(device);
    this.isRunningSpeaker = true;

    this.audioOutputDeviceTest = testAudioOutputDevice({
      deviceId: device.deviceId
    });

    try {
      this.audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
        this.speakerLevel$.next(volume);
        // console.log('volume', volume);
      });

      this.audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
        console.error(error);
      });

      this.audioOutputDeviceTest.on(AudioOutputTest.Events.End, (report) => {
        this.speakerLevel$.next(0);
        // console.log('report', report);
      });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      this.stopAudioOutput();
    }, 6000);
  }

  getDevice() {
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.microphones.next(devices.filter((d) => d.kind === 'audioinput'));
      this.cameras.next(devices.filter((d) => d.kind === 'videoinput'));
      this.speakers.next(devices.filter((d) => d.kind === 'audiooutput'));

      if (this.selectedCam === undefined) {
        this.selectedCam = devices.find((d) => d.kind === 'videoinput' && d.deviceId === 'default');
      }
      return devices;
    });
  }

  stopAudioOutput() {
    this.audioOutputDeviceTest!.stop();
    this.isRunningMic = false;
    this.speakerLevel$.next(0);
  }

  stopAudioInput() {
    this.audioInputDeviceTest!.stop();
    this.isRunningMic = false;
    this.micLevel$.next(0);
  }
}
