import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environment';
import { UserService } from '@service/user.service';
import { MerchantService } from '@service/merchant.service';
import { SubscriptionService } from '@service/subscription.service';
import { User } from '@model/user';
import { Merchant } from '@model/merchant';
import { SubscriptionPlan, Subscription } from '@model/subscription';

@Component({
  selector: 'app-all-access',
  templateUrl: './all-access.component.html',
  styleUrls: ['./all-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllAccessComponent implements OnInit {
  user$ = new BehaviorSubject<User | undefined>(undefined);
  merchant$ = new BehaviorSubject<Merchant | undefined>(undefined);
  subscriptionPlans$ = new BehaviorSubject<SubscriptionPlan[] | undefined>(undefined);

  currentSubscription?: Subscription;
  isSubscribeModalOpen = false;
  isUnsubscribeModalOpen = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private merchantService: MerchantService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getMerchant();
    this.getSubscriptionPlans();
    this.getUserSubscriptionToXStreamr();
  }

  getUser(): void {
    this.userService.user$.subscribe((user) => {
      this.user$.next(user);
    });
  }

  getMerchant(): void {
    this.merchantService.getMerchant(environment.mainMerchantGuid).subscribe((merchant) => {
      this.merchant$.next(merchant);
    });
  }

  getSubscriptionPlans(): void {
    this.subscriptionService.getSubscriptionByMerchant(environment.mainMerchantGuid).subscribe((subscriptionPlans) => {
      this.subscriptionPlans$.next(subscriptionPlans);
    });
  }

  getUserSubscriptionToXStreamr(): void {
    this.userService.getCurrentUserSubscriptions().subscribe((next) => {
      this.currentSubscription = next.find(
        (x) => x.merchantGUID === environment.mainMerchantGuid && x.status === 'Active'
      );

      this.userService.isSubscribedToXStreamr.next(!!this.currentSubscription);
    });
  }

  toggleSubscriptionModal(value: boolean): void {
    this.isSubscribeModalOpen = value;
  }

  toggleUnsubscriptionModal(value: boolean): void {
    this.isUnsubscribeModalOpen = value;
  }

  subscriptionSucess(event: Subscription): void {
    this.currentSubscription = event;
    this.userService.isSubscribedToXStreamr.next(true);
    this.router.navigate(['/home']);
  }

  unsubscribe(): void {
    this.subscriptionService.unsubscribe(this.currentSubscription?.subscriptionPlanGUID!).subscribe(() => {
      console.log('here');
    });
    this.currentSubscription = undefined;
    this.userService.isSubscribedToXStreamr.next(false);
    this.toggleUnsubscriptionModal(false);
  }
}
