import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environment';
// import { MeetAndGreetRequest } from '@model/request';
import { User, UserAuth } from '@model/user';
import { UserService } from '@service/user.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { MeetAndGreetNotificationService } from './meet-and-greet-nofitication.service';
import { MerchantMeetAndGreetRequest } from '@model/merchant-meet-and-greet-request';
import { TwilioGenerateTokenResponse } from '@model/twilio';
import { VideoMeetings } from '../models/request';

@Injectable({
  providedIn: 'root'
})
export class MerchantMeetAndGreetRequestService {
  // private hubConnection: signalR.HubConnection;
  private apiName = 'merchantmeetandgreetrequests';
  isVideoCallActive = new BehaviorSubject(false);
  videoMeetingToken = new BehaviorSubject('');
  data = new BehaviorSubject<any>(undefined);

  generatedToken = new BehaviorSubject<string | undefined>(undefined);
  action = new BehaviorSubject<string | undefined>(undefined);
  isOpened = new BehaviorSubject(false);
  showRating = new BehaviorSubject(false);

  merchantVideoMeetAndGreetRequests$ = new BehaviorSubject<MerchantMeetAndGreetRequest[]>([]);
  fanVideoMeetAndGreetRequests$ = new BehaviorSubject<MerchantMeetAndGreetRequest[]>([]);

  constructor(
    private http: HttpClient,
    private meetAndGreetNotificationService: MeetAndGreetNotificationService,
    private userService: UserService,
    private router: Router
  ) {}

  endCall(showRating = false, user?: User) {
    const data = this.data.value;
    const merchantRequests = this.merchantVideoMeetAndGreetRequests$.value;

    const merchantRequestId = merchantRequests.findIndex((f) => f.guid === data.guid);
    // console.log(data);
    // console.log('merchantRequests', merchantRequests, merchantRequestId);

    if (merchantRequestId !== -1 && merchantRequestId !== undefined && merchantRequests.length) {
      merchantRequests[merchantRequestId].status = 'Completed';
      this.merchantVideoMeetAndGreetRequests$.next(merchantRequests);
      // console.log('update', merchantRequests);
    }

    const fanRequests = this.fanVideoMeetAndGreetRequests$.value;

    const fanRequestId = merchantRequests.findIndex((f) => f.guid === data.guid);
    // console.log('fanRequests', fanRequests, fanRequestId);

    if (fanRequestId !== -1 && fanRequestId !== undefined && fanRequests.length) {
      fanRequests[fanRequestId].status = 'Completed';
      this.fanVideoMeetAndGreetRequests$.next(fanRequests);
      // console.log('update', fanRequests);
    }
    if (user && user.guid && user.guid === data.merchantUserGUID) {
      // console.log('Completed');
      data.status = 'Completed';
      const index = fanRequests.findIndex((r) => r.guid === data.guid);
      console.log(index);
      if (index !== undefined || index !== -1) {
        console.log(fanRequests[index]);
        fanRequests[index] = data;
        this.fanVideoMeetAndGreetRequests$.next(fanRequests);
      }
      this.showRating.next(false);
      this.isOpened.next(false);
    } else {
      this.showRating.next(showRating);
    }
    this.generatedToken.next(undefined);
    this.isVideoCallActive.next(false);
    this.videoMeetingToken.next('');
    if (!showRating) {
      // console.log('else');
      this.data.next(undefined);
      this.isOpened.next(false);
    }
  }

  callResponseMeetAndGreet(guid: string, action: string) {
    return this.http.post(`/api/v1/merchantmeetandgreetrequests/${guid}/videomeeting/${action}`, {
      guid: guid
    });
  }

  searchMeetAndGreet(query: { [p: string]: any }): Observable<object[]> {
    return this.http.get<MerchantMeetAndGreetRequest[]>(`/api/v1/${this.apiName}/search`, {
      params: query
    });
  }

  postMeetAndGreetRequest(request: MerchantMeetAndGreetRequest): Observable<object> {
    return this.http.post('/api/v1/merchantmeetandgreetrequests', request);
  }

  getMeetAndGreetByGuid(guid: string): Observable<MerchantMeetAndGreetRequest> {
    return this.http.get(`/api/v1/merchantmeetandgreetrequests/${guid}`);
  }

  generateVideoMeetingToken(guid: string): Promise<VideoMeetings> {
    return this.http.post(`/api/v1/videomeetings/${guid}/token`, {}).toPromise();
  }

  updateMeetAndGreetStatus(guid: string, status: string) {
    return this.http.post(`/api/v1/merchantmeetandgreetrequests/${guid}/${status}`, null);
  }

  getVideoCallRequest(params: any, selectedOption: number) {
    this.searchMeetAndGreet(params)
      .toPromise()
      .then((results) => {
        if (selectedOption === 1) {
          this.merchantVideoMeetAndGreetRequests$.next(results);
        } else {
          this.fanVideoMeetAndGreetRequests$.next(results);
        }

        return results;
      });
  }

  completeMerchantMeetAndGreetRequest(guid: string) {
    return this.http.post(`/api/v1/merchantmeetandgreetrequests/${guid}/complete`, {});
  }

  startMerchantMeetAndGreetRequest(guid: string) {
    return this.http.post<MerchantMeetAndGreetRequest>(`/api/v1/merchantmeetandgreetrequests/${guid}/start`, {});
  }
  updateMeetAndGreetRequest(data: MerchantMeetAndGreetRequest) {
    return this.http.patch(`/api/v1/merchantmeetandgreetrequests/${data.guid}`, data);
  }
}
