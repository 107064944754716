<div class="outer">
  <div class="kahon">
    <div class="is-size-2 has-text-weight-bold mb-4">Reset Password</div>
    <div>
      <form [formGroup]="passwordResetForm" (ngSubmit)="reset()">
        <div class="field">
          <label class="label" for="password">Password</label>
          <div class="control">
            <input class="input" type="password" id="password" formControlName="password" />
          </div>
        </div>
        <div class="field">
          <label class="label" for="confirmPassword">Confirm Password</label>
          <div class="control">
            <input class="input" type="password" id="confirmPassword" formControlName="confirmPassword" />
          </div>
        </div>
        <button class="button is-primary full-width">Confirm Reset</button>

        <div class="message-space">
          <div
            *ngIf="passwordResetForm.controls['password'].touched && passwordResetForm.hasError('passwordFormat')"
            class="has-text-danger"
          >
            Use 8 or more characters with a mix of numbers and upper and lower case letters.
          </div>

          <div
            *ngIf="passwordResetForm.controls['confirmPassword'].touched && passwordResetForm.hasError('notSame')"
            class="has-text-danger"
          >
            Passwords do not match.
          </div>

          <div *ngIf="(isSuccess$ | async) === 1">
            Password reset successful. You will be automatically redirected to the login page in a few seconds.
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
