import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { ColDef, DomLayoutType, GridApi, GridReadyEvent, PaginationChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent<T> implements OnInit, OnChanges {
  @Input() context: any;
  @Input() rows: Array<T> = [];
  @Input() columns: ColDef<T, unknown>[] = [];
  @Input() emptyMessage?: string;

  gridApi: GridApi<T> | undefined = undefined;

  themeClass = 'ag-theme-alpine';
  headerHeight = 45;
  domLayout: DomLayoutType = 'autoHeight';
  totalPages = 1;
  currentPage = 1;
  rowData: Array<T> = [];
  noRowsTemplate = '<span>No Rows To Show</span>';

  ngOnInit(): void {
    if (this.emptyMessage) {
      this.noRowsTemplate = `<span>${this.emptyMessage}</span>`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rows) {
      // get user's balance on open
      console.log('row change');
      console.log(this.rows);
      if (this.rows) {
        this.rowData = this.rows;
      }
    }
  }

  onGridReady(params: GridReadyEvent<T>) {
    this.gridApi = params.api;
    this.rowData = this.rows;
  }

  onPaginationChanged(event: PaginationChangedEvent<T>) {
    if (this.gridApi) {
      console.log(this.gridApi.paginationGetTotalPages());
      this.totalPages = this.gridApi.paginationGetTotalPages();
      this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
    }
  }

  paginate(type: string) {
    switch (type) {
      case 'left':
        this.gridApi?.paginationGoToPreviousPage();
        break;
      case 'right':
        this.gridApi?.paginationGoToNextPage();
    }
  }
}
