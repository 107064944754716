<div class="columns">
  <div class="column is-half">
    <form [formGroup]="changePassForm" (ngSubmit)="submit()">
      <div class="field">
        <label class="label">Current Password</label>
        <div class="control">
          <input class="input" formControlName="oldPassword" type="password" />
        </div>
      </div>

      <div class="field">
        <label class="label">New Password</label>
        <div class="control">
          <input class="input" formControlName="newPassword" type="password" />
        </div>
      </div>

      <div class="field">
        <label class="label">Confirm New Password</label>
        <div class="control">
          <input class="input" formControlName="confirmPassword" type="password" />
        </div>
      </div>

      <button [disabled]="changePassForm.invalid || (isFormSubmitted | async)" type="button" class="button is-primary" (click)="submit()">
        Update Password
      </button>
    </form>
  </div>
</div>
