import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';

import { ReferralLevelOne } from '@model/referral-level1';
import { User } from '@model/user';
import { UserService } from '@service/user.service';

@Component({
  selector: 'app-referral-signups',
  templateUrl: './referral-signups.component.html',
  styleUrls: ['./referral-signups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralSignupsComponent implements OnInit {
  totalUserCount: BehaviorSubject<number | any>;
  tier1UserCount: BehaviorSubject<number | any>;
  tier2UserCount: BehaviorSubject<number | any>;

  orderAsc = false;

  tier1UsersDisplay$ = new BehaviorSubject<ReferralLevelOne[] | undefined>(undefined);
  tier1Users: ReferralLevelOne[];

  selectedUser: ReferralLevelOne | null;

  searchControl: FormControl;

  user = this.userService.user;
  
  topN = 10;
  offSetN = 0;
  currentPage = 1;
  totalTransactionsCount = 0;

  constructor(private userService: UserService) {
    this.totalUserCount = new BehaviorSubject(0);
    this.tier1UserCount = new BehaviorSubject(0);
    this.tier2UserCount = new BehaviorSubject(0);

    this.tier1Users = [];

    this.selectedUser = null;
    this.searchControl = new FormControl();
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((next: any) => {
      this.searchUser(next);
    });

    this.getReferralUsageStats();
    this.getTier1Users();
  }

  getReferralUsageStats(): void {
    this.userService.getReferralCodeUsage().subscribe((next: any) => {
      this.tier1UserCount.next(next.tier1UserCount);
      this.tier2UserCount.next(next.tier2UserCount);

      this.totalUserCount.next(next.tier1UserCount + next.tier2UserCount);
      this.totalTransactionsCount = Math.ceil((next.tier1UserCount + next.tier2UserCount) / 10);
    });
  }

  getTier1Users(type?: string): void {
    const params = {
      referrerGUID: this.user.guid,
      offsetN: this.offSetN,
      topN: this.topN
    };

    this.userService.searchUser(params as any).subscribe((next: User[]) => {
      const referralUsers: ReferralLevelOne[] = [];

      if (next.length > 0) {
        next.forEach((user: User) => {
          referralUsers.push({
            username: user.username,
            date: user.dateCreated,
            profileImage: user.normalizedProfileImageUrl,
            referralCount: user.referralCount,
            amount: 0
          });
        });
  
        this.tier1UsersDisplay$.next(referralUsers);
        this.tier1Users = referralUsers;
      } else {
        if (type === 'right') {
          this.offSetN -= 10;
          this.currentPage -= 1;
          this.getTier1Users();
        }
      }
    });
  }

  searchUser(searchTerm: any): void {
    const res: ReferralLevelOne[] = [];

    this.tier1Users.forEach((next: ReferralLevelOne) => {
      if (next.username?.toUpperCase().includes(searchTerm.toUpperCase())) {
        res.push(next);
      }
    });

    this.tier1UsersDisplay$.next(res);
  }

  sortUsername(): void {
    this.orderAsc = !this.orderAsc;

    const sorted = orderBy(this.tier1Users, ['username'], this.orderAsc ? ['asc'] : ['desc']);
    this.tier1UsersDisplay$.next(sorted);
  }

  navigate(type: string): void {
    switch (type) {
      case 'left':
        this.offSetN -= 10;
        this.currentPage -= 1;
        break;
      case 'right':
        this.offSetN += 10;
        this.currentPage += 1;
        break;
    }
    if (this.offSetN < 0) {
      this.offSetN = 0;
      this.currentPage = 1;
    }
    this.getTier1Users(type);
  }
}
