import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@model/user';
import { NotificationService } from '@service/notification.service';
import { UserService } from '@service/user.service';
import { WebSocketService } from '@service/web-socket.service';
import { ToastService } from 'app/shared/components/toast/toast.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  user$ = new BehaviorSubject<User | undefined>(undefined);
  selectedOption = 1;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private webSocketService: WebSocketService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getCurrentUserData();

    this.activatedRoute.queryParams.subscribe((query) => {
      // console.log(query);
      if (query.ref) {
        switch (query.ref) {
          case 'wallet':
            this.selectedOption = 3;
            break;
          case 'ticket':
            this.selectedOption = 8;
            break;
        }
      }
    });
  }

  getCurrentUserData(): void {
    let isGettingPaymentInfo = false;

    this.userService.user$.subscribe((next) => {
      if (next && next.guid && !isGettingPaymentInfo) {
        isGettingPaymentInfo = true;
        this.user$.next(next);
        this.getUserPaymentInfo();
      }
    });
  }

  getUserPaymentInfo(): void {
    this.userService.getUserPaymentInfo(this.user$.getValue()?.guid || '').subscribe((res) => {
      this.userService.paymentProfiles = res;
    });
  }

  signOut(): void {
    this.toastService.clear();
    localStorage.removeItem('xgenflix.user');
    this.userService.user = null;
    this.userService.user$.next(undefined);
    this.user$.next(undefined);
    this.webSocketService.disconnect();
    this.webSocketService.hubConnection = undefined;
    this.notificationService.notificationSubscription.unsubscribe();
    this.notificationService.notifications$.next(undefined);
    this.resetLandingHandler();
    window.location.href = '';
  }

  private resetLandingHandler(): void {
    const i = this.router.config.findIndex((x) => x.path === '');
    this.router.config.splice(i, 1);
    this.router.config.push({
      path: '',
      loadChildren: () => import('../../shared/modules/landing-handler.module').then((m) => m.LandingHandlerModule)
    });
  }
}
