import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { UserService } from '@service/user.service';
import { UserAuth } from '@model/user';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  requestUrl: string;

  constructor(private userService: UserService, private router: Router) {
    this.requestUrl = environment.baseApiUrl;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user: UserAuth = JSON.parse(localStorage.getItem('xgenflix.user') || '{}');

    let headers = new HttpHeaders();

    if (!req.url.includes('http') && user != null) {
      headers = headers.set('Authorization', 'Bearer ' + user.token);
    }

    req = req.clone({
      headers,
      url: (req.url.includes('http') ? '' : this.requestUrl) + req.url
    });

    return next.handle(req).pipe(
      catchError((error) => {
        console.log('why redirect');
        if (error.status === 401) {
          localStorage.removeItem('xgenflix.user');
          this.router.navigate(['']);
        }
        return throwError(error);
      })
    );
  }
}
