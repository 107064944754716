<ng-container *ngIf="subscriptions$ | async as subscriptions; else loading">
  <app-data-table
    [rows]="subscriptions"
    [columns]="colDefs"
    emptyMessage="You currently have no other subscriptions."
  ></app-data-table>
</ng-container>

<ng-template #loading>
  <div class="p-6 has-text-centered">Loading subscriptions...</div>
</ng-template>

<app-modal
  *ngIf="isUnsubscribeModalOpen"
  [showModal]="isUnsubscribeModalOpen"
  (modalStatus)="toggleUnsubscriptionModal($event)"
>
  <div class="p-5">
    <div class="is-size-4 has-text-weight-semibold">
      <div>Are you sure you want to unsubscribe from</div>
      <div>{{ subscription?.merchantUserDisplayName }}?</div>
    </div>
    <div class="mt-4">
      <button class="button is-primary" (click)="unsubscribe()">Continue unsubscribing</button>
      <button class="button is-outlined ml-4" (click)="toggleUnsubscriptionModal(false)">Cancel</button>
    </div>
  </div>
</app-modal>
