import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { LocalStorageKey } from '@const/local-storage-key';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle(environment.externalName);
  }

  @HostListener('window:unload', ['$event'])
  clearLocalStorage() {
    Object.values(LocalStorageKey).forEach(key => {
      localStorage.removeItem(key);
    });
  }
}
