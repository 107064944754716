import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	SimpleChanges
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ColDef, DomLayoutType, GridApi, GridReadyEvent, ICellRendererParams, PaginationChangedEvent } from 'ag-grid-community';

@Component({
	selector: 'app-merchant-renderer',
	templateUrl: './merchant-renderer.component.html',
	styleUrls: ['./merchant-renderer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MerchantRendererComponent implements ICellRendererAngularComp {

  // Init Cell Value
  displayName!: string;
  userName!: string;
  userProfileImageUrl!: string;

  agInit(params: ICellRendererParams): void {
    this.displayName = params.data.merchantName;
    this.userName = params.data.merchantUsername;
    this.userProfileImageUrl = params.data.merchantUserNormalizedProfileImageUrl
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.displayName = params.data.merchantName;
    this.userName = params.data.merchantUsername;
    this.userProfileImageUrl = params.data.merchantUserNormalizedProfileImageUrl
    return true;
  }
}
