import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Merchant } from '@model/merchant';
import { UserService } from '@service/user.service';
import { User } from '@model/user';
import { Subscription, SubscriptionPlan } from '@model/subscription';
import { SubscriptionService } from '@service/subscription.service';
import { PaymentProfile } from '@model/payment';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionModalComponent implements OnInit {
  @Input() merchantInfo$: BehaviorSubject<Merchant | undefined> = new BehaviorSubject<Merchant | undefined>({});
  @Input() userInfo$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>({});
  @Input() subscriptionPlans$: BehaviorSubject<SubscriptionPlan[] | undefined> = new BehaviorSubject<
    SubscriptionPlan[] | undefined
  >([]);
  @Input() showModal = false;
  @Output() modalStatus = new EventEmitter<boolean>();
  @Output() subscriptionSuccess = new EventEmitter<Subscription>();

  payment$ = new BehaviorSubject(0);

  selectedPlan?: SubscriptionPlan;
  creditOption?: string;

  paymentProfiles$ = this.userService.paymentProfiles$;

  subscriptionData?: Subscription;

  constructor(private userService: UserService, private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.paymentProfiles$.subscribe((next) => {
      const defaultPayment = next?.find((x) => x.isDefault);
      if (defaultPayment) {
        this.creditOption = defaultPayment.guid;
      }
    });
  }

  toggleSubscriptionModal(event?: any): void {
    if (event === false) {
      this.modalStatus.emit(false);

      if (this.subscriptionData) {
        this.subscriptionSuccess.emit(this.subscriptionData);
      }
    }
  }

  checkPaymentProfiles(selectedPlan: SubscriptionPlan, updatePayment = true): void {
    this.selectedPlan = selectedPlan;
    if (updatePayment) {
      this.payment$.next(4);
    }

    this.userService.getUserPaymentInfoObservable(this.userInfo$.value?.guid!);
  }

  confirmSubscription(): void {
    this.payment$.next(1);

    const payment: PaymentProfile = {
      paymentProfileGUID: this.creditOption,
      amount: this.selectedPlan?.amount,
      tokenProfileKey: 'USD'
    };

    this.subscriptionService.subscribe(this.selectedPlan?.guid!, payment).subscribe(
      (next) => {
        this.payment$.next(2);
        this.subscriptionData = next;
      },
      (error) => {
        if (error.error.message === 'Insufficient balance for transfer') {
          this.payment$.next(3);
        } else {
          this.payment$.next(5);
        }
      }
    );
  }

  resetPurchaseCheck(): void {
    this.payment$.next(0);
  }

  closeModal(): void {
    this.modalStatus.emit(false);
  }

  subscriptionSuccessHome(): void {
    this.subscriptionSuccess.emit(this.subscriptionData);
    this.modalStatus.emit(false);
  }
}
