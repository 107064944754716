<div *ngIf="paymentProfiles$ | async as paymentProfiles">
  <div class="current-balance has-text-centered my-4">
    Current FanBucks Balance:
    <div class="has-text-weight-bold is-size-4">{{ (kudoBalance$ | async) || 0 | number }}</div>
  </div>
  <div *ngIf="paymentProfiles.length > 0; else noneAvailable">
    <div class="mt-3 font-weight-bold">Saved card(s):</div>

    <div class="columns credit-card is-mobile is-overflow-hidden is-flex-wrap-wrap my-3">
      <ng-container *ngFor="let p of paymentProfiles">
        <div class="flip-card column is-3 my-2 mr-4" [class.rotate]="p == cardSelected" (click)="selectCard(p)">
          <div class="flip-card-inner full-height" [class.default]="p.isDefault">
            <div class="poster-wrapper flip-card-front p-2">
              <div class="poster mb-2">
                <img *ngIf="p.creditCardType === 'Visa'" src="../../../../../assets/images/visa.png" />

                <img *ngIf="p.creditCardType === 'Mastercard'" src="../../../../../assets/images/mastercard.png" />

                <img *ngIf="p.creditCardType === 'AmericanExpress'" src="../../../../../assets/images/amex.png" />

                <img *ngIf="p.creditCardType === 'Discover'" src="../../../../../assets/images/discover.svg" />

                <img *ngIf="p.creditCardType === 'JCB'" src="../../../../../assets/images/jcb.svg" />

                <img *ngIf="p.creditCardType === 'DinersClub'" src="../../../../../assets/images/diners.png" />
              </div>

              <div class="mt-5 card-number">
                <span>•••• •••• •••• {{ p.maskedNumber?.substring(4) }}</span>
              </div>
            </div>
            <div class="flip-card-back p-2">
              <ul class="is-flex is-size-7">
                <li [class.disabled]="p.isDefault" (click)="setAsDefaultCard(p)">Set as default</li>
                <li class="mr-1 ml-1" (click)="updateCard(p)">Edit details</li>
                <li class="remove" (click)="removeCard(p)">Remove</li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template #noneAvailable>
    <div class="p-6 has-text-centered">There are no saved credit cards on file.</div>
  </ng-template>

  <button
    class="button is-primary"
    [class.is-hidden]="addNewPaymentDetails$ | async"
    (click)="addNewPaymentDetails$.next(true)"
  >
    Add New Card
  </button>

  <div [class.is-hidden]="!(addNewPaymentDetails$ | async)">
    <div class="hr-bar mt-3 mb-3"></div>
    <p *ngIf="paymentProfiles.length == 0">Please enter your credit card information.</p>
    <div class="column" *ngIf="paymentProfiles.length > 0"></div>
    <form [formGroup]="creditForm" (ngSubmit)="savePaymentInfo()" class="column control">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="field">
            <label class="label">First Name</label>
            <div class="control">
              <input class="input" formControlName="firstName" type="text" />
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Last Name</label>
            <div class="control">
              <input class="input" formControlName="lastName" type="text" />
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label">Card Number</label>
            <div class="control">
              <input class="input" formControlName="creditCardNumber" #cardNumberRef />
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Expiration Date</label>
            <div class="control">
              <input class="input" formControlName="expirationDate" maxlength="7" placeholder="MM/YY" #cardExpiryRef />
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">CVV</label>
            <div class="control">
              <input class="input" formControlName="cardCode" #cardCvvRef />
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <input class="input" formControlName="address" type="text" />
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">State</label>
            <div class="select">
              <select formControlName="state">
                <option *ngFor="let state of statesList" [value]="state.key">
                  {{ state.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input class="input" formControlName="city" type="text" />
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Zipcode</label>
            <div class="control">
              <input class="input" formControlName="zipCode" type="text" />
            </div>
          </div>
        </div>
        <div class="column is-full">
          <label class="checkbox">
            <input type="checkbox" formControlName="isDefault" />
            Use this card for future purchases
          </label>
        </div>
      </div>

      <button [disabled]="creditForm.invalid" type="button" class="button is-primary mr-2" (click)="savePaymentInfo()">
        Save Credit Card
      </button>

      <button type="button" class="button is-ghost" (click)="resetFlags(true)">Cancel</button>
    </form>
  </div>
</div>
