import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '@service/user.service';
import { ChartOptions, ChartType } from 'chart.js';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-referral-dashboard',
  templateUrl: './referral-dashboard.component.html',
  styleUrls: ['./referral-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralDashboardComponent implements OnInit {
  totalUserCount$ = new BehaviorSubject(0);
  chart$ = new BehaviorSubject({
    pieChartOptions: {
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          fontColor: 'white'
        },
        onClick(event: MouseEvent): void {
          event.stopPropagation();
        }
      }
    } as ChartOptions,
    pieChartLabels: ['Level 1', 'Level 2'],
    pieChartData: [0, 0],
    pieChartType: 'pie' as ChartType,
    pieChartColors: [
      {
        backgroundColor: ['#49bf5d', '#157325']
      }
    ]
  });

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getReferralUsageStats();
  }

  getReferralUsageStats(): void {
    this.userService.getReferralCodeUsage().subscribe((next: any) => {
      this.totalUserCount$.next(next.tier1UserCount + next.tier2UserCount);

      const chart = this.chart$.getValue();
      chart.pieChartData = [next.tier1UserCount, next.tier2UserCount];
      this.chart$.next(chart);
    });
  }
}
