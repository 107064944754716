<div class="content">
  <div *ngIf="toast.hasCloseButton">
    <button *ngIf="toast.hasCloseButton" class="pop-up-close is-small" (click)="dismissToast()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div
    class="title"
    *ngIf="toast.title"
    [class.call-title]="toast.data && toast.data.messageType === 'MerchantMeetAndGreetRequestCallStarted'"
  >
    {{ toast.title }}
  </div>

  <div
    [ngClass]="toast.type != null ? 'has-text-' + toast.type : ''"
    class="description {{
      toast.data && toast.data.messageType === 'MerchantMeetAndGreetRequestCallStarted' ? 'call-description' : ''
    }}"
    *ngIf="toast.description"
  >
    {{ toast.description }}
  </div>
  <ng-container *ngIf="toast.data && toast.data.messageType === 'MerchantMeetAndGreetRequestCallStarted'">
    <div class="flexbox mt-4">
      <div class="call-action accept mr-2" (click)="callAction('accept', toast.data.data)">
        <!-- [routerLink]="['/video-call', toast.data.data.guid, '/accept']" -->
        <i class="fa fa-check mr-2"></i> Accept call
      </div>
      <div class="call-action reject" (click)="callAction('reject', toast.data.data)">
        <i class="fa fa-times mr-2"></i> Reject call
      </div>
      <!-- <pre style="max-width: 100%">
        
        {{ toast.data | json }}
      </pre> -->
    </div>
  </ng-container>

  <ng-container *ngIf="toast.data && toast.data.messageType === 'LiveStreamPost'">
    <div class="flexbox mt-4">
      <button class="button is-primary" [routerLink]="['watch', 'livestream', toast.data.data.postGUID]">
        Watch Live stream
      </button>
      <!-- <pre style="max-width: 100%">
        
        {{ toast.data | json }}
      </pre> -->
    </div>
  </ng-container>
</div>
