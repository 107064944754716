<div>
  <div class="tabs">
    <ul>
      <li [class.is-active]="selectedOption === 1"><a (click)="selectedOption = 1">XStreamr</a></li>
      <li [class.is-active]="selectedOption === 2"><a (click)="selectedOption = 2">Others</a></li>
    </ul>
  </div>

  <div>
    <ng-container *ngIf="selectedOption === 1">
      <app-all-access></app-all-access>
    </ng-container>
    <ng-container *ngIf="selectedOption === 2">
      <app-other-creators></app-other-creators>
    </ng-container>
  </div>
</div>
