import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // showModal = new BehaviorSubject<boolean>(false);
  constructor() {}

  // openModal(action?: boolean) {
  //   if (action !== undefined) {
  //     this.showModal.next(action);
  //   } else {
  //     this.showModal.next(this.showModal.value == false ? true : false);
  //   }

  //   if (action == true || this.showModal.value == true) {
  //     document.documentElement.style.overflowY = 'hidden';
  //   } else {
  //     document.documentElement.style.removeProperty('overflow-y');
  //   }
  // }
}
