<div class="poster-movie" *ngIf="newFilmRelease$ | async as newFilmReleases; else loading">
  <div
    class="thumbnail-img-wrapper razor"
    [ngClass]="'index-' + i"
    *ngFor="let thumbnail of newFilmReleases; let i = index"
    [routerLink]="['/film', thumbnail.guid]"
  >
    <div class="thumbnail-img-inner-wrapper razor">
      <img
        class="thumbnail-img"
        [src]="thumbnail.thumbnailImageUrl || thumbnail.tmdbImageUrl || thumbnail.featuredImageUrl || 'assets/images/featured-cover-art.png'"
      />
    </div>
    <div class="movie-title">{{ thumbnail.name }}</div>
  </div>

  <div class="none" *ngIf="newFilmReleases.length === 0">You currently have no movies in your collection.</div>
</div>

<ng-template #loading>
  <div class="poster-movie">
    <div class="thumbnail-img-wrapper" [ngClass]="'index-' + i" *ngFor="let e of [].constructor(8); let i = index">
      <div class="thumbnail-img-inner-wrapper">
        <div class="thumbnail-img loading"></div>
      </div>
    </div>
  </div>
</ng-template>
