<div *ngIf="user$ | async as user">
  <div class="tabs">
    <ul>
      <li [class.is-active]="currencyOption === 1"><a (click)="currencyOption = 1">FanBucks</a></li>
      <li [class.is-active]="currencyOption === 2"><a (click)="currencyOption = 2">Fan Rewards</a></li>
    </ul>
  </div>

  <div>
    <div class="tabs">
      <ul>
        <li [class.is-active]="selectedOption === 1">
          <a (click)="selectedOption = 1">{{ currencyOption === 1 ? 'Payment Details' : 'Balance' }}</a>
        </li>
        <li [class.is-active]="selectedOption === 2"><a (click)="selectedOption = 2">Transaction History</a></li>
      </ul>
    </div>
    <ng-container *ngIf="currencyOption === 1">
      <ng-container *ngIf="selectedOption === 1"><app-payment-details-tab></app-payment-details-tab></ng-container>
      <ng-container *ngIf="selectedOption === 2"
        ><app-transactions-tab [isTethered]="false"></app-transactions-tab
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="currencyOption === 2">
      <ng-container *ngIf="selectedOption === 1"><app-fanbucks-balance-tab></app-fanbucks-balance-tab></ng-container>
      <ng-container *ngIf="selectedOption === 2">
        <app-transactions-tab [isTethered]="true"></app-transactions-tab>
        <!-- <app-fanbucks-transactions-tab></app-fanbucks-transactions-tab> -->
      </ng-container>
    </ng-container>
  </div>
</div>
