<ng-container *ngIf="toastService.isOpened | async">
  <ng-container *ngFor="let item of toastService.items$ | async">
    <div class="host {{ item.position }}" [ngClass]="{ full: item.description.includes('is calling') }">
      <div class="isOpened" [ngClass]="{ call: item.description.includes('is calling') }">
        <div @verticalCollapse @triggerChildAnimation [ngClass]="{ 'mt-5': !item.description.includes('is calling') }">
          <labs-toast-item [toast]="item" @slideIn @slideOut> </labs-toast-item>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
