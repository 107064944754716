import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LiveEvent } from '@model/live-event';
import { TokenPurchaseRequest } from '@service/payment.service';

@Injectable({
  providedIn: 'root'
})
export class LiveEventService {
  liveEvents$ = new BehaviorSubject<LiveEvent[]>([]);

  constructor(private http: HttpClient) {}

  getLiveEventByGuid(guid: string): Observable<LiveEvent> {
    return this.http.get<LiveEvent>(`/api/v1/liveevents/${guid}`);
  }

  getOngoingLiveEvents(): void {
    this.http
      .get<LiveEvent[]>('/api/v1/liveevents/search', { params: { liveStreamStatus: 'Active', status: 'Pending' } })
      .toPromise()
      .then((res) => {
        this.liveEvents$.next(res);
      });
  }

  getLiveEventsByMerchantGuid(merchantGuid: string): Observable<LiveEvent[]> {
    return this.http.get<LiveEvent[]>('/api/v1/liveevents/search', {
      params: { merchantGUID: merchantGuid, status: 'Pending' }
    });
  }

  getPendingLiveEvents(): Observable<LiveEvent[]> {
    return this.http.get<LiveEvent[]>('/api/v1/liveevents/search', {
      params: { status: 'Pending', isFutureEvent: true }
    });
  }

  getLiveEventTicketsByUser(): Observable<LiveEvent[]> {
    return this.http.get<LiveEvent[]>('/api/v1/liveevents/search', { params: { hasTicketForCallingUser: true } });
  }

  purchaseTicket(guid: string, purchaseRequest: TokenPurchaseRequest): Observable<LiveEvent> {
    return this.http.post<LiveEvent>(`/api/v1/liveevents/${guid}/liveeventtickets/purchase`, purchaseRequest);
  }

  cancelTicket(guid: string): Observable<LiveEvent> {
    return this.http.post<LiveEvent>(`/api/v1/liveeventtickets/${guid}/cancel`, {});
  }
}
