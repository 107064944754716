import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostService } from '@service/post.service';
import { environment } from '@environment';
import { Post } from '@model/post';
import { User } from '@model/user';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites-tab.component.html',
  styleUrls: ['./favorites-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesTabComponent implements OnInit {
  env = environment;
  newFilmRelease$ = new BehaviorSubject<Post[] | undefined>(undefined);
  user: User = {};

  constructor(private postService: PostService) {}

  ngOnInit(): void {
    this.getNewReleases();
  }

  getNewReleases(): void {
    this.postService
      .searchPost({
        // topN: '12',
        postType: 'Video',
        // hasPostSubType: 'false',
        isSavedByCallingUser: 'true'
        // isUnlockedByCallingUser: 'true'
      })
      .pipe(
        map((posts) => {
          return posts.map((post) => {
            if (post.tmdbImageUrl) {
              post.imgSrcSet = `
                ${this.env.tmdbResource}w92${post.tmdbImageUrl} 92w,
                ${this.env.tmdbResource}w185${post.tmdbImageUrl} 185w,
                ${this.env.tmdbResource}w300${post.tmdbImageUrl} 342w,
                ${this.env.tmdbResource}w500${post.tmdbImageUrl} 500w,
                ${this.env.tmdbResource}w780${post.tmdbImageUrl} 780w,
                ${this.env.tmdbResource}original${post.tmdbImageUrl} 1280w`;
              post.tmdbImageUrl = this.env.tmdbResource + 'w185' + post.tmdbImageUrl;
            }
            if (post.tmdbBackdropImageUrl) {
              post.backdropSrcSet = `
                ${this.env.tmdbResource}w300${post.tmdbBackdropImageUrl} 300w,
                ${this.env.tmdbResource}w780${post.tmdbBackdropImageUrl} 780w,
                ${this.env.tmdbResource}original${post.tmdbBackdropImageUrl} 1280w`;
              post.tmdbBackdropImageUrl = this.env.tmdbResource + 'original' + post.tmdbBackdropImageUrl;
            }
            return post;
          });
        })
      )
      .subscribe((next) => {
        const filteredShows = next.filter(
          (f, index, self) => index === self.findIndex((o) => o.guid === f.guid)
        );
        this.newFilmRelease$.next(filteredShows);
      });
  }
}
