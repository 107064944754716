<div class="mt-4 code flexbox">
  <div *ngIf="referralCodeCtrl">
    <div class="has-text-centered">
      <div *ngIf="!isCodeChange">
        <div>Current Code</div>
        <div class="is-size-2 has-text-weight-bold">{{ currentReferralCode }}</div>
      </div>
      <div *ngIf="isCodeChange">
        <div>Enter Code</div>
        <div class="field mb-0">
          <input
            type="text"
            class="input has-text-centered"
            [class.invalid]="referralCodeCtrl.invalid"
            [formControl]="referralCodeCtrl"
          />
        </div>
        <div class="is-size-7" [class.text-danger]="referralCodeCtrl.invalid">
          Codes must be a single word containing letters and/or numbers.
        </div>
        <div class="is-size-7 text-danger" *ngIf="isReferralCodeUsed$ | async">
          The referral code you entered has already been taken.
        </div>
      </div>

      <div class="mt-2">
        <div *ngIf="!isCodeChange">
          <button class="button is-outlined is-small" (click)="changeCode()">Create New</button>
          <button class="button is-outlined is-small ml-2" (click)="copyReferralCode()">Copy Code</button>
          <button class="button is-outlined is-small ml-2" (click)="createPermalink()">Share Referral Link</button>
        </div>
        <div *ngIf="isCodeChange">
          <div class="mt-2">
            <button
              class="button is-small is-primary"
              (click)="saveNewCode()"
              [disabled]="referralCodeCtrl.invalid || referralCodeCtrl.value == ''"
            >
              Save
            </button>
            <button class="button is-outlined is-small ml-4" (click)="cancelCodeChange()">Cancel</button>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="isCopied">
        <span *ngIf="isCopied">Code copied to clipboard.</span>
      </div>
    </div>
  </div>
</div>
<div class="mt-4 has-text-centered">
  <qrcode [qrdata]="env.domains.baseUrl + '/register?ref=' + currentReferralCode"></qrcode>
</div>
