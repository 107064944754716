<div class="cookie">
  <div class="container is-max-desktop">
    <ng-container *ngIf="selectedOption === 1">
      <div class="flexbox is-size-4 has-text-weight-bold mb-4" *ngIf="user$ | async as user">
        <div>Hi,</div>
        <div class="ml-2 mr-1 l-0">
          <img
            class="avatar"
            [src]="user.profileImageUrl ? user.profileImageUrl : 'assets/images/xgenflix-avatar.png'"
          />
        </div>
        <div>{{ user.username }}</div>
      </div>

      <div>
        <div (click)="selectedOption = 2">
          <div class="card has-hover">
            <i class="unreel-feather-wallet md mr-2"></i>
            <div>
              <div>Account</div>
              <div class="tooltip">Manage profile and security</div>
            </div>
          </div>
        </div>
        <div (click)="selectedOption = 5">
          <div class="card has-hover">
            <i class="unreel-feather-layers md mr-2"></i>
            <div>
              <div>Recently Viewed</div>
              <div class="tooltip">Movies you have been watching</div>
            </div>
          </div>
        </div>
        <!-- <div (click)="selectedOption = 6">
          <div class="card has-hover">
            <i class="unreel-feather-heart md mr-2"></i>
            <div>
              <div>Watchlist</div>
              <div class="tooltip">Movies you have saved</div>
            </div>
          </div>
        </div> -->
        <div (click)="selectedOption = 4">
          <div class="card has-hover">
            <i class="unreel-feather-target md mr-2"></i>
            <div>
              <div>Referrals</div>
              <div class="tooltip">Create and manage referral codes</div>
            </div>
          </div>
        </div>

        <div [routerLink]="['/settings/requests']">
          <div class="card has-hover">
            <i class="unreel-feather-request md mr-2"></i>
            <div>
              <div>Requests</div>
              <div class="tooltip">Your video calls and messages</div>
            </div>
          </div>
        </div>
        <div (click)="selectedOption = 8">
          <div class="card has-hover">
            <i class="unreel-material-outline-ticket md mr-2"></i>
            <div>
              <div>Event Tickets</div>
              <div class="tooltip">View and manage your tickets</div>
            </div>
          </div>
        </div>
        <div (click)="selectedOption = 7">
          <div class="card has-hover">
            <i class="unreel-feather-users md mr-2"></i>
            <div>
              <div>Following</div>
              <div class="tooltip">People you follow</div>
            </div>
          </div>
        </div>
        <div (click)="selectedOption = 3">
          <div class="card has-hover">
            <i class="unreel-feather-wallet md mr-2"></i>
            <div>
              <div>Wallet</div>
              <div class="tooltip">Manage credit card, view transactions</div>
            </div>
          </div>
        </div>
        <div (click)="selectedOption = 9">
          <div class="card has-hover">
            <i class="unreel-feather-repeat md mr-2"></i>
            <div>
              <div>Subscriptions</div>
              <div class="tooltip">View subscription plans</div>
            </div>
          </div>
        </div>
        <div (click)="signOut()">
          <div class="card has-hover is-danger"><i class="unreel-feather-log-out md mr-2"></i>Log Out</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 2">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Account
      </div>
      <div class="mt-4"><app-account></app-account></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 3">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Wallet
      </div>
      <div class="mt-4"><app-wallet></app-wallet></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 4">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Referrals
      </div>
      <div class="mt-4"><app-referrals></app-referrals></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 5">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Recently Viewed
      </div>
      <div class="mt-4"><app-collection></app-collection></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 6">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Watchlist
      </div>
      <div class="mt-4"><app-favorites></app-favorites></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 7">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Following
      </div>
      <div class="mt-4"><app-follows></app-follows></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 8">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Tickets
      </div>
      <div class="mt-4"><app-tickets></app-tickets></div>
    </ng-container>
    <ng-container *ngIf="selectedOption === 9">
      <div class="flexbox is-size-4 has-text-weight-bold">
        <i class="unreel-feather-arrow-left lg cursor-pointer mr-2" (click)="selectedOption = 1"></i>Subscriptions
      </div>
      <div class="mt-4"><app-subscriptions></app-subscriptions></div>
    </ng-container>
  </div>
</div>
