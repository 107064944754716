import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Kudo } from '@model/kudo';
import { MerchantMeetAndGreetRequest } from '@model/merchant-meet-and-greet-request';
import { MerchantMeetAndGreetRequestService } from '@service/merchant-meet-and-greet-request.service';
import { UserService } from '@service/user.service';
import { BehaviorSubject } from 'rxjs';
import { FanBucks } from '@const/kudo';
import { User } from '@model/user';
import { TokenBalance } from '@model/token-balance';
import { GiftService } from '@service/gift.service';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-video-call-end',
  templateUrl: './video-call-end.component.html',
  styleUrls: ['./video-call-end.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCallEndComponent implements OnInit {
  @Input() meetAndGreetRequest: MerchantMeetAndGreetRequest | undefined = undefined;
  @Input() user: User | undefined;

  userRating: number = 0;
  commentCtrl: FormControl;
  isSendKudos$: BehaviorSubject<number>;
  kudoAmount: number = 0;
  isKudoAmountSelected: boolean;
  kudos: Kudo[] = FanBucks;
  isSendingTip$ = new BehaviorSubject(false);
  isKudoInsufficient$ = new BehaviorSubject(false);
  optionalMessage = new FormControl(null);

  averageRating: number;

  merchantName: BehaviorSubject<string>;

  tokenBalances: TokenBalance[] | any = [];
  userKudoBalance: number | any;

  constructor(
    private userService: UserService,
    private meetAndGreetRequestService: MerchantMeetAndGreetRequestService,
    private giftService: GiftService,
    private toastService: ToastService
  ) {
    this.commentCtrl = new FormControl('');
    this.isSendKudos$ = new BehaviorSubject(0);
    this.isKudoAmountSelected = false;
    this.kudos = FanBucks;

    this.userRating = 0;
    this.averageRating = 0;

    this.merchantName = new BehaviorSubject(this.meetAndGreetRequest?.merchantName!);
  }

  ngOnInit(): void {
    this.getUserKudoAmount();
  }

  startRating(userRating: number): void {
    this.userRating = userRating;
  }

  toggleSendKudos(): void {
    let isSendKudos = this.isSendKudos$.getValue();
    isSendKudos = isSendKudos ? 0 : 1;
    this.isSendKudos$.next(isSendKudos);
    if (!isSendKudos) {
      this.kudoAmount = 0;
      this.isKudoAmountSelected = false;
    }
  }

  selectKudo(kudo: Kudo): void {
    this.isKudoAmountSelected = true;
    this.kudoAmount = kudo.value;
  }

  getKudoFromAmount(amount: number) {
    return this.kudos.find((x) => x.value === amount);
  }

  rateVideoCall() {
    this.isSendingTip$.next(true);
    if (this.kudoAmount === 0) {
      this.isSendingTip$.next(false);
      this.meetAndGreetRequestService.data.next(undefined);
      this.meetAndGreetRequestService.isOpened.next(false);
      this.meetAndGreetRequestService.showRating.next(false);
      this.toastService.item({
        type: 'white',
        description: `Rating video call with ${this.meetAndGreetRequest?.merchantName} success!`,
        duration: 5000
      });
      return;
    }
    if (this.userKudoBalance >= this.kudoAmount) {
      this.giftService
        // fromUserGUID: this.userService.user.guid,
        .createGift({
          toUserGUID: this.meetAndGreetRequest?.merchantUserGUID,
          giftType: 'Tip',
          message: this.commentCtrl.value || '',
          amount: this.kudoAmount,
          tokenProfileKey: 'kudo'
        })
        .subscribe(
          (data) => {
            this.toastService.item({
              type: 'white',
              description: `Rating video call with ${this.meetAndGreetRequest?.merchantName} success!`,
              duration: 5000
            });

            this.isSendingTip$.next(false);
            this.meetAndGreetRequestService.data.next(undefined);
            this.meetAndGreetRequestService.isOpened.next(false);
            this.meetAndGreetRequestService.showRating.next(false);
          },
          (error) => {
            if (error.error.errorCode === 'InsufficientBalance') {
              this.isKudoInsufficient$.next(true);
              this.isSendingTip$.next(false);
            }

            this.toastService.item({
              type: 'danger',
              description: `Rating video call failed. ${error.error.errorCode}`,
              duration: 5000
            });
          }
        );
    } else {
      this.isKudoInsufficient$.next(true);
      this.isSendingTip$.next(false);
    }
  }

  getUserKudoAmount(): void {
    this.userService.getUserTokenBalances().subscribe((next: TokenBalance[] | any[]) => {
      this.tokenBalances = next;
      this.userKudoBalance = next.find((x: TokenBalance) => x.tokenProfile.key === 'KUDO')
        ? next.find((x: TokenBalance) => x.tokenProfile.key === 'KUDO').balance
        : 0;
    });
  }
}
