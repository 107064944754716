import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MerchantMeetAndGreetRequestService } from '@service/merchant-meet-and-greet-request.service';
import { VideoSettingsService } from '../../../services/video-settings.service';
import { ToastService } from '../toast.service';
import { Toast } from './../toast';

@Component({
  selector: 'labs-toast-item',
  templateUrl: 'toast-item.component.html',
  styleUrls: ['toast-item.component.scss']
})
export class ToastItemComponent {
  constructor(
    private toastService: ToastService,
    private merchantMeetAndGreetService: MerchantMeetAndGreetRequestService,
    private videoSettingsService: VideoSettingsService
  ) {}
  @Input() toast: Toast | any;

  ngOnInit(): void {}
  callAction(action: string, data: any) {
    if (action === 'accept') {
      this.toastService.clear();
      // this.merchantMeetAndGreetService.isOpened.next(true);
      this.merchantMeetAndGreetService.data.next(data);
      this.merchantMeetAndGreetService.action.next(action);
      this.videoSettingsService.showSettingsModal.next(true);
    } else if (action === 'reject') {
      this.toastService.clear();
      this.merchantMeetAndGreetService.isOpened.next(false);
    }
    //comment this for testing
    // this.merchantMeetAndGreetService
    //   .callResponseMeetAndGreet(data.guid, action)
    //   .toPromise()
    //   .then((res) => {
    //     this.toastService.clear();
    //   })
    //   .catch(() => {
    //     this.toastService.clear();
    //   });
  }

  dismissToast() {
    this.toastService.clear();
  }
}
