import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '@service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit {
  passwordResetForm = new FormGroup(
    {
      password: new FormControl(null, [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl(null, Validators.required)
    },
    { validators: this.checkPasswords }
  );
  code = '';
  isSuccess$ = new BehaviorSubject(0);

  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.verificationCode) {
        this.code = params.verificationCode;
      }
    });

    this.isSuccess$.subscribe((next) => {
      if (next === 1) {
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
      }
    });
  }

  reset(): void {
    const request = { newPassword: this.passwordResetForm.controls.password.value, emailVerificationCode: this.code };

    this.userService.passwordReset(request).subscribe(() => {
      this.isSuccess$.next(1);
    });
  }

  public passwordValidator(): ValidatorFn {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return (control: AbstractControl) => {
      const match = regex.test(control.value);
      return !match ? { passwordFormat: 'Password format is incorrect.' } : null;
    };
  }

  public checkPasswords(control: AbstractControl): ValidationErrors | null {
    const pass = control.get('password');
    const confirmPass = control.get('confirmPassword');
    return pass?.value === confirmPass?.value ? null : { notSame: true };
  }
}
