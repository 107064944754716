import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';
import { Models } from '@model/movies.models';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import {
  ColDef,
  DomLayoutType,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  PaginationChangedEvent
} from 'ag-grid-community';

@Component({
  selector: 'app-transaction-user-renderer',
  templateUrl: './transaction-user-renderer.component.html',
  styleUrls: ['./transaction-user-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionUserRendererComponent implements ICellRendererAngularComp {
  // Init Cell Value
  displayName!: string;
  userProfileImageUrl!: string;
  transaction: Models.TokenTransferDetails | undefined = undefined;

  agInit(params: ICellRendererParams): void {
    this.initializeTransactionUser(params);
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.initializeTransactionUser(params);
    return true;
  }

  initializeTransactionUser(params: ICellRendererParams): void {
    const transaction = params.data as Models.TokenTransferDetails;
    this.transaction = transaction;
    const type = transaction.type;
    this.userProfileImageUrl =
      type == Models.TokenTransferDetailsType.Payment && !transaction.details?.includes('payout')
        ? transaction.toUserProfileImageUrl || transaction.toMerchantMeetAndGreetRequestUserNormalizedProfileImageUrl ||
          transaction.toMerchantMeetAndGreetRequestUserProfileImageUrl || transaction.toLiveEventUserNormalizedProfileImageUrl ||
          transaction.toLiveEventUserProfileImageUrl || 'assets/images/xgenflix-avatar.png'
        : transaction.fromUserProfileImageUrl ?? 'assets/images/default-user.png';
    this.displayName =
      type == Models.TokenTransferDetailsType.Payment && !transaction.details?.includes('payout')
        ? transaction.toUserUsername || transaction.toMerchantMeetAndGreetRequestMerchantName ||
          transaction.toLiveEventUserName || transaction.toPlatformDisplayName || '-'
        : transaction.fromUserUsername ?? transaction.platform ?? '-';
  }
}
