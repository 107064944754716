<div class="mt-4" *ngIf="totalUserCount$ | async as totalUserCount; else noSignupData">
  <div class="columns">
    <div class="column is-two-thirds">
      <div class="chart" *ngIf="chart$ | async as chart">
        <canvas
          baseChart
          [data]="chart.pieChartData"
          [labels]="chart.pieChartLabels"
          [chartType]="chart.pieChartType"
          [options]="chart.pieChartOptions"
          [colors]="chart.pieChartColors"
        ></canvas>
      </div>
    </div>
    <div class="column flexbox">
      <div class="has-text-centered">
        <div>Total Sign Ups</div>
        <div class="is-size-2 has-text-weight-bold">{{ totalUserCount }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noSignupData>
  <div class="flexbox no-data">
    <!-- View people that you have referred. -->
    Provide your referral code to others and get rewarded when they sign up.
    <p></p>
  </div>
</ng-template>
