import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as moment from 'moment';

import { LiveEvent } from '@model/live-event';
import { Post } from '@model/post';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketComponent implements OnInit {
  @Input() ticket?: LiveEvent;
  @Input() liveStream?: Post;
  @Input() size: 'md' | 'sm' | 'xs' | 'xxs' | 'xxxs' | 's2' = 'md';

  moment = moment;

  constructor() {}

  ngOnInit(): void {}
}
