import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { ViewVideoRendererComponent } from './cell-renderers/view-video/view-video-renderer.component';
import { UserRendererComponent } from './cell-renderers/user/user-renderer.component';
import { MerchantRendererComponent } from './cell-renderers/merchant/merchant-renderer.component';
import { RouterModule } from '@angular/router';
import { MerchantViewVideoRendererComponent } from './cell-renderers/merchant-view-video/merchant-view-video-renderer.component';
import { StatusRendererComponent } from './cell-renderers/status/status-renderer.component';
import { VideoCallActionsRendererComponent } from './cell-renderers/video-call-actions/video-call-actions-renderer.component';
import { TransactionUserRendererComponent } from 'app/components/settings/billing/transactions-tab/cell-renderers/transaction-user-renderer.component';
import { TransactionMerchantRendererComponent } from 'app/components/settings/billing/fan-bucks/balance-tab/cell-renderers/transaction-merchant-renderer.component';
import { ViewDirectMessageComponent } from './cell-renderers/view-direct-message/view-direct-message.component';

@NgModule({
  declarations: [
    DataTableComponent,
    ViewVideoRendererComponent,
    UserRendererComponent,
    MerchantRendererComponent,
    MerchantViewVideoRendererComponent,
    StatusRendererComponent,
    VideoCallActionsRendererComponent,
    TransactionUserRendererComponent,
    TransactionMerchantRendererComponent,
    ViewDirectMessageComponent
  ],
  imports: [CommonModule, AgGridModule, RouterModule],
  exports: [
    DataTableComponent,
    ViewVideoRendererComponent,
    UserRendererComponent,
    MerchantRendererComponent,
    MerchantViewVideoRendererComponent,
    StatusRendererComponent,
    VideoCallActionsRendererComponent,
    TransactionUserRendererComponent,
    TransactionMerchantRendererComponent
  ]
})
export class DataTableModule {}
