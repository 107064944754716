<div *ngIf="isOpened | async" class="video-call-container twilio">
  <ng-container *ngIf="meetAndGreet$ | async as meetAndGreet">
    <ng-container *ngIf="showRating">
      <div class="is-flex rating-container" *ngIf="showRating && user$ | async as user">
        <div>
          <button class="modal-close is-large" aria-label="close" (click)="onClose()"></button>
        </div>
        <app-video-call-end [meetAndGreetRequest]="meetAndGreet" [user]="user"></app-video-call-end>
      </div>
      <div class="is-flex rating-container" *ngIf="isMerchant">
        <div>
          <button class="modal-close is-large" aria-label="close" (click)="onClose()"></button>
        </div>
        <div class="is-size-4 has-text-weight-bold has-text-centered">You may close the call screen.</div>
      </div>
    </ng-container>
    <div class="room" *ngIf="!showRating">
      <div id="local" class="local" [ngClass]="{ visible: isEnableVideo }">
        <video
          id="local-video"
          class="local-video"
          [ngClass]="{ invisible: !zoomService.zoomSession?.isRenderSelfViewWithVideoElement() }"
        ></video>
        <canvas id="local-canvas" class="local-canvas"></canvas>
        <span class="user-display-name">
          {{ isMerchant ? meetAndGreet.merchantName : meetAndGreet.userDisplayName }}
        </span>
      </div>
      <div id="meet" class="meet">
        <canvas
          id="participant-videos-canvas"
          width="1920"
          height="1080"
          [ngClass]="{ visible: (isParticipantEnableVideo | async) }"
        ></canvas>
        <div
          id="merchantImg"
          class="merchantImg"
          *ngIf="!(isParticipantConnected | async) || !(isParticipantEnableVideo | async)"
        >
          <img *ngIf="!isMerchant" [src]="meetAndGreet.merchantUserNormalizedProfileImageUrl" />
          <img *ngIf="isMerchant" [src]="meetAndGreet.userProfileImageUrl" />
          <span class="mb-4 mt-4">{{ !isMerchant ? meetAndGreet.merchantName : meetAndGreet.userDisplayName }}</span>
        </div>
      </div>
      <ng-container *ngIf="room$ | async as room">
        <div class="status" *ngIf="meetAndGreet && isParticipantConnected.getValue()">
          <span class="merchant mr-2">{{
            !isMerchant ? meetAndGreet.merchantName : meetAndGreet.userDisplayName
          }}</span>
          <div class="mic-status">
            <i
              *ngIf="isParticipantEnableAudio | async as audioStatus; else participantAudioMuted"
              class="fa fa-microphone"
            ></i>
            <ng-template #participantAudioMuted>
              <i class="fa fa-microphone-slash"></i>
            </ng-template>
          </div>
        </div>
        <div class="controls">
          <button class="button {{ isEnableVideo ? 'is-primary' : '' }} mr-2" (click)="videoControl(!isEnableVideo)">
            <i class="fa {{ isEnableVideo ? 'fa-video' : 'fa-video-slash' }} mr-1"></i>
            {{ isEnableVideo ? 'Stop Video' : 'Start Video' }}

            <div
              class="toggle-video-settings {{ isEnableVideo ? 'text-white' : 'text-primary' }}"
              (click)="isEnableVideo && settings === 1 ? toggleSettings($event) : openSettings($event, 1)"
            >
              <i class="fa {{ settings === 1 && showSettings ? 'fa-caret-up' : 'fa-caret-down' }}"></i>
            </div>
          </button>
          <button class="button {{ isEnableAudio ? 'is-primary' : '' }} mr-2" (click)="audioControl(!isEnableAudio)">
            <i class="fa {{ isEnableAudio ? 'fa-microphone' : 'fa-microphone-slash' }} mr-1"></i>
            {{ isEnableAudio ? 'Mute' : 'Unmute' }}

            <div
              class="toggle-audio-settings {{ isEnableAudio ? 'text-white' : 'text-primary' }}"
              (click)="isEnableAudio && settings === 2 ? toggleSettings($event) : openSettings($event, 2)"
            >
              <i class="fa {{ settings === 2 && showSettings ? 'fa-caret-up' : 'fa-caret-down' }}"></i>
            </div>
          </button>
          <button class="button is-primary mr-2" (click)="hangUp()">
            <i class="fa fa-phone mr-1"></i>
            End Call
          </button>
          <div *ngIf="timeLapse | async as time" class="time-lapse-container">
            <span class="time-lapse ft-ml-2 is-size-4">{{ time }}</span>
          </div>
        </div>

        <div class="device-list-container video" *ngIf="settings === 1 && showSettings">
          <div class="flex mb-2">
            <label class="is-size-5">Select a Camera</label>

            <div class="field">
              <div class="control">
                <select id="camera" #camera (change)="changeCameraInput(camera.value)" class="input">
                  <option
                    *ngFor="let camera of zoomService.cameras | async"
                    [value]="camera.deviceId"
                    [selected]="
                      zoomService.selectedCam !== undefined && zoomService.selectedCam.deviceId === camera.deviceId!
                        ? 'selected'
                        : ''
                    "
                  >
                    {{ camera.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="device-list-container audio" *ngIf="settings === 2 && showSettings">
          <div class="flex mb-1">
            <label class="is-size-5">Select a Speaker</label>
            <div class="field">
              <div class="control">
                <select id="speaker" #speaker (change)="changeAudioOutput(speaker.value)" class="input">
                  <option
                    *ngFor="let speaker of zoomService.speakers | async"
                    [value]="speaker.deviceId"
                    [selected]="
                      zoomService.selectedSpeaker !== undefined &&
                      zoomService.selectedSpeaker.deviceId === speaker.deviceId!
                        ? 'selected'
                        : ''
                    "
                  >
                    {{ speaker.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="flex mb-1">
            <label class="is-size-5">Select a Microphone</label>
            <div class="field">
              <div class="control">
                <select id="microphone" #microphone (change)="changeAudioInput(microphone.value)" class="input">
                  <option
                    *ngFor="let microphone of zoomService.microphones | async"
                    [value]="microphone.deviceId"
                    [selected]="
                      zoomService.selectedMic !== undefined && zoomService.selectedMic.deviceId === microphone.deviceId!
                        ? 'selected'
                        : ''
                    "
                  >
                    {{ microphone.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- <button class="button is-outlined" (click)="openSettings()">Settings</button> -->
        </div>
      </ng-container>

      <div class="recording-participant">
        <!-- <span class="record-status {{ isParticipantConnected.getValue() ? 'on' : 'off' }}"></span>
        <span *ngIf="isParticipantConnected.getValue()" class="ml-1">Recording</span> -->
        <span *ngIf="!isParticipantConnected.getValue()">Please wait, the other party has yet to join.</span>
      </div>
    </div>
  </ng-container>
</div>
