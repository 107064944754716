<div *ngIf="user$ | async as user">
  <div class="tabs">
    <ul>
      <li [class.is-active]="selectedOption === 1"><a (click)="selectedOption = 1">Profile</a></li>
      <li [class.is-active]="selectedOption === 2"><a (click)="selectedOption = 2">Security</a></li>
    </ul>
  </div>

  <div>
    <ng-container *ngIf="selectedOption === 1"><app-profile-tab></app-profile-tab></ng-container>
    <ng-container *ngIf="selectedOption === 2"><app-security-tab></app-security-tab></ng-container>
  </div>
</div>
