import { User } from './user';
import { environment } from '@environment';

export namespace Models {
  export interface Auth {
    user: User;
    success: boolean;
    token: string;
    expires: string | undefined;
    expirationSeconds: number | undefined;
    meta: { requestTraceId: string };
  }

  export enum NotificationType {
    Follow = 'Follow',
    Comment = 'Comment',
    PostLike = 'PostLike',
    CommentLike = 'CommentLike'
  }

  export class Notification {
    guid?: string | undefined;
    notificationType?: NotificationType | undefined;
    fromUserGUID?: string | undefined;
    toUserGUID?: string | undefined;
    postGUID?: string | undefined;
    commentGUID?: string | undefined;
    message?: string | undefined;
    dateCreated?: Date | undefined;
    isRead?: boolean | undefined;
  }

  export class EntityCreationResponse {
    guid?: string | undefined;
  }

  export enum Platform {
    XGenFlix = 'XGenFlix',
    UnreelLive = 'UnreelLive',
    XStreamr = 'XStreamr'
  }

  export enum PostType {
    Image = 'Image',
    Video = 'Video',
    LiveStream = 'LiveStream'
  }

  export enum PostMetaDataType {
    Synopsis = 'Summary',
    PGRating = 'PGRating',
    StarAvg = 'StarAvg',
    Year = 'Year'
  }

  export class PostMetaData {
    guid?: string | undefined;
    postGUID?: string | undefined;
    postMetaDataType?: PostMetaDataType | undefined;
    value?: string | undefined;
  }

  export class PostHashtag {
    guid?: string | undefined;
    postGUID?: string | undefined;
    hashtag?: string | undefined;
  }

  export class UserPostRating {
    guid?: string | undefined;
    postGUID?: string | undefined;
    userGUID?: string | undefined;
    rating?: number | undefined;
    dateCreated?: Date | undefined;
  }

  export interface IPostRatingRequest {
    postGUID: string;
    userGUID: string;
    rating: number;
  }

  export enum MovieGenreType {
    Comedy = 'Comedy',
    Action = 'Action',
    Horror = 'Horror',
    Historical = 'Historical',
    War = 'War',
    SciFi = 'SciFi',
    International = 'International',
    Crime = 'Crime',
    Western = 'Western',
    Drama = 'Drama',
    Musical = 'Musical',
    Documentary = 'Documentary'
  }

  export class PostGenre {
    guid?: string | undefined;
    postGUID?: string | undefined;
    movieGenreType?: MovieGenreType | undefined;
  }

  export enum ReactionType {
    Like = 'Like',
    Love = 'Love',
    Laugh = 'Laugh',
    Wow = 'Wow',
    Sad = 'Sad',
    Angry = 'Angry'
  }

  export class Reaction {
    guid?: string | undefined;
    reactionType?: ReactionType | undefined;
    userGUID?: string | undefined;
    postGUID?: string | undefined;
    commentGUID?: string | undefined;
  }

  export enum FlaggedContentType {
    Other = 'Other',
    Inappropriate = 'Inappropriate',
    Scam = 'Scam',
    Dislike = 'Dislike'
  }

  export class UserFlaggedContent {
    guid?: string | undefined;
    userGUID?: string | undefined;
    flaggedContentType?: FlaggedContentType | undefined;
    postGUID?: string | undefined;
    commentGUID?: string | undefined;
  }

  export class PostCategory {
    guid?: string | undefined;
    name?: string | undefined;
    description?: string | undefined;
    isDeleted?: boolean | undefined;
  }

  export class TokenResponse {
    token?: string | undefined;
    expires?: Date | undefined;
    readonly expirationSeconds?: number | undefined;
  }

  export class ApiError {
    errorCode: string | undefined;
    errorDescription?: string | undefined;
    propertyName?: string | undefined;
  }

  export class ApiResponse {
    success: boolean | undefined;
    errors?: ApiError[] | undefined;
    meta?: { [key: string]: any } | undefined;
  }

  export enum TransferRequestStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Cancelled = 'Cancelled'
  }

  export class TokenTransferRequest {
    guid?: string | undefined;
    status?: TransferRequestStatus | undefined;
    fromUserGUID?: string | undefined;
    toUserGUID?: string | undefined;
    tokenProfileKey?: string | undefined;
    amount?: number | undefined;
    tokenTransferGUID?: string | undefined;
    dateRequested?: Date | undefined;
  }

  export class TokenTransferDetails {
    guid?: string | undefined;
    type?: TokenTransferDetailsType | undefined;
    platform?: string | undefined;
    details?: string | undefined;
    tokenProfileKey?: string | undefined;
    tokenProfileDisplayName?: string | undefined;
    amount?: number | undefined;
    userPaymentTransactionGUID?: string | undefined;
    userPaymentTransactionAmountUSD: number | undefined;
    dateTransferred?: Date | undefined;
    fromUserUsername?: string | undefined;
    toUserUsername?: string | undefined;
    fromUserProfileImageUrl?: string | undefined;
    toUserProfileImageUrl?: string | undefined;
    tetheredMerchantAmount?: number;
    tetheredMerchantUserUsername?: string;
    toMerchantMeetAndGreetRequestMerchantName?: string;
    toMerchantMeetAndGreetRequestUserUsername?: string;
    toMerchantMeetAndGreetRequestUserNormalizedProfileImageUrl?: string;
    toMerchantMeetAndGreetRequestUserProfileImageUrl?: string;
    toLiveEventUserName?: string;
    toLiveEventUserNormalizedProfileImageUrl?: string;
    toLiveEventUserProfileImageUrl?: string;
    toPlatformDisplayName?: string;
  }

  export enum TokenTransferDetailsType {
    Payment = 'Payment',
    Purchase = 'Purchase',
    Reward = 'Reward',
    Received = 'Received'
  }

  export class LoginPasswordRequest {
    username?: string | undefined;
    password?: string | undefined;
  }

  export class Lead {
    guid?: string | undefined;
    platform?: Platform | undefined;
    emailAddress?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    isSubscribed?: boolean | undefined;
  }

  export class VerifyUserPhoneNumberRequest {
    verificationCode?: string | undefined;
  }

  export class UserPhoneNumberVerification {
    guid?: string | undefined;
    userGUID?: string | undefined;
    verificationCode?: string | undefined;
    dateCreated?: Date | undefined;
    dateVerified?: Date | undefined;
  }

  export class InitResetPasswordRequest {
    username?: string | undefined;
  }

  export class ResetPasswordRequest {
    username?: string | undefined;
    newPassword?: string | undefined;
    verificationCode?: string | undefined;
  }

  export class ChangePasswordRequest {
    oldPassword?: string | undefined;
    newPassword?: string | undefined;
  }

  export class UserFollower {
    guid?: string | undefined;
    userGUID?: string | undefined;
    followerGUID?: string | undefined;
    userFirstName?: string | undefined;
    userLastName?: string | undefined;
    followerFirstName?: string | undefined;
    followerLastName?: string | undefined;
  }

  export class UserBlock {
    guid?: string | undefined;
    userGUID?: string | undefined;
    blockedUserGUID?: string | undefined;
  }
}
