import * as moment from 'moment';

export function momentDateFilterComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  const cellDate = moment(cellValue);
  const filterDate = moment(filterLocalDateAtMidnight);
  const dateAsString = cellValue;

  const cellDateAtMidnight = cellDate.startOf('day');
  if (dateAsString == null) {
    return 0;
  }

  // Now that both parameters are Date objects, we can compare
  if (cellDateAtMidnight.isBefore(filterDate)) {
    console.log('is before');
    return -1;
  } else if (cellDateAtMidnight.isAfter(filterDate)) {
    console.log('is after');
    return 1;
  }
  console.log('equal');
  return 0;
}
