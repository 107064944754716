<!-- <p>video-call-end works!</p> -->
<!-- {{ meetAndGreetRequest | json }} -->
<div class="mt-5" *ngIf="meetAndGreetRequest">
  <div class="mt-5">
    <p class="is-size-4 has-text-weight-bold has-text-centered">
      Rate your call with {{ meetAndGreetRequest.merchantName }}
    </p>
  </div>

  <div class="flexbox rating mt-5">
    <!-- (mouseleave)="startRating(0)" -->
    <div class="is-flex navigable">
      <button (mouseenter)="startRating(1)" (click)="startRating(1)" [class.highlight]="userRating >= 1">
        <span *ngIf="userRating >= 1"><i class="fas fa-star"></i></span>
        <span *ngIf="userRating < 1"><i class="far fa-star"></i></span>
      </button>
      <button (mouseover)="startRating(2)" (click)="startRating(2)" [class.highlight]="userRating >= 2">
        <span *ngIf="userRating >= 2"><i class="fas fa-star"></i></span>
        <span *ngIf="userRating < 2"><i class="far fa-star"></i></span>
      </button>
      <button (mouseover)="startRating(3)" (click)="startRating(3)" [class.highlight]="userRating >= 3">
        <span *ngIf="userRating >= 3"><i class="fas fa-star"></i></span>
        <span *ngIf="userRating < 3"><i class="far fa-star"></i></span>
      </button>
      <button (mouseover)="startRating(4)" (click)="startRating(4)" [class.highlight]="userRating >= 4">
        <span *ngIf="userRating >= 4"><i class="fas fa-star"></i></span>
        <span *ngIf="userRating < 4"><i class="far fa-star"></i></span>
      </button>
      <button (mouseover)="startRating(5)" (click)="startRating(5)" [class.highlight]="userRating >= 5">
        <span *ngIf="userRating >= 5"><i class="fas fa-star"></i></span>
        <span *ngIf="userRating < 5"><i class="far fa-star"></i></span>
      </button>
    </div>
  </div>

  <div class="mt-5" *ngIf="user">
    <div class="comment-box mt-3">
      <div class="is-flex w-100">
        <img class="avatar" [src]="user.profileImageUrl" />
        <div class="control">
          <div class="field">
            <input type="text" class="input" placeholder="Enter your comment..." [formControl]="commentCtrl" />
          </div>
        </div>
        <button class="button is-outlined kudo is-primary" (click)="toggleSendKudos()">
          <i class="unreel-trey-kudo-solid"></i>
        </button>
        <button class="button is-primary ml-3" (click)="rateVideoCall()">
          <i class="tr tr-send"></i>
        </button>
      </div>
      <div class="mt-2" *ngIf="(isSendKudos$ | async) == 1">
        <div>How many Fanbucks do you want to give?</div>
        <div class="flexbox wrap kudos" [class.has-selected]="isKudoAmountSelected">
          <button
            class="button {{ kudo.value === kudoAmount ? 'is-primary' : 'is-outlined' }}"
            *ngFor="let kudo of kudos"
            (click)="selectKudo(kudo)"
          >
            <i class="unreel-trey-kudo-solid"></i>

            <!-- <img [src]="'assets/img/kudo/' + kudo.id + '.png'" /> -->
            <span class="has-text-weight-bold ml-1">{{ kudo.value }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
