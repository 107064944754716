<ng-container *ngIf="liveEvents$ | async as liveEvents; else loading">
  <div class="ticket-header" *ngIf="liveEvents.length">Upcoming Events</div>
  <div class="poster-movie" *ngIf="liveEvents.length">
    <div
      class="cursor-pointer razor"
      [ngClass]="'index-' + i"
      *ngFor="let thumbnail of liveEvents; let i = index"
      (click)="selectLiveEvent(thumbnail)"
    >
      <app-ticket [ticket]="thumbnail" size="xxxs"></app-ticket>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="pastLiveEvents$ | async as pastLiveEvents; else loading">
  <div class="ticket-header" *ngIf="pastLiveEvents.length">Past Events</div>
  <div class="poster-movie" *ngIf="pastLiveEvents.length">
    <div
      class="cursor-pointer razor"
      [ngClass]="'index-' + i"
      *ngFor="let thumbnail of pastLiveEvents; let i = index"
      (click)="selectLiveEvent(thumbnail)"
    >
      <app-ticket [ticket]="thumbnail" size="xxxs"></app-ticket>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="liveEvents$.getValue()?.length === 0 && pastLiveEvents$.getValue()?.length === 0">
  <div>You currently have no tickets.</div>
</ng-container>

<ng-template #loading>
  <div class="poster-movie">
    <div class="thumbnail-img-wrapper" [ngClass]="'index-' + i" *ngFor="let e of [].constructor(8); let i = index">
      <div class="thumbnail-img-inner-wrapper">
        <div class="thumbnail-img loading"></div>
      </div>
    </div>
  </div>
</ng-template>

<app-modal *ngIf="isPaymentModalOpen" [showModal]="isPaymentModalOpen" (modalStatus)="closePaymentOptionModal($event)">
  <ng-container *ngIf="selectedTicket?.hasTicketForCallingUser">
    <div class="card unlock-card">
      <div class="card-content has-text-centered">
        <div class="payment-success text-center">
          <div class="is-size-5 has-text-weight-semibold">You will be able to watch the event when it goes live.</div>
          <div>
            <button class="button is-primary mt-4" (click)="cancelTicket()">Cancel Ticket</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-modal>
