import { Component, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { VideoCallRequest } from '@model/request';
import { MerchantMeetAndGreetRequestService } from '@service/merchant-meet-and-greet-request.service';
import { ZoomService } from '@service/zoom.service';
import { VideoSettingsService } from '@service/video-settings.service';
import { User } from '@model/user';
import { UserService } from '@service/user.service';

@Component({
  selector: 'app-onboarding-video-call',
  templateUrl: './onboarding-video-call.component.html',
  styleUrls: ['./onboarding-video-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingVideoCallComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement') videoElement: any;
  showModal$ = new BehaviorSubject(false);
  video: any;

  selectedOption = 1;
  hasPermission = false;
  microphones = new BehaviorSubject<MediaDeviceInfo[]>([]);
  cameras = new BehaviorSubject<MediaDeviceInfo[]>([]);
  speakers = new BehaviorSubject<MediaDeviceInfo[]>([]);

  speakerLevel$ = new BehaviorSubject(0);
  micLevel$ = new BehaviorSubject(0);

  isRunningSpeaker = this.zoomService.isRunningSpeaker;
  isRunningMic = this.zoomService.isRunningMic;
  isRunningCamera = this.zoomService.isRunningCamera;

  audioOutputDeviceTest: any = undefined;
  videoTrack: Navigator | any = undefined;

  modalSubscription: Subscription = new Subscription();

  videoCallDataSubscription: Subscription = new Subscription();
  videoCallData = new BehaviorSubject<VideoCallRequest | undefined>(undefined);

  callInProgressSubscription: Subscription = new Subscription();
  callInProgress = false;

  user$ = new BehaviorSubject<User | undefined>(undefined);
  isFan = true;

  isMerchant = false;
  constructor(
    public zoomService: ZoomService,
    private videoSettingsService: VideoSettingsService,
    private merchantMeetAndGreetService: MerchantMeetAndGreetRequestService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // isFan = this.user?.guid !== videoCall.merchantUserGUID;
    this.userService.user$.subscribe((user) => {
      if (user) this.user$.next(user);
    });

    this.getPermission();
    this.modalSubscription = this.videoSettingsService.showSettingsModal.subscribe((status) => {
      // console.log(status);

      this.showModal$.next(status);

      if (status) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.joinCall();
        } else {
          this.checkPermission();
        }
      }
    });

    this.videoCallDataSubscription = this.merchantMeetAndGreetService.data.subscribe((data) => {
      // console.log(data);
      if (data && data.merchantUserGUID && this.user$.value?.guid === data.merchantUserGUID) {
        // console.log('MERCHANT');
        this.isMerchant = true;
      }
      this.videoCallData.next(data);
    });

    this.callInProgressSubscription = this.videoSettingsService.callInProgress.subscribe((status) => {
      // console.log(status);
      this.callInProgress = status;
    });
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
    this.videoCallDataSubscription.unsubscribe();
    this.stopAllDevice();
  }

  setTab(value: number): void {
    this.selectedOption = value;
    if (value === 1 && this.cameras.value.length === 1) {
      // this.twilioService.selectedCam = this.cameras.value[0];
      // this.testCamera(this.cameras.value[0].deviceId);
    } else {
      this.stopAllDevice();
    }
  }

  async getPermission(): Promise<void> {
    try {
      // get audio and video permissions then stop the tracks
      await this.stopAllDevice();
      // The devicechange event is not fired after permissions are granted, so we fire it
      // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
      // when the useDevices hook updates.
      // console.log((this.hasPermission = true));
      this.getDevice();
      setTimeout(() => navigator.mediaDevices.dispatchEvent(new Event('devicechange')), 500);
    } catch (error) {
      this.hasPermission = false;
      if (error instanceof Error) {
        console.log(error);
      }
    }
  }

  getDevice(): void {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.microphones.next(devices.filter((d) => d.kind === 'audioinput'));
      this.cameras.next(devices.filter((d) => d.kind === 'videoinput'));
      this.speakers.next(devices.filter((d) => d.kind === 'audiooutput'));

      if (this.zoomService.selectedCam === undefined) {
        this.zoomService.selectedCam = devices.filter((d) => d.kind === 'videoinput')[0];
      }

      if (this.zoomService.selectedSpeaker === undefined && this.speakers.value.length > 1) {
        this.zoomService.selectedSpeaker = devices.find((d) => d.deviceId === 'default' && d.kind === 'audiooutput');
      }

      if (this.zoomService.selectedMic === undefined && this.microphones.value.length > 1) {
        this.zoomService.selectedMic = devices.find((d) => d.deviceId === 'default' && d.kind === 'audioinput');
      }
    });
  }

  testCamera(id: string) {
    if (this.hasPermission) {
      // console.log(id);
      const media = this.cameras.value.find((s) => s.deviceId === id);
      // console.log(media);
      if (media) {
        this.zoomService.isRunningCamera = true;
        this.isRunningCamera = true;
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            // video: true
            video: {
              deviceId: media?.deviceId
            }
          })
          .then((stream: any | MediaStream | MediaSource) => {
            // console.log(stream);
            this.zoomService.mediaStream = stream;

            // this.video = document.getElementById('video');
            // console.log(this.videoElement);
            this.video = this.videoElement.nativeElement;

            // this.video.src = window.URL.createObjectURL(stream);
            this.video.className = 'video';
            this.video.srcObject = stream;
            this.video.play();
          });
      }
      // if (media) this.twillioService.audioInTest(media);
    } else {
      this.checkPermission();
    }
  }

  testAudioInput(id: string) {
    if (this.hasPermission) {
      this.zoomService.micLevel$.subscribe((v) => {
        this.micLevel$.next(v);
      });
      const media = this.microphones.value.find((s) => s.deviceId === id);
      if (media) this.zoomService.audioInTest(media);
    } else {
      this.checkPermission();
    }
  }

  testAudioOutput(id: string) {
    if (this.hasPermission) {
      // console.log(id);

      this.zoomService.speakerLevel$.subscribe((v) => {
        this.speakerLevel$.next(v);
      });
      const media = this.speakers.value.find((s) => s.deviceId === id);
      if (media) {
        if (media) {
          this.zoomService.audioOutTest(media);
        }
      }
    } else {
      this.checkPermission();
    }
  }

  changeAudioOutput(id: string) {
    const media = this.speakers.value.find((s) => s.deviceId === id);
    // if (media) this.twillioService.audioOutTest(media);
    this.zoomService.selectedSpeaker = media;
  }

  changeAudioInput(id: string) {
    const media = this.microphones.value.find((s) => s.deviceId === id);
    // if (media) this.twillioService.audioOutTest(media);
    this.zoomService.selectedMic = media;
  }

  changeCameraInput(id: string) {
    // console.log(id);
    const media = this.cameras.value.find((s) => s.deviceId === id);
    // if (media) this.twillioService.audioOutTest(media);
    this.zoomService.selectedCam = media;
  }

  stopAllDevice() {
    this.zoomService.isRunningCamera = false;
    this.zoomService.isRunningMic = false;
    this.zoomService.isRunningSpeaker = false;

    this.isRunningCamera = false;
    this.isRunningMic = false;
    this.isRunningSpeaker = false;

    this.zoomService.mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    // navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(async (mediaStream) => {
    //   this.twilioService.mediaStream = mediaStream;
    //   mediaStream.getTracks().forEach((track) => {
    //     track.stop();
    //   });
    // });
  }

  closeModal($event: any): void {
    this.stopAllDevice();
    // console.log($event);
    this.zoomService.mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    this.videoSettingsService.showSettingsModal.next($event);
  }

  joinCall() {
    this.merchantMeetAndGreetService.isOpened.next(true);
    const videoCall = this.merchantMeetAndGreetService.data.value;
    const user = this.user$.value;
    if (user?.guid !== videoCall.merchantUserGUID) {
      if (videoCall.guid) {
        this.merchantMeetAndGreetService
          .callResponseMeetAndGreet(videoCall.guid, 'accept')
          .toPromise()
          .then((res) => {
            // console.log(res);
            this.videoSettingsService.callInProgress.next(true);
            this.videoSettingsService.showSettingsModal.next(false);
            this.merchantMeetAndGreetService.isOpened.next(true);
            this.merchantMeetAndGreetService.showRating.next(false);
          })
          .catch(() => {
            this.videoSettingsService.showSettingsModal.next(false);
          });
      }
    } else {
      this.videoSettingsService.callInProgress.next(true);
      this.videoSettingsService.showSettingsModal.next(false);
      this.merchantMeetAndGreetService.isOpened.next(true);
      this.merchantMeetAndGreetService.showRating.next(false);
    }
  }

  checkPermission() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        // console.log(stream);
        /* use the stream */
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.getDevice();
        this.hasPermission = true;
      })
      .catch((err) => {
        console.log(err);
        this.hasPermission = false;
        /* handle the error */
      });
  }

  stopAudioOutput() {
    this.zoomService.isRunningSpeaker = false;
    this.zoomService.stopAudioOutput();
  }

  stopAudioInput() {
    this.zoomService.isRunningMic = false;
    this.zoomService.stopAudioInput();
  }
}
