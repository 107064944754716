import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Merchant } from '@model/merchant';
import { User } from '@model/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  merchantInfo$ = new BehaviorSubject<Merchant | any>(null);

  constructor(private http: HttpClient) {}

  getMerchant(guid: string): Observable<Merchant> {
    return this.http.get<Merchant>(`/api/v1/merchants/${guid}`);
  }

  getMerchantDashboard(guid: string): Observable<Merchant> {
    return this.http.get<Merchant>(`/api/v1/merchants/${guid}/dashboard`, {
      params: {
        tokenProfileKey: 'kudo'
      }
    });
  }

  searchMerchants(params: { [p: string]: string | any }): Observable<Merchant[]> {
    return this.http.get<Merchant[]>(`/api/v1/merchants/search`, { params });
  }

  followMerchant(followingGuid: string): Observable<Merchant> {
    return this.http.post(`/api/v1/merchants/${followingGuid}/follow`, {});
  }

  unfollowMerchant(followingGuid: string): Observable<Merchant> {
    return this.http.post(`/api/v1/merchants/${followingGuid}/unfollow`, {});
  }

  merchantFollower(guid: string) {
    return this.http.get<User[]>(`/api/v1/merchants/current/merchantFollowers`, {
      params: { merchantGUID: guid, tokenProfileKey: 'kudo' }
    });
  }

  getFeaturedMerchants(params?: { [p: string]: string | any }): Observable<Merchant[]> {
    return this.http.get<Merchant[]>(`/api/v1/featured/merchants`, { params });
  }
}
