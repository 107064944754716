import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { User } from '@model/user';
import { UserService } from '@service/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-follows',
  templateUrl: './follows.component.html',
  styleUrls: ['./follows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowsComponent implements OnInit {
  selectedOption = 1;
  user$ = new BehaviorSubject<User | undefined>(undefined);

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getCurrentUserData();
  }

  getCurrentUserData(): void {
    this.userService.user$.subscribe((next) => {
      if (next) this.user$.next(next);
    });
  }
}
