import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MeetAndGreetNotificationService {
  message?: string | undefined | null;
  title?: string | undefined | null;
  duration?: number | undefined | null;
  isOpened = new BehaviorSubject(false);
  type?: string | undefined | null;
  roomName?: string | undefined | null;

  constructor() {}

  open(
    message: string,
    title: string | null = null,
    duration: number = 5000,
    type: string = 'success',
    roomName: string | null = null
  ): void {
    this.message = message;
    this.title = title;
    this.duration = duration;
    this.type = type;
    this.roomName = roomName;
    this.isOpened.next(true);
    setTimeout(() => {
      this.isOpened.next(false);
    }, duration);
  }
}
