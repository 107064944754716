import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@model/user';
import { UserService } from '@service/user.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingComponent implements OnInit {
  user$: BehaviorSubject<User> = new BehaviorSubject({});
  selectedOption = 1;
  currencyOption = 1;

  constructor(public userService: UserService) {}

  ngOnInit(): void {
    this.getCurrentUserData();
  }

  getCurrentUserData(): void {
    this.user$.next(this.userService.user);
  }
}
