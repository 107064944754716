import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';

import { SettingsComponent } from './settings.component';
import { AccountComponent } from './account/account.component';
import { SecurityTabComponent } from './account/security-tab/security-tab.component';
import { CollectionTabComponent } from './collection-tab/collection-tab.component';
import { PaymentDetailsTabComponent } from './billing/payment-details-tab/payment-details-tab.component';
import { TransactionsTabComponent } from './billing/transactions-tab/transactions-tab.component';
import { ReferralCodesComponent } from './referrals/referral-codes/referral-codes.component';
import { ReferralSignupsComponent } from './referrals/referral-signups/referral-signups.component';
import { BalanceTabComponent } from './balance-tab/balance-tab.component';
import { ProfileTabComponent } from './account/profile-tab/profile-tab.component';
import { BillingComponent } from './billing/billing.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { ReferralDashboardComponent } from './referrals/referral-dashboard/referral-dashboard.component';
import { FavoritesTabComponent } from './favorites-tab/favorites-tab.component';
import { RequestsComponent } from './requests/requests.component';
import { VideoMessageComponent } from './requests/video-message/video-message.component';
import { ModalModule } from 'app/shared/components/modal/modal.module';
import { VideoCallComponent } from './requests/video-call/video-call.component';
import { FollowsComponent } from './follows/follows.component';
import { FollowersComponent } from './follows/followers/followers.component';
import { FollowingComponent } from './follows/following/following.component';
import { FanBucksBalanceTabComponent } from './billing/fan-bucks/balance-tab/balance-tab.component';
import { RateVideoMessageModule } from 'app/shared/components/rate-video-message/rate-video-message.module';
import { TicketsTabComponent } from './tickets-tab/tickets-tab.component';
import { TicketComponent } from '../../shared/components/ticket/ticket.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DataTableModule } from 'app/shared/components/data-table/data-table.module';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionModalComponent } from './subscriptions/subscription-modal/subscription-modal.component';
import { AllAccessComponent } from './subscriptions/all-access/all-access.component';
import { OtherCreatorsComponent } from './subscriptions/other-creators/other-creators.component';
import { UnsubcribeCallActionRendererComponent } from './subscriptions/unsubcribe-call-action-renderer/unsubcribe-call-action-renderer.component';
import { DirectMessageComponent } from './requests/direct-message/direct-message.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChartsModule,
    ImageCropperModule,
    RouterModule,
    ModalModule,
    RateVideoMessageModule,
    QRCodeModule,
    DataTableModule
  ],
  declarations: [
    SettingsComponent,
    AccountComponent,
    ProfileTabComponent,
    SecurityTabComponent,
    BillingComponent,
    PaymentDetailsTabComponent,
    TransactionsTabComponent,
    ReferralsComponent,
    ReferralDashboardComponent,
    ReferralCodesComponent,
    CollectionTabComponent,
    TicketsTabComponent,
    FavoritesTabComponent,
    ReferralSignupsComponent,
    BalanceTabComponent,
    RequestsComponent,
    VideoMessageComponent,
    VideoCallComponent,
    FollowsComponent,
    FollowersComponent,
    FollowingComponent,
    FanBucksBalanceTabComponent,
    TicketComponent,
    SubscriptionsComponent,
    SubscriptionModalComponent,
    AllAccessComponent,
    OtherCreatorsComponent,
    UnsubcribeCallActionRendererComponent,
    DirectMessageComponent
  ],
  exports: [TicketComponent],
  providers: [
    {
      provide: ROUTES,
      useFactory: configHandlerRoutes,
      multi: true
    }
  ]
})
export class SettingsModule {}

export function configHandlerRoutes(): Routes {
  let routes: Routes;
  routes = [
    {
      path: '',
      component: SettingsComponent
    }
  ];
  return routes;
}
