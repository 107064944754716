<div *ngIf="user$ | async as user">
  <div class="columns">
    <div class="column">
      <div class="is-size-6 has-text-weight-bold">Summary</div>
      <app-referral-dashboard></app-referral-dashboard>
    </div>

    <div class="column">
      <div class="is-size-6 has-text-weight-bold">Referral Code</div>
      <app-referral-codes></app-referral-codes>
    </div>
  </div>

  <div class="columns mt-6">
    <div class="column">
      <div class="is-size-6 has-text-weight-bold">Signups</div>
      <app-referral-signups></app-referral-signups>
    </div>
  </div>
</div>
