import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '@service/user.service';
import { ToastService } from 'app/shared/components/toast/toast.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-security-tab',
  templateUrl: './security-tab.component.html',
  styleUrls: ['./security-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityTabComponent implements OnInit {
  public changePassForm: FormGroup;
  public isFormSubmitted = new BehaviorSubject(false);

  constructor(private formBuilder: FormBuilder, private userService: UserService, private toastService: ToastService) {
    this.changePassForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      },
      { validators: this.checkPasswords }
    );
  }

  ngOnInit(): void {}

  submit(): void {
    this.isFormSubmitted.next(true);
    delete this.changePassForm.value.confirmPassword;
    this.userService.changePassword(this.changePassForm.value).subscribe(
      (res) => {
        this.isFormSubmitted.next(false);
        this.toastService.item({ type: 'white', description: 'You have successfully changed your password' });
        this.changePassForm.setValue({
          oldPassword: null,
          newPassword: null,
          confirmPassword: null
        });
      },
      (error) => {
        this.isFormSubmitted.next(false);
        this.toastService.item({ type: 'danger', description: 'Change password failed.' });
      }
    );
  }

  checkPasswords(group: FormGroup): unknown {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
}
