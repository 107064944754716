<div class="mt-5">
  <!-- <div class="tabs">
    <ul>
      <li [class.is-active]="selectedOption === 1"><a (click)="selectedOption = 1">Following</a></li>
      <ng-container *ngIf="user$ | async as user">
        <li [class.is-active]="selectedOption === 2">
          <a (click)="selectedOption = 2" *ngIf="user.merchantGUID">Followers</a>
        </li>
      </ng-container>
    </ul>
  </div> -->

  <div>
    <ng-container *ngIf="selectedOption === 1">
      <app-following></app-following>
    </ng-container>
    <!-- <ng-container *ngIf="selectedOption === 2">
      <app-followers></app-followers>
    </ng-container> -->
  </div>
</div>
