<div>
  <ng-template #loading>
    <div class="p-6 has-text-centered">Loading balance...</div>
  </ng-template>
  <div *ngIf="tokenBalanceHistory$ | async as balance; else loading">
    <div class="current-balance has-text-centered my-4">
      Current Fan Rewards Balance:
      <div class="has-text-weight-bold is-size-4">{{ (fanRewardBalance$ | async) || 0 | number }}</div>
    </div>
    <div *ngIf="balance.length > 0; else noBalance" class="table-responsive">
      <app-data-table [rows]="balance" [columns]="colDefs"></app-data-table>
    </div>
    <ng-template #noBalance>
      <div class="text-center">You currently do not have any balance or purchases.</div>
    </ng-template>
  </div>
</div>
