import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferralCode } from '@model/referral-code';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferralCodeService {
  constructor(private http: HttpClient) {}

  addReferralCode(code: string, userGuid: string) {
    return this.http.post(`/api/v1/referralcodes`, { code: code, userGUID: userGuid, isDefault: true, isActive: true });
  }

  getUserReferralCodes() {
    return this.http.get<ReferralCode[]>(`/api/v1/users/current/referralcodes`);
  }

  getCurrentUserSelectedReferralCode() {
    return this.http.get<ReferralCode[]>(`/api/v1/users/current/referralcodes`).pipe(
      map((next: ReferralCode[]) => {
        const selectedReferralCode = next.find((x) => x.isDefault === true);

        // if there is no default referral code, get the latest on to default
        if (!selectedReferralCode) {
          this.selectReferralCode(next[0].code!).subscribe();
          return next[0];
        }

        // return the default if we find one
        return selectedReferralCode;
      })
    );
  }

  toggleReferralCode(code: string, isActive: boolean) {
    return this.http.patch(`/api/v1/referralcodes/${code}`, { isActive: isActive });
  }

  selectReferralCode(code: string) {
    return this.http.patch(`/api/v1/referralcodes/${code}`, { isDefault: true });
  }

  deleteReferralCode(code: string) {
    return this.http.delete(`/api/v1/referralcodes/${code}`);
  }

  patchReferralCode(referral: ReferralCode) {
    return this.http.patch(`/api/v1/referralcodes/${referral.code}`, referral);
  }
}
