import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PaymentProfile } from '@model/payment-profile';
import { BehaviorSubject } from 'rxjs';
import { Models } from '@model/movies.models';
import { UserService } from '@service/user.service';
import { TokenBalance } from '@model/token-balance';
import { ColDef } from 'ag-grid-community';
import { TransactionUserRendererComponent } from './cell-renderers/transaction-user-renderer.component';
import * as moment from 'moment';
import { momentDateComparator } from 'app/shared/components/data-table/sort-comparators/sort-comparators';
import { momentDateFilterComparator } from 'app/shared/components/data-table/filter-comparators/filter-comparators';

@Component({
  selector: 'app-transactions-tab',
  templateUrl: './transactions-tab.component.html',
  styleUrls: ['./transactions-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionsTabComponent implements OnInit {
  @Input() isTethered: boolean = false;
  user = this.userService.user;
  // Token Balance
  private _tokenBalance = 0;
  public transactionIndex = 0;
  public totalTransactionPages = 0;
  public tokenBalance$ = new BehaviorSubject<number>(0);

  public userProfile$ = new BehaviorSubject<PaymentProfile>({});
  // Token History
  private _tokenPurchaseHistory: Models.TokenTransferDetails[] = [];
  public tokenPurchaseHistory$: BehaviorSubject<Models.TokenTransferDetails[]> = new BehaviorSubject(null as any);

  colDefs: ColDef<Models.TokenTransferDetails>[] = [
    {
      headerName: 'Type',
      field: 'type',
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      wrapText: true,
      valueFormatter: (params) => {
        const transactionType = params.value as Models.TokenTransferDetailsType;
        if (transactionType === Models.TokenTransferDetailsType.Purchase) {
          return `Credit card purchase`;
        } else {
          return `${transactionType} ${transactionType === Models.TokenTransferDetailsType.Payment ? 'to' : 'from'}`;
        }
      },
      headerClass: 'has-text-weight-semibold',
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'User',
      flex: 1,
      minWidth: 120,
      autoHeight: true,
      cellRenderer: TransactionUserRendererComponent,
      headerClass: 'has-text-weight-semibold'
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      cellClass: (params) => {
        const transactionType = params.data?.type as Models.TokenTransferDetailsType;
        if (transactionType === Models.TokenTransferDetailsType.Payment) {
          return `flexbox is-align-items-center has-text-danger`;
        } else {
          return `flexbox is-align-items-center has-text-success`;
        }
      },
      valueFormatter: (params) => {
        const tokenProfileKey = params.data?.tokenProfileKey;
        return `${tokenProfileKey == 'USD' ? '$' : ''} ${params.value}`;
      },
      headerClass: 'has-text-weight-semibold',
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: 'details',
      flex: 1,
      minWidth: 300,
      autoHeight: true,
      wrapText: true,
      cellClass: 'flexbox is-align-items-center',
      headerClass: 'has-text-weight-semibold'
    },
    {
      field: 'dateTransferred',
      flex: 1,
      minWidth: 120,
      maxWidth: 300,
      cellClass: 'flexbox is-align-items-center',
      headerClass: 'has-text-weight-semibold',
      valueFormatter: (params) => {
        const momentDate = moment(params.value);
        const formattedDate = momentDate.format('M/D/YY, h:mm A');
        return formattedDate;
      },
      sortable: true,
      comparator: momentDateComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: momentDateFilterComparator
      }
    }
  ];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getUserTokenBalances().subscribe((res: TokenBalance[]) => {
      this._tokenBalance = res[0] && res[0].balance ? res[0].balance : 0;
      this.tokenBalance$.next(this._tokenBalance);
    });
    this.userService.getTokenHistory(this.isTethered).subscribe((res: Models.TokenTransferDetails[]) => {
      console.log(res);
      this._tokenPurchaseHistory = res;
      /*  this._tokenPurchaseHistory = res.filter((t) =>
        !this.isTethered ? t.tetheredMerchantAmount === undefined : t.tetheredMerchantAmount !== undefined
      );*/
      this.totalTransactionPages = Math.ceil(this._tokenPurchaseHistory.length / 20);
      this.tokenPurchaseHistory$.next(res);
    });
    this.userService.getUserPaymentInfo(this.user && this.user.guid ? this.user.guid : '').subscribe((res) => {
      const paymentProfile = res.filter((r) => r.isDefault)[0];
      this.userProfile$.next(paymentProfile);
    });
  }

  paginate(type: string) {
    switch (type) {
      case 'left':
        this.transactionIndex--;
        break;
      case 'right':
        this.transactionIndex++;
    }
    this.tokenPurchaseHistory$.next(
      this._tokenPurchaseHistory.slice(20 * this.transactionIndex, 20 * this.transactionIndex + 20)
    );
  }
}
