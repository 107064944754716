import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Models } from '@model/movies.models';
import { TokenBalance } from '@model/token-balance';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-transaction-merchant-renderer',
  templateUrl: './transaction-merchant-renderer.component.html',
  styleUrls: ['./transaction-merchant-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionMerchantRendererComponent implements ICellRendererAngularComp {
  // Init Cell Value
  displayName!: string;
  userProfileImageUrl!: string;

  agInit(params: ICellRendererParams): void {
    this.initializeTransactionUser(params);
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.initializeTransactionUser(params);
    return true;
  }

  initializeTransactionUser(params: ICellRendererParams): void {
    const balance = params.data as TokenBalance;
    this.userProfileImageUrl =
      balance.tetheredMerchantNormalizedProfileImageUrl ??
      balance.tetheredMerchantProfileImageUrl ??
      'assets/images/xgenflix-avatar.png';
    this.displayName = balance.tetheredMerchantName ?? 'XGENFLIX';
  }
}
