import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GiftService {
  constructor(private http: HttpClient) {}

  getGift(guid: string) {
    return this.http.get(`/api/v1/gifts/${guid}`);
  }
  createGift(params: { [p: string]: any }) {
    return this.http.post('/api/v1/gifts', params);
  }
}
