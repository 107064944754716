<div>
  <ng-template #loading>
    <div class="p-6 has-text-centered">Loading transactions...</div>
  </ng-template>
  <div *ngIf="tokenPurchaseHistory$ | async as transactions; else loading">
    <div *ngIf="transactions.length > 0; else noTransaction" class="table-responsive">
      <app-data-table [rows]="transactions" [columns]="colDefs"></app-data-table>
    </div>
    <ng-template #noTransaction>
      <div class="text-center">You currently do not have any transactions or purchases.</div>
    </ng-template>
  </div>
</div>
