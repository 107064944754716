import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import ZoomVideo, { Stream } from '@zoom/videosdk';
import * as signalR from '@microsoft/signalr';
import { testAudioInputDevice, AudioInputTest, testAudioOutputDevice, AudioOutputTest } from '@twilio/rtc-diagnostics';
import { environment } from '@environment';
import { VideoMeetings } from '../models/request';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  zoomVideo = ZoomVideo.createClient();
  zoomSession: typeof Stream | undefined = undefined;

  private hubConnection!: signalR.HubConnection;
  hubConnectionState = new BehaviorSubject(false);
  currentConnection!: signalR.HubConnection;

  microphones = new BehaviorSubject<MediaDeviceInfo[]>([]);
  cameras = new BehaviorSubject<MediaDeviceInfo[]>([]);
  speakers = new BehaviorSubject<MediaDeviceInfo[]>([]);

  selectedMic: MediaDeviceInfo | undefined = undefined;
  selectedCam: MediaDeviceInfo | undefined = undefined;
  selectedSpeaker: MediaDeviceInfo | undefined = undefined;

  speakerLevel$ = new BehaviorSubject(0);
  micLevel$ = new BehaviorSubject(0);

  isRunningSpeaker = false;
  isRunningMic = false;
  isRunningCamera = false;

  mediaStream: MediaStream | undefined = undefined;

  audioOutputDeviceTest: AudioOutputTest | undefined;
  audioInputDeviceTest: AudioInputTest | undefined;

  constructor() {}

  async connect(meeting: VideoMeetings, merchantName: string) {
    this.getDevice();

    await this.zoomVideo.init('en-US', 'Global', { patchJsMedia: true });
    await this.zoomVideo.join(
      meeting.videoMeetingName || `Meet and greet with ${merchantName}`,
      meeting.token || '',
      'XStreamr'
    );

    this.zoomSession = this.zoomVideo.getMediaStream();
  }

  initializeConnection(): void {
    const userAuth = JSON.parse(localStorage.getItem('xgenflix.user')!);
    const token = userAuth.token;
    if (token) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalRUrl, { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Error)
        .build();

      this.hubConnection.on('MerchantMeetAndGreetRequestCallAccepted', (next) => {
        const data = JSON.parse(next);
        // alert(`${data.data.userDisplayName} accepted the call.`);
        // this.snackBar.open(`${data.data.userDisplayName} accepted the call.`, 5000);
      });

      this.hubConnection.on('MerchantMeetAndGreetRequestCallRejected', (next) => {
        const data = JSON.parse(next);
        // alert(`${data.data.userDisplayName} rejected the call.`);
        // this.snackBar.open(`${data.data.userDisplayName} rejected the call.`, 5000, 'danger');
      });

      if (this.hubConnectionState.getValue() === false) {
        this.hubConnection
          .start()
          .then(() => {
            this.currentConnection = this.hubConnection;
            this.hubConnectionState.next(true);
          })
          .catch(() => {
            console.error('Error while starting connection. Retrying...');
            setTimeout(() => {
              this.initializeConnection();
            }, 3000);
          });
      }
    }
  }

  async addLocalVideo() {
    const videoElement = document.getElementById('local-video') as HTMLVideoElement;
    const canvasElement = document.getElementById('local-canvas') as HTMLCanvasElement;

    if (this.zoomSession?.isRenderSelfViewWithVideoElement()) {
      await this.zoomSession.startVideo({ videoElement, cameraId: this.selectedCam?.deviceId });
    } else {
      await this.zoomSession?.startVideo({ cameraId: this.selectedCam?.deviceId });
      await this.zoomSession?.renderVideo(canvasElement, this.zoomVideo.getCurrentUserInfo().userId, 200, 160, 0, 0, 3);
    }
  }

  videoControl(isEnable: boolean) {
    if (isEnable) {
      this.addLocalVideo();
    } else {
      this.zoomSession?.stopVideo();
    }
  }

  audioInTest(device: MediaDeviceInfo) {
    // console.log(device);
    this.isRunningMic = true;

    this.audioInputDeviceTest = testAudioInputDevice({
      deviceId: device.deviceId
    });
    try {
      this.audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
        // console.log('volume', volume);
        this.micLevel$.next(volume);
      });

      this.audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
        console.error('error', error);
      });

      this.audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
        console.log('report', report);
      });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      this.audioInputDeviceTest!.stop();
      this.micLevel$.next(0);
      this.isRunningMic = false;
    }, 6000);
  }

  audioOutTest(device: MediaDeviceInfo) {
    // console.log(device);
    this.isRunningSpeaker = true;

    this.audioOutputDeviceTest = testAudioOutputDevice({
      deviceId: device.deviceId
    });

    try {
      this.audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
        this.speakerLevel$.next(volume);
        // console.log('volume', volume);
      });

      this.audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
        console.error(error);
      });

      this.audioOutputDeviceTest.on(AudioOutputTest.Events.End, (report) => {
        this.speakerLevel$.next(0);
        // console.log('report', report);
      });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      this.stopAudioOutput();
    }, 6000);
  }

  getDevice() {
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.microphones.next(devices.filter((d) => d.kind === 'audioinput'));
      this.cameras.next(devices.filter((d) => d.kind === 'videoinput'));
      this.speakers.next(devices.filter((d) => d.kind === 'audiooutput'));

      if (this.selectedCam === undefined) {
        this.selectedCam = devices.find((d) => d.kind === 'videoinput' && d.deviceId === 'default');
      }
      return devices;
    });
  }

  stopAudioOutput() {
    this.audioOutputDeviceTest!.stop();
    this.isRunningMic = false;
    this.speakerLevel$.next(0);
  }

  stopAudioInput() {
    this.audioInputDeviceTest!.stop();
    this.isRunningMic = false;
    this.micLevel$.next(0);
  }
}
