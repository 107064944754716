import { Injectable } from '@angular/core';
import { environment } from '@environment';
import * as signalR from '@microsoft/signalr';
import { LiveStreamResponse } from '@model/live-stream';
import { UserAuth, UserMessage } from '@model/user';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../components/toast/toast.service';
import { LiveStreamService } from './live-stream.service';
import { MerchantMeetAndGreetRequestService } from './merchant-meet-and-greet-request.service';
import { NotificationService } from './notification.service';
import { PostService } from './post.service';
import { ZoomService } from './zoom.service';

import { UserService } from './user.service';
import { VideoSettingsService } from './video-settings.service';
import { LiveEventService } from '@service/live-event.service';
import { UserMessageService } from './usermessage.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  token = '';
  hubConnection: signalR.HubConnection | undefined;
  // liveStreamResponse$: BehaviorSubject<LiveStreamResponse | any> = new BehaviorSubject(undefined);

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private merchantMeetAndGreetRequestService: MerchantMeetAndGreetRequestService,
    private zoomService: ZoomService,
    private notificationService: NotificationService,
    private liveStreamService: LiveStreamService,
    private liveEventService: LiveEventService,
    private postService: PostService,
    private videoSettingsService: VideoSettingsService,
    private userMessageService: UserMessageService
  ) {
    // this.liveStreamResponse$ = new BehaviorSubject(undefined);
  }

  initializeConnection(): Promise<void> {
    const userAuth: UserAuth = JSON.parse(localStorage.getItem('xgenflix.user') || '{}');

    if (!this.hubConnection && userAuth && userAuth.token) {
      // to do Delete sample toast
      // this.toastService.item({
      //   type: 'white',
      //   description: `Test is calling you...`,
      //   duration: 600000000,
      //   data: this.testData
      // });
      // console.log(this.testData);
      // end of todo
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalRUrl || '', { accessTokenFactory: () => userAuth.token || '' })
        .configureLogging(signalR.LogLevel.Error)
        .build();

      this.hubConnection.on('MerchantMeetAndGreetRequestCallStarted', (next: any) => {
        const payload = JSON.parse(next);
        // console.log(payload);
        this.toastService.item({
          type: 'white',
          description: `${payload.data.merchantName} is calling you...`,
          data: payload,
          duration: 600000
        });
      });

      this.hubConnection.on('MerchantMeetAndGreetRequestCallCompleted', (next: any) => {
        const payload = JSON.parse(next);
        // console.log(payload);

        this.toastService.item({
          type: 'info',
          description: `Call with ${payload.data.merchantName} has ended.`,
          duration: 5000
        });
        // trigger automatic endcall
        this.videoSettingsService.callInProgress.next(false);

        this.merchantMeetAndGreetRequestService.endCall(true);
        this.zoomService.zoomVideo.leave();
      });

      this.hubConnection.on('UserMessage', (next) => {
        const payload: UserMessage = JSON.parse(next).data;
        this.userMessageService.unreadMessage$.next(payload);
        this.userService.getUserMessageGroups()
        .pipe(
          map((groups) =>
            groups.map((group) => {
              group.dateOfLastMessage = moment(group.dateOfLastMessage).fromNow();
              return group;
            })
          )
        )
        .toPromise()
        .then((next) => {
          this.userMessageService.messageGroups$.next(next);
        });
        
        this.toastService.item({
          type: 'info',
          description: `${payload.fromUserDisplayName} sent you a message.`,
          duration: 5000
        });
      });

      this.hubConnection.on('Gift', (next) => {
        const payload = JSON.parse(next);
        // console.log(payload);
        payload.data.notificationType = 'Gift';
        payload.data.message = `${payload.data.fromUserDisplayName} sent you a gift of ${payload.data.amount} Fanbucks`;
        this.addToNotification(payload.data);

        this.toastService.item({
          type: 'info',
          description: `${payload.data.fromUserDisplayName} sent you a gift of ${payload.data.amount} Fanbucks`,
          duration: 5000
        });
      });

      this.hubConnection.on('Notification', (next) => {
        const payload = JSON.parse(next);
        this.addToNotification(payload.data);
        // console.log(payload.data.notificationType);
        if (
          payload.data.notificationType !== 'MerchantMeetAndGreetRequestStarted' &&
          payload.data.notificationType !== 'MerchantMeetAndGreetRequestCompleted' &&
          payload.data.notificationType !== 'MerchantMeetAndGreetRequestCancelled'
        ) {
          // console.log('notif');
          // console.log(payload);

          this.toastService.item({
            type: 'info',
            description: `${payload.data.message} by ${payload.data.fromUserDisplayName}.`,
            duration: 5000
          });
        }
      });

      // //Live Stream
      this.hubConnection.on('LiveStreamPost', (next) => {
        const data = JSON.parse(next);
        // this.liveStreamService.liveStreamResponse$.next(data);
        const currentLiveStream = this.liveStreamService.liveStreams$.value;
        // console.log(data);
        this.postService
          .getPost(data.data.postGUID)
          .toPromise()
          .then((res) => {
            this.toastService.item({
              type: 'white',
              description: `${res.merchantName} started a new live stream`,
              data,
              duration: 5000
            });
            // console.log(res);
            const checking = currentLiveStream.findIndex((l) => l.guid === data.data.postGUID);
            // console.log(checking);
            if (checking === undefined || checking === -1) {
              const newLiveStream = [...currentLiveStream, res];
              this.liveStreamService.liveStreams$.next(newLiveStream);
            }
          });
      });

      this.hubConnection.on('LiveEvent', (next) => {
        const data = JSON.parse(next);
        const currentLiveEvents = this.liveEventService.liveEvents$.value;
        console.log('signalr live event', data);

        this.liveEventService
          .getLiveEventByGuid(data.data.liveEventGUID)
          .toPromise()
          .then((res) => {
            const checking = currentLiveEvents.findIndex((l) => l.guid === data.data.liveEventGUID);
            if (checking === undefined || checking === -1) {
              const updatedLiveEvents = [...currentLiveEvents, res];
              this.liveEventService.liveEvents$.next(updatedLiveEvents);
            }
          });

        /* this.postService
          .getPost(data.data.postGUID)
          .toPromise()
          .then((res) => {
            this.toastService.item({
              type: 'white',
              description: `${res.merchantName} started a new live stream`,
              data,
              duration: 5000
            });
            // console.log(res);
            const checking = currentLiveStream.findIndex((l) => l.guid === data.data.postGUID);
            // console.log(checking);
            if (checking === undefined || checking === -1) {
              const newLiveStream = [...currentLiveStream, res];
              this.liveStreamService.liveStreams$.next(newLiveStream);
            }
          });*/
      });

      this.hubConnection.on('LiveStreamPostCompleted', (next) => {
        const data = JSON.parse(next);
        console.log('LiveStreamPostCompleted', data);
        const currentLiveStream = this.liveStreamService.liveStreams$.value;
        const index = currentLiveStream.findIndex((l) => l.guid === data.data.postGUID);
        if (index !== undefined) {
          currentLiveStream.splice(index, 1);
          this.liveStreamService.liveStreams$.next(currentLiveStream);
        }
        this.liveStreamService.liveStreamResponse$.next(data);
      });

      this.hubConnection.on('LiveStreamComment', (next) => {
        const comment = JSON.parse(next).data;
        this.liveStreamService.comment$.next(comment);
      });

      this.hubConnection.on('LiveStreamViewerCount', (next) => {
        this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
      });

      this.hubConnection.on('LiveStreamPoll', (next) => {
        this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
      });

      this.hubConnection.on('LiveStreamPollCompleted', (next) => {
        this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
      });

      this.hubConnection.on('LiveStreamPollVote', (next) => {
        this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
      });
    }
    return this.hubConnection!.start().catch((error: any) => {
      console.log(error);
      setTimeout(() => {
        this.initializeConnection();
      }, 500);
      console.log('Error while starting connection: ' + error);
    });
  }

  disconnect() {
    this.hubConnection!.stop();
  }

  initializeMessagingService = (recipientUserGuid: string) => {
    this.hubConnection!.on('UserMessage', (next) => {
      const message = JSON.parse(next).data;
      if (recipientUserGuid === message.fromUserGUID) {
        this.userMessageService.newMessage$.next(message);
      }
    });
  };

  addToNotification(payload: any) {
    this.notificationService.hasNotification.next(true);
    const currentNotification = this.notificationService.notifications$.value;

    payload.dateCreated = moment(payload.dateCreated).fromNow();
    payload.isRead = false;
    const notification = [payload, ...currentNotification!];
    console.log(payload);
    this.notificationService.notifications$.next(notification);
    // console.log(payload);
  }

  subscribeToChat(liveStreamGuid: string): void {
    // console.log('web socket subscribeToChat');

    if (this.hubConnection!.state === signalR.HubConnectionState.Connected) {
      this.hubConnection!.invoke('SubscribeToLiveStreamUpdates', liveStreamGuid).catch((error) => {
        console.error(error.toString());
      });
    } else {
      setTimeout(() => {
        // console.log('initialize connection web-socket-service');
        this.subscribeToChat(liveStreamGuid);
      }, 250);
    }
  }

  unsubscribeFromChat(liveStreamGuid: string): void {
    this.hubConnection!.invoke('UnsubscribeFromLiveStreamUpdates', liveStreamGuid).catch((error) => {
      console.error(error.toString());
    });
  }

  connectToLiveStreamChat(): void {
    console.log('connectToLiveStreamChat');

    //Live Stream
    this.hubConnection!.on('LiveStreamPost', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });

    this.hubConnection!.on('LiveStreamComment', (next) => {
      const comment = JSON.parse(next).data;
      this.liveStreamService.comment$.next(comment);
    });

    this.hubConnection!.on('LiveStreamViewerCount', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });

    this.hubConnection!.on('LiveStreamCompleted', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });

    this.hubConnection!.on('LiveStreamPoll', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });

    this.hubConnection!.on('LiveStreamPollCompleted', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });

    this.hubConnection!.on('LiveStreamPollVote', (next) => {
      this.liveStreamService.liveStreamResponse$.next(JSON.parse(next));
    });
  }
}
