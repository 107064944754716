import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() modalClass = '';
  @Input() showModal = false;
  @Input() customWidth?: number;

  @Output() modalStatus = new EventEmitter();
  constructor(public modalService: ModalService) {}

  ngOnChanges(): void {
    this.modalStatus.emit(this.showModal);
  }

  ngOnInit(): void {}

  closeModal(): void {
    this.showModal = false;
    this.modalStatus.emit(false);
  }
}
