import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewCastCrewComponent } from './view-cast-crew.component';
import { ModalModule } from '../modal/modal.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ViewCastCrewComponent],
  imports: [CommonModule, ModalModule, RouterModule],
  exports: [ViewCastCrewComponent]
})
export class ViewCastCrewModule {}
